import React, { Fragment } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { Helmet } from "react-helmet";
import PulseLoader from "react-spinners/PulseLoader";
import VideoPlayer from "./VideoPlayer";
import VideoDetails from "./VideoDetails";
import ChannelInfo from "./ChannelInfo";
import Comments from "./Comments";
import VideoRightSide from "./SideBar/VideoRightSide";
import { objectToArray, createCommentsTree } from "./helpers";
import "./Video.css";

import spamImg from "../../assets/images/spam-poster.png";

let commentsListener;
let videoListener;
let needUnmount = true;
class Video extends React.Component {
  constructor(props) {
    super(props);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let autoplay = params.get("autoplay");

    this.state = {
      comments: [],
      videoPoster: "",
      videoSrc: "",
      video: null,
      videoId: "",
      enableAutoplay: autoplay === "off" ? false : true,
    };
  }

  componentDidMount = async () => {
    var videoId = this.props.match.params.videoId.split("?")[0];

    const videoCheck = await firebase
      .database()
      .ref("videos")
      .child(videoId)
      .once("value");

    if (videoCheck.val() === null || videoCheck.val() === undefined) {
      needUnmount = false;
      this.props.history.push("/error");
    } else {
      if (!videoCheck.val().reported) {
        var videoPoster =
          videoCheck.val().thumbnail ||
          "https://image.mux.com/" +
            videoCheck.val().playbackIds[0].id +
            "/thumbnail.png";
        var videoSrc =
          "https://stream.mux.com/" +
          videoCheck.val().playbackIds[0].id +
          ".m3u8";
      } else {
        videoPoster = spamImg;
        videoSrc = "";
      }

      this.setState({
        videoPoster: videoPoster,
        videoSrc: videoSrc,
        videoId: videoId,
      });

      videoListener = firebase
        .database()
        .ref("videos")
        .child(videoId)
        .on("value", (snapshot) => {
          if (snapshot) {
            this.setState({
              video: snapshot.val(),
            });
          } else {
            // if (snapshot === null || snapshot === undefined) {
            //   this.props.history.push("/error");
            // }
            console.log("no video", snapshot);
          }
        });

      commentsListener = firebase
        .database()
        .ref("comments")
        .child(videoId)
        .on("value", (snapshot) => {
          if (snapshot) {
            this.setState({ comments: objectToArray(snapshot.val()) });
          }
        });

      //increment the view count
      const videoRef = await firebase
        .database()
        .ref("videos")
        .child(videoId)
        .child("viewCount");
      videoRef.transaction(function (currentViewCount) {
        return (currentViewCount || 0) + 1;
      });
    }
  };

  componentWillUnmount() {
    if (needUnmount) {
      this.state.videoId &&
        firebase
          .database()
          .ref("videos")
          .child(this.state.videoId)
          .off("value", videoListener);

      this.state.videoId &&
        firebase
          .database()
          .ref("comments")
          .child(this.state.videoId)
          .off("value", commentsListener);
    }
  }

  render() {
    const { videoPoster, videoSrc, video, enableAutoplay } = this.state;

    if (video === null) {
      return (
        <Fragment>
          <section className="bg-light video-container">
            <div className="text-center" style={{ marginTop: 200 }}>
              <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
            </div>
          </section>
        </Fragment>
      );
    }

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: enableAutoplay,
      controls: true,
      fluid: true,
      aspectRatio: "16:9",
    };

    const { comments } = this.state;
    const commentsTree =
      comments && Object.keys(comments).length > 0
        ? createCommentsTree(comments)
        : [];

    return (
      <Fragment>
        <Helmet>
          <title>{video.title}</title>
          <meta name="description" content={video.description} />
        </Helmet>
        <section className="bg-light video-container">
          <div className="videoInfos container">
            <VideoPlayer
              {...videoJsOptions}
              poster={videoPoster}
              src={videoSrc}
            />
            <div className="row">
              <div className="col-12 col-lg-8 col-md-7">
                <VideoDetails video={video} />
                <hr className="margin_top_z" />
                <ChannelInfo video={video} />
                <hr />
                <h3>Comments</h3>
                <Comments
                  comments={commentsTree}
                  // count={comments ? Object.keys(comments).length : 0}
                  video={video}
                />
              </div>
              <aside className="col-lg-4 col-md-5 d-none d-sm-none d-md-flex video-side-column">
                <VideoRightSide video={video} />
              </aside>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Video;
