import React, { Component } from "react";
import { Feed, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import firebase from "firebase/app";
import "firebase/database";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

class NotificationItem extends Component {
  renderSummary = (notification) => {
    const userName =
      notification.user.userName && notification.user.userName !== ""
        ? notification.user.userName
        : notification.user.uid
        ? notification.user.uid
        : notification.user.userId;
    switch (notification.type) {
      case "newTextComment": //done
        return notification.isPrivate ? (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            sent you a private message in the{" "}
            <Link
              to={{
                pathname: "/video/" + notification.videoId + "?autoplay=off",
              }}
            >
              video
            </Link>
          </div>
        ) : (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            replied you in the{" "}
            <Link
              to={{
                pathname: "/video/" + notification.videoId + "?autoplay=off",
              }}
            >
              video
            </Link>
          </div>
        );
      case "newVideoComment": //done
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            sent you a cool video 🎥 reply in a{" "}
            <Link
              to={{
                pathname: "/video/" + notification.videoId + "?autoplay=off",
              }}
            >
              video
            </Link>
          </div>
        );
      case "newVideo":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            shared a{" "}
            <Link to={{ pathname: "/video/" + notification.videoId }}>
              video
            </Link>{" "}
            🎥
          </div>
        );
      case "newFollower":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            started to follow you 🎉
          </div>
        );
      case "newWelcome":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            just sent you a warm welcome 👋
          </div>
        );
      case "newCommentLike":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.name}
            </Feed.User>{" "}
            liked ❤️ your{" "}
            {notification.videoComment ? "video reply" : "following comment"} in
            the{" "}
            <Link
              to={{
                pathname: "/video/" + notification.videoId + "?autoplay=off",
              }}
            >
              video
            </Link>
          </div>
        );
      case "newVideoLike":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.name}
            </Feed.User>{" "}
            liked ❤️ your{" "}
            <Link
              to={{
                pathname: "/video/" + notification.videoId + "?autoplay=off",
              }}
            >
              video
            </Link>
          </div>
        );
      case "newAmaQuestion":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            asked a question in your{" "}
            <Link to={{ pathname: "/ama/" + notification.amaId }}>AMA</Link>
          </div>
        );
      case "newAmaAnswer":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {notification.user.displayName}
            </Feed.User>{" "}
            answered your question in the{" "}
            <Link to={{ pathname: "/ama/" + notification.amaId }}>AMA</Link>
          </div>
        );
      default:
        return;
    }
  };

  markAsRead = async (notification) => {
    const notificationRef = await firebase
      .database()
      .ref("notifications")
      .child(notification.owner)
      .child(notification.id)
      .once("value");

    await firebase
      .database()
      .ref("read-notifications")
      .child(notification.owner)
      .push(notificationRef.val());

    await firebase
      .database()
      .ref("notifications")
      .child(notification.owner)
      .child(notification.id)
      .remove();
  };

  deletePerm = async (notification) => {
    await firebase
      .database()
      .ref("read-notifications")
      .child(notification.owner)
      .child(notification.id)
      .remove();
  };

  render() {
    const { notification, isRead } = this.props;

    return (
      <>
        <Feed.Event className="notification-item">
          <Feed.Label>
            <img src={notification.user.avatar || "/assets/user.png"} alt="" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>{this.renderSummary(notification)}</Feed.Summary>
            {notification.type === "newVideo" && ( // add video preview
              <>
                <Feed.Extra text>{notification.videoTitle}</Feed.Extra>
                {notification.videoGif && (
                  <Feed.Extra images>
                    <a
                      href={
                        "https://indielog.com/video/" + notification.videoId
                      }
                      target="_blank"
                    >
                      <img src={notification.videoGif} />
                    </a>
                  </Feed.Extra>
                )}
              </>
            )}
            {(notification.type === "newTextComment" ||
              (notification.type === "newCommentLike" &&
                notification.videoComment === false)) && (
              // display comment content
              <Feed.Extra text>{notification.comment}</Feed.Extra>
            )}
            {notification.type === "newVideoLike" && ( // add video preview
              <>
                {notification.videoThumbnail && (
                  <Feed.Extra images>
                    <a
                      href={
                        "https://indielog.com/video/" +
                        notification.videoId +
                        "?autoplay=off"
                      }
                      target="_blank"
                    >
                      <img src={notification.videoThumbnail} />
                    </a>
                  </Feed.Extra>
                )}
                <Feed.Extra text>{notification.videoTitle}</Feed.Extra>
              </>
            )}

            <Feed.Meta>
              <Feed.Date>
                {formatDistance(notification.createdAt, Date.now())} ago
              </Feed.Date>
            </Feed.Meta>
          </Feed.Content>

          {isRead ? (
            <Tippy content="Delete it permanently" placement="right-start">
              <span
                className="more-option"
                onClick={() => this.deletePerm(notification)}
              >
                <Icon name="close" style={{ color: "#565656" }} />
              </span>
            </Tippy>
          ) : (
            <Tippy content="Mark as read" placement="right-start">
              <span
                className="more-option"
                onClick={() => this.markAsRead(notification)}
              >
                <Icon name="close" style={{ color: "#565656" }} />
              </span>
            </Tippy>
          )}
        </Feed.Event>
      </>
    );
  }
}

export default NotificationItem;
