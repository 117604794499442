import React, { Component } from "react";
import { Comment, Header } from "semantic-ui-react";
import AddCommentForm from "./AddCommentForm";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import RenderComments from "./RenderComments";
import { setUserValue } from "../../../redux/action/userAction";
import "./Comments.css";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
      selectedCommentId: null,
    };
  }

  addMeetupComment = async (meetupId, comment, parentId) => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return "loginNeeded";
    }

    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    let newComment = currentUser.userName
      ? {
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          userName: currentUser.userName,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
        }
      : {
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
        };

    try {
      await firebase
        .database()
        .ref("meetup-comments")
        .child(meetupId)
        .push(newComment);
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "addMeetupComment in Comments",
        error: error,
      });
    }
  };

  render() {
    const { meetup, comments, user } = this.props;
    const { currentUser } = user;

    if (!meetup) return null;

    return (
      <div>
        <AddCommentForm
          parentId={0}
          addMeetupComment={this.addMeetupComment}
          meetupId={meetup.id}
          allowCancel={false}
          setUserValue={setUserValue}
          currentUser={currentUser}
          parentComment={true}
          meetupHost={meetup.host.displayName || ""}
        />
        <Comment.Group threaded>
          {comments &&
            comments.map((comment) => {
              return (
                <RenderComments
                  key={comment.id}
                  comment={comment}
                  currentUser={currentUser}
                  meetupId={meetup.id}
                />
              );
            })}
        </Comment.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Comments);
