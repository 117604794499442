import React, { Fragment } from "react";
import { Form, Button, Feed, Icon } from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import NotificationItem from "./NotificationItem";
import { setUserValue } from "../../redux/action/userAction";
import "./Notifications.css";

let notificationListener;
let readNotificationsListener;
var unsubscribe;
class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "all",
      headerTitle: "All notifications",
      notifications: [],
      readNotifications: [],
      totalCount: 0,
      readCount: 0,
      replyCount: 0,
      videoCount: 0,
      followerCount: 0,
      noNotification: false,
    };
  }

  componentDidMount = () => {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        notificationListener = firebase
          .database()
          .ref("notifications")
          .child(user.uid)
          .on("value", (snapshot) => {
            var replyCount = 0;
            var videoCount = 0;
            var followerCount = 0;
            var totalCount = 0;
            var notifiArray = [];
            snapshot.val() &&
              Object.entries(snapshot.val()).map((e) => {
                if (
                  e[1].type === "newTextComment" ||
                  e[1].type === "newVideoComment" ||
                  e[1].type === "newCommentLike" ||
                  e[1].type === "newVideoLike"
                ) {
                  replyCount++;
                } else if (e[1].type === "newVideo") {
                  videoCount++;
                } else if (e[1].type === "newFollower") {
                  followerCount++;
                }
                totalCount++;
                notifiArray.push({ ...e[1], id: e[0] });
              });
            this.setState({
              notifications: notifiArray || [],
              replyCount: replyCount,
              videoCount: videoCount,
              followerCount: followerCount,
              totalCount: totalCount,
            });
          });

        readNotificationsListener = await firebase
          .database()
          .ref("read-notifications")
          .child(user.uid)
          .on("value", (snapshot) => {
            var readCount = 0;
            var readNotifiArray = [];
            snapshot.val() &&
              Object.entries(snapshot.val()).map((e) => {
                readCount++;
                readNotifiArray.push({ ...e[1], id: e[0] });
              });
            this.setState({
              readNotifications: readNotifiArray || [],
              readCount: readCount,
            });
          });
      } else {
        this.setState({ notifications: [], readNotifications: [] });
      }
    });
  };

  componentWillUnmount() {
    unsubscribe();
  }

  markAllAsReadWrapper = async () => {
    await this.markAllAsRead();

    const { notifications } = this.state;

    if (notifications.length > 0) {
      await firebase
        .database()
        .ref("notifications")
        .child(notifications[0].owner)
        .remove();
    }

    toast("You just marked all notifications as read 👏", {
      autoClose: 3000,
    });
  };

  markAllAsRead = async () => {
    const { notifications } = this.state;

    if (notifications.length > 0) {
      for (let i = 0; i < notifications.length; i++) {
        const notification = Object.values(notifications)[i];
        await firebase
          .database()
          .ref("read-notifications")
          .child(notification.owner)
          .push(notification);
      }
    }
  };

  deleteAllPerm = async () => {
    const { readNotifications } = this.state;

    if (readNotifications.length > 0) {
      await firebase
        .database()
        .ref("read-notifications")
        .child(readNotifications[0].owner)
        .remove();
    }

    toast("You deleted all notifications 👏", {
      autoClose: 3000,
    });
  };

  renderNotifications = (notifications, readNotifications, tab) => {
    if (!notifications && !readNotifications) return [];

    var reversedNotifications =
      (notifications && Object.values(notifications).reverse()) || [];

    if (tab === "replies") {
      return reversedNotifications.map((notification) => {
        if (
          notification.type === "newTextComment" ||
          notification.type === "newVideoComment" ||
          notification.type === "newCommentLike" ||
          notification.type === "newVideoLike"
        ) {
          return (
            <NotificationItem
              key={notification.createdAt}
              notification={notification}
            />
          );
        }
      });
    } else if (tab === "videos") {
      return reversedNotifications.map((notification) => {
        if (notification.type === "newVideo") {
          return (
            <NotificationItem
              key={notification.createdAt}
              notification={notification}
            />
          );
        }
      });
    } else if (tab === "followers") {
      return reversedNotifications.map((notification) => {
        if (notification.type === "newFollower") {
          return (
            <NotificationItem
              key={notification.createdAt}
              notification={notification}
            />
          );
        }
      });
    } else if (tab === "allRead") {
      var reversedReadNotifications =
        Object.values(readNotifications).reverse() || [];

      return reversedReadNotifications.map((notification) => {
        return (
          <NotificationItem
            key={notification.createdAt}
            notification={notification}
            isRead={true}
          />
        );
      });
    } else {
      return reversedNotifications.map((notification) => {
        return (
          <NotificationItem
            key={notification.createdAt}
            notification={notification}
          />
        );
      });
    }
  };

  render() {
    const {
      notifications,
      readNotifications,
      tab,
      headerTitle,
      replyCount,
      videoCount,
      followerCount,
      totalCount,
      readCount,
    } = this.state;

    return (
      <Fragment>
        <section className="bg-notifications">
          <div className="notifications container">
            <div className="row">
              <div className="col-lg-3 d-none d-md-none d-lg-flex">
                <nav>
                  <div
                    onClick={() =>
                      this.setState({
                        tab: "all",
                        headerTitle: "All notifications",
                      })
                    }
                    className={
                      "cursor-pointer group flex items-center px-3 py-2 text-xl leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150 " +
                      (tab === "all" && "bg-gray-100")
                    }
                    aria-current="page"
                  >
                    <span className="truncate">All notifications</span>
                    <span className="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full bg-white transition ease-in-out duration-150">
                      {totalCount}
                    </span>
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        tab: "replies",
                        headerTitle: "New replies",
                      })
                    }
                    className={
                      "cursor-pointer mt-1 group flex items-center px-3 py-2 text-xl leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150 " +
                      (tab === "replies" && "bg-gray-100")
                    }
                  >
                    <span className="truncate">New replies</span>
                    <span className="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-gray-600 bg-gray-100 group-hover:bg-gray-200 group-focus:bg-gray-200 transition ease-in-out duration-150">
                      {replyCount}
                    </span>
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        tab: "videos",
                        headerTitle: "New videos",
                      })
                    }
                    className={
                      "cursor-pointer mt-1 group flex items-center px-3 py-2 text-xl leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150 " +
                      (tab === "videos" && "bg-gray-100")
                    }
                  >
                    <span className="truncate">New videos</span>
                    <span className="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-gray-600 bg-gray-100 group-hover:bg-gray-200 group-focus:bg-gray-200 transition ease-in-out duration-150">
                      {videoCount}
                    </span>
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        tab: "followers",
                        headerTitle: "New followers",
                      })
                    }
                    className={
                      "cursor-pointer mt-1 group flex items-center px-3 py-2 text-xl leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150 " +
                      (tab === "followers" && "bg-gray-100")
                    }
                  >
                    <span className="truncate">New followers</span>
                    <span className="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-gray-600 bg-gray-100 group-hover:bg-gray-200 group-focus:bg-gray-200 transition ease-in-out duration-150">
                      {followerCount}
                    </span>
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        tab: "allRead",
                        headerTitle: "All read",
                      })
                    }
                    className={
                      "cursor-pointer mt-1 group flex items-center px-3 py-2 text-xl leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150 " +
                      (tab === "allRead" && "bg-gray-100")
                    }
                  >
                    <span className="truncate">All read</span>
                    <span className="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-gray-600 bg-gray-100 group-hover:bg-gray-200 group-focus:bg-gray-200 transition ease-in-out duration-150">
                      {readCount}
                    </span>
                  </div>
                </nav>
              </div>
              <div className="col-lg-9">
                <div>
                  <span className="notification-header-title">
                    {headerTitle}
                  </span>
                  {tab === "all" && (
                    <div style={{ float: "right", display: "inline" }}>
                      <Tippy content="Mark all as read" placement="right-start">
                        <div
                          onClick={this.markAllAsReadWrapper}
                          className="notification-header-icon"
                          style={{ cursor: "pointer" }}
                        >
                          <Icon
                            name="check square outline"
                            style={{ color: "#565656" }}
                          />
                          {/* <i title="Mark all as read" className="fas fa-tasks"></i> */}
                        </div>
                      </Tippy>
                    </div>
                  )}
                  {tab === "allRead" && (
                    <div style={{ float: "right", display: "inline" }}>
                      <Tippy
                        content="Delete all notifications permanently"
                        placement="right-start"
                      >
                        <div
                          onClick={this.deleteAllPerm}
                          className="notification-header-icon"
                          style={{ cursor: "pointer" }}
                        >
                          <Icon
                            name="trash alternate outline"
                            style={{ color: "#565656" }}
                          />
                          {/* <i title="Mark all as read" className="fas fa-tasks"></i> */}
                        </div>
                      </Tippy>
                    </div>
                  )}
                </div>

                <div className="ui divider"></div>
                {notifications.length === 0 &&
                readNotifications.length === 0 ? (
                  <div className="text-center">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#565656",
                      }}
                    >
                      You don't have any notification.
                    </p>
                  </div>
                ) : (
                  <Feed>
                    {this.renderNotifications(
                      notifications,
                      readNotifications,
                      tab
                    )}
                  </Feed>
                )}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Notifications);
