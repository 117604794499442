import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer footer-bar">
          <div className="container ml-3">
            <div className="row">
              <div className="text-sm-left">
                <p className="mb-0" style={{ fontSize: 13 }}>
                  © {new Date().getFullYear()} IndieLog made with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by{" "}
                  <a
                    href="https://twitter.com/damengchen"
                    target="_blank"
                    style={{ color: "inherit" }}
                    rel="noopener noreferrer"
                  >
                    Damon
                  </a>
                  {"  |  "}
                  <a
                    href="https://blog.indielog.com"
                    target="_blank"
                    style={{ color: "inherit" }}
                    rel="noopener noreferrer"
                  >
                    Our blog
                  </a>
                  {"  |  "}
                  <a
                    href="https://t.me/indielog"
                    target="_blank"
                    style={{ color: "inherit" }}
                    rel="noopener noreferrer"
                  >
                    Telegram
                  </a>
                  {"  |  "}
                  <a
                    href="https://indielog.backlogs.co/changelog"
                    target="_blank"
                    style={{ color: "inherit" }}
                    rel="noopener noreferrer"
                  >
                    Changelog
                  </a>
                  {"  |  "}
                  <a
                    href="https://indielog.com/privacy"
                    target="_blank"
                    style={{ color: "inherit" }}
                    rel="noopener noreferrer"
                  >
                    Privacy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
