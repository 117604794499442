// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./PageError.css";

// import images
import img404 from "../../assets/images/404.png";

class PageError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <section className="bg-errors">
          <div className="headerSpace container"></div>
          <div className="home-center">
            <div className="home-desc-center">
              <div className="container">
                <Row className="justify-content-center">
                  <Col lg={8} md={12} className="text-center">
                    <img
                      src={img404}
                      className="img-fluid mx-auto"
                      alt=""
                      style={{ width: "420px" }}
                    />
                    <div className="mt-4 text-dark display-3">Oh no!</div>
                    <div className="text-capitalize text-dark mb-4 error-page">
                      Page Not Found
                    </div>
                  </Col>
                </Row>

                <Row>
                  <div className="col-md-12 text-center">
                    <Link to="/" className="btn btn-outline-primary mt-lg-5">
                      Go back to homepage
                    </Link>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default PageError;
