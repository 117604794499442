import React from "react";
import ProductList from "../../components/ProductList/ProductList";

class UserProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { products, profileUser } = this.props;
    return (
      <div className="home-page">
        <div className="container">
          <ProductList
            profileUser={profileUser}
            products={Object.values(products)}
            hideAvatar={true}
          />
        </div>
      </div>
    );
  }
}
export default UserProducts;
