import React, { Component } from "react";
import { Comment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";
import { formatDistance } from "date-fns";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import Linkify from "linkifyjs/react";
import { toast } from "react-toastify";
import sizeMe from "react-sizeme";
import { Modal, ListGroup, Button } from "react-bootstrap";
import VideoPlayer from "./VideoPlayer";
import { setUserValue } from "../../redux/action/userAction";

class RenderComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
      selectedCommentId: null,
      showVideoModal: false,
      showVideoId: "",
      showReportVideoModal: false,
      reportReason: "",
      reportComment: null,
      isEditComment: false
    };
  }

  handleCloseReplyForm = () => {
    this.setState({
      selectedCommentId: null,
      showReplyForm: false,
      isEditComment: false
    });
    this.props.setUserValue({ showAddNewVideoModal: false });
  };

  handleOpenReplyForm = id => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id
      });
    }
  };

  handleOpenEditReplyForm = id => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id,
        isEditComment: true
      });
    }
  };

  onShowAmaAnswerModal = (videoId, commentParentId) => () => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({
        showAddNewVideoModal: true,
        videoId: videoId,
        commentParentId: commentParentId,
        AmaAnswer: true
      });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  onClickVideoReply = videoPlaybackId => () => {
    this.setState({ showVideoModal: true, showVideoId: videoPlaybackId });
  };

  handleModalClose = () => {
    this.setState({ showVideoModal: false, showReportVideoModal: false });
  };

  reportVideo = comment => () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    this.setState({ showReportVideoModal: true, reportComment: comment });
  };

  sendReport = async () => {
    const { reportComment, reportReason } = this.state;
    const { videoId, currentUser } = this.props;

    //add the report to /report/{reportId}
    await firebase
      .database()
      .ref("comment-reports")
      .child(videoId)
      .child(reportComment.id)
      .set({
        comment: reportComment,
        commentVideoURL:
          "https://stream.mux.com/" + reportComment.playbackIds[0].id + ".m3u8",
        reason: reportReason,
        judge: "pending",
        reportedBy: currentUser
      });
    //update the report status in comment's object
    await firebase
      .database()
      .ref("comments")
      .child(videoId)
      .child(reportComment.id)
      .update({ badComment: "pending" });

    toast.success("Thanks for reporting! We will take a look soon.", {
      autoClose: 4000
    });
    this.setState({ showReportVideoModal: false });
  };

  onUpvote = async comment => {
    const { currentUser } = this.props.user;
    const { video } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }
    const upvoteRef = await firebase
      .database()
      .ref("AMA-QA")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child(comment.id)
      .child("upvoteCount");
    upvoteRef.transaction(function(currentUpvoteCount) {
      return (currentUpvoteCount || 0) + 1;
    });

    await firebase
      .database()
      .ref("AMA-QA")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child(comment.id)
      .child("upvotedBy")
      .child(currentUser.uid)
      .set({ upvoted: true });
  };

  onUnupvote = async comment => {
    const { currentUser } = this.props.user;
    const { video } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    const videoRef = await firebase
      .database()
      .ref("AMA-QA")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child(comment.id)
      .child("upvoteCount");
    videoRef.transaction(function(currentUpvoteCount) {
      return currentUpvoteCount - 1;
    });

    await firebase
      .database()
      .ref("AMA-QA")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child(comment.id)
      .child("upvotedBy")
      .child(currentUser.uid)
      .remove();
  };

  render() {
    const {
      videoId,
      video,
      comment,
      currentUser,
      setUserValue,
      size,
      showReply
    } = this.props;
    const { width } = size;
    const { showVideoModal, showVideoId, showReportVideoModal } = this.state;
    var nestedCommentsInGroups;
    if (comment && comment.childNodes && comment.childNodes.length > 0) {
      nestedCommentsInGroups = (
        <Comment.Group threaded>
          {(comment.childNodes || []).map(comment => {
            return (
              <RenderComments
                key={comment.id}
                comment={comment}
                currentUser={currentUser}
                setUserValue={setUserValue}
                videoId={videoId}
                video={video}
                size={size}
                showReply={false}
              />
            );
          })}
        </Comment.Group>
      );
    } else {
      nestedCommentsInGroups = null;
    }

    const videoPoster =
      showVideoId !== "" &&
      "https://image.mux.com/" + showVideoId + "/thumbnail.png";
    const videoURL =
      showVideoId !== "" && "https://stream.mux.com/" + showVideoId + ".m3u8";

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: true,
      controls: true,
      fluid: true
    };

    return (
      <>
        <Modal //report video
          show={showReportVideoModal}
          onHide={this.handleModalClose}
          top
        >
          <Modal.Header closeButton>
            <Modal.Title>Why are you reporting this video reply?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Inappropriate" })}
              >
                Inappropriate
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Off-Topic" })}
              >
                Off-Topic
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Spam" })}
              >
                Spam
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() =>
                  this.setState({ reportReason: "Sexual, Violence" })
                }
              >
                Sexual, Violence
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Others" })}
              >
                Others
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary px-5" onClick={this.sendReport}>
              Go Report
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal //show video
          show={showVideoModal}
          onHide={this.handleModalClose}
          size="lg"
          centered
        >
          <Modal.Body style={{ padding: 0 }}>
            <VideoPlayer
              {...videoJsOptions}
              poster={videoPoster}
              src={videoURL}
            />
          </Modal.Body>
        </Modal>
        {width > 750 ? (
          <div className="row mb-3">
            <Comment key={comment.id} className="col-4">
              <Comment.Avatar
                src={comment.photoURL || "/assets/user.png"}
                as={Link}
                to={
                  comment.userName
                    ? `/user/${comment.userName}`
                    : `/user/${comment.uid}`
                }
              />

              <Comment.Content>
                <Comment.Author
                  as={Link}
                  to={
                    comment.userName
                      ? `/user/${comment.userName}`
                      : `/user/${comment.uid}`
                  }
                >
                  {comment.displayName}
                </Comment.Author>
                <Comment.Metadata>
                  <div>{formatDistance(comment.date, Date.now())} ago</div>
                </Comment.Metadata>
                <Comment.Text>
                  {comment.type === "AMA-QA" &&
                  comment.playbackIds &&
                  comment.playbackIds[0] &&
                  comment.playbackIds[0].id ? (
                    !comment.badComment ||
                    (comment.badComment && comment.badComment === "no") ? (
                      <img
                        src={
                          "https://image.mux.com/" +
                          comment.playbackIds[0].id +
                          "/animated.gif?width=350"
                        }
                        alt=""
                        className="ama_qa_thumbnail"
                        onClick={this.onClickVideoReply(
                          comment.playbackIds[0].id
                        )}
                      />
                    ) : (
                      <span style={{ backgroundColor: "antiquewhite" }}>
                        This video reply is reported.
                      </span>
                    )
                  ) : (
                    <Linkify style={{ whiteSpace: "per-line" }}>
                      {comment.comment}
                    </Linkify>
                  )}
                </Comment.Text>
                <Comment.Actions>
                  <Comment.Action
                    onClick={this.handleOpenReplyForm(comment.id)}
                  >
                    {comment.upvotedBy &&
                    currentUser &&
                    comment.upvotedBy[currentUser.uid] ? (
                      <div
                        onClick={() => this.onUnupvote(comment)}
                        style={{ cursor: "pointer", fontWeight: 700 }}
                      >
                        <i className="fas fa-chevron-up"></i> Upvote (
                        {comment.upvoteCount})
                      </div>
                    ) : (
                      <div
                        onClick={() => this.onUpvote(comment)}
                        style={{ cursor: "pointer", fontWeight: 700 }}
                      >
                        <i className="fas fa-chevron-up"></i> Upvote
                        {comment.upvoteCount > 0 &&
                          "(" + comment.upvoteCount + ")"}
                      </div>
                    )}
                  </Comment.Action>
                  {currentUser &&
                    video.user.uid === currentUser.uid &&
                    comment &&
                    (!comment.childNodes ||
                      (comment.childNodes &&
                        comment.childNodes.length === 0)) && (
                      <Comment.Action
                        onClick={this.onShowAmaAnswerModal(videoId, comment.id)}
                      >
                        Reply
                      </Comment.Action>
                    )}
                  {comment.type === "VIDEO" && (
                    <Comment.Action onClick={this.reportVideo(comment)}>
                      <i className="fas fa-exclamation-circle fa-sm"></i>
                    </Comment.Action>
                  )}
                </Comment.Actions>
              </Comment.Content>
            </Comment>
            <div
              className="col-4"
              style={{ margin: "auto", textAlign: "center" }}
            >
              <h5 className="wavy-underline">{comment.comment} </h5>
            </div>

            {comment && comment.childNodes && comment.childNodes.length > 0 ? (
              comment.childNodes.map(comment => {
                return (
                  <Comment
                    key={comment.id}
                    className="col-4 reverse"
                    style={{
                      textAlign: "right",
                      marginTop: 0,
                      paddingTop: 0
                    }}
                  >
                    <Comment.Avatar
                      src={comment.photoURL || "/assets/user.png"}
                      as={Link}
                      to={
                        comment.userName
                          ? `/user/${comment.userName}`
                          : `/user/${comment.uid}`
                      }
                      style={{ float: "right" }}
                    />
                    <Comment.Content
                      style={{ marginLeft: 0, marginRight: "3.5em" }}
                    >
                      <Comment.Author
                        as={Link}
                        to={
                          comment.userName
                            ? `/user/${comment.userName}`
                            : `/user/${comment.uid}`
                        }
                      >
                        {comment.displayName}
                      </Comment.Author>
                      <Comment.Metadata>
                        <div>
                          {formatDistance(comment.date, Date.now())} ago
                        </div>
                      </Comment.Metadata>
                      <Comment.Text>
                        {comment.type === "AMA-QA" &&
                        comment.playbackIds &&
                        comment.playbackIds[0] &&
                        comment.playbackIds[0].id ? (
                          !comment.badComment ||
                          (comment.badComment &&
                            comment.badComment === "no") ? (
                            <img
                              src={
                                "https://image.mux.com/" +
                                comment.playbackIds[0].id +
                                "/animated.gif?width=350"
                              }
                              alt=""
                              className="ama_qa_thumbnail"
                              onClick={this.onClickVideoReply(
                                comment.playbackIds[0].id
                              )}
                            />
                          ) : (
                            <span style={{ backgroundColor: "antiquewhite" }}>
                              This video reply is reported.
                            </span>
                          )
                        ) : (
                          <Linkify style={{ whiteSpace: "per-line" }}>
                            {comment.comment}
                          </Linkify>
                        )}
                      </Comment.Text>
                      <Comment.Actions>
                        <Comment.Action
                          onClick={this.handleOpenReplyForm(comment.id)}
                        >
                          {comment.upvotedBy &&
                          currentUser &&
                          comment.upvotedBy[currentUser.uid] ? (
                            <div
                              onClick={() => this.onUnupvote(comment)}
                              style={{ cursor: "pointer", fontWeight: 700 }}
                            >
                              <i className="fas fa-chevron-up"></i> Upvote (
                              {comment.upvoteCount})
                            </div>
                          ) : (
                            <div
                              onClick={() => this.onUpvote(comment)}
                              style={{ cursor: "pointer", fontWeight: 700 }}
                            >
                              <i className="fas fa-chevron-up"></i> Upvote
                              {comment.upvoteCount > 0 &&
                                "(" + comment.upvoteCount + ")"}
                            </div>
                          )}
                        </Comment.Action>
                      </Comment.Actions>
                    </Comment.Content>
                  </Comment>
                );
              })
            ) : (
              <div className="col-4" />
            )}
          </div>
        ) : (
          <Comment key={comment.id}>
            <Comment.Avatar
              src={comment.photoURL || "/assets/user.png"}
              as={Link}
              to={
                comment.userName
                  ? `/user/${comment.userName}`
                  : `/user/${comment.uid}`
              }
            />
            <Comment.Content>
              <Comment.Author
                as={Link}
                to={
                  comment.userName
                    ? `/user/${comment.userName}`
                    : `/user/${comment.uid}`
                }
              >
                {comment.displayName}
              </Comment.Author>
              <Comment.Metadata>
                <div>{formatDistance(comment.date, Date.now())} ago</div>
              </Comment.Metadata>
              <Comment.Text>
                {comment.type === "AMA-QA" &&
                comment.playbackIds &&
                comment.playbackIds[0] &&
                comment.playbackIds[0].id ? (
                  !comment.badComment ||
                  (comment.badComment && comment.badComment === "no") ? (
                    <img
                      src={
                        "https://image.mux.com/" +
                        comment.playbackIds[0].id +
                        "/animated.gif?width=250"
                      }
                      alt=""
                      className="ama_qa_thumbnail"
                      onClick={this.onClickVideoReply(
                        comment.playbackIds[0].id
                      )}
                    />
                  ) : (
                    <span style={{ backgroundColor: "antiquewhite" }}>
                      This video reply is reported.
                    </span>
                  )
                ) : (
                  <Linkify style={{ whiteSpace: "per-line" }}>
                    {comment.comment}
                  </Linkify>
                )}
              </Comment.Text>
              <p className="wavy-underline">{comment.comment}</p>

              <Comment.Actions>
                <Comment.Action onClick={this.handleOpenReplyForm(comment.id)}>
                  {comment.upvotedBy &&
                  currentUser &&
                  comment.upvotedBy[currentUser.uid] ? (
                    <div
                      onClick={() => this.onUnupvote(comment)}
                      style={{ cursor: "pointer", fontWeight: 700 }}
                    >
                      <i className="fas fa-chevron-up"></i> Upvote (
                      {comment.upvoteCount})
                    </div>
                  ) : (
                    <div
                      onClick={() => this.onUpvote(comment)}
                      style={{ cursor: "pointer", fontWeight: 700 }}
                    >
                      <i className="fas fa-chevron-up"></i> Upvote
                      {comment.upvoteCount > 0 &&
                        "(" + comment.upvoteCount + ")"}
                    </div>
                  )}
                </Comment.Action>
                {showReply &&
                  currentUser &&
                  video.user.uid === currentUser.uid &&
                  comment &&
                  (!comment.childNodes ||
                    (comment.childNodes &&
                      comment.childNodes.length === 0)) && (
                    <Comment.Action
                      onClick={this.onShowAmaAnswerModal(videoId, comment.id)}
                    >
                      Reply
                    </Comment.Action>
                  )}
              </Comment.Actions>
            </Comment.Content>
            {nestedCommentsInGroups}
          </Comment>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state && state.user
  };
};

const mapDispatchToProps = dispatch => ({
  setUserValue: state => {
    return dispatch(setUserValue(state));
  }
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(sizeMe()(RenderComments));
