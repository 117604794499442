import React, { Fragment } from "react";
import firebase from "firebase/app";
import "firebase/database";
import PulseLoader from "react-spinners/PulseLoader";
import VideoPlayer from "./VideoPlayer";
import AmaDetails from "./AmaDetails";
import AmaUserInfo from "./AmaUserInfo";
import Comments from "./Comments";
import { objectToArray, createCommentsTree } from "./helpers";
import "./AmaVideo.css";

let commentsListener;
let videoListener;
let needUnmount = true;
class AmaVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      videoPoster: "",
      videoSrc: "",
      video: null,
      videoId: "",
    };
  }

  componentDidMount = async () => {
    var videoId = this.props.match.params.videoId;
    videoId = "damon-chen";

    const videoCheck = await firebase
      .database()
      .ref("AMAs")
      .child("damon-chen")
      .once("value");

    if (videoCheck.val() === null || videoCheck.val() === undefined) {
      needUnmount = false;
      this.props.history.push("/error");
    } else {
      var videoPoster =
        "https://image.mux.com/" +
        "KbzDFAqCS6fBFV777aze400YShg1wxeqgadr01EdDKaAY" +
        "/thumbnail.png";
      var videoSrc =
        "https://stream.mux.com/KbzDFAqCS6fBFV777aze400YShg1wxeqgadr01EdDKaAY.m3u8";
      this.setState({
        videoPoster: videoPoster,
        videoSrc: videoSrc,
        videoId: videoId,
      });

      videoListener = firebase
        .database()
        .ref("AMAs")
        .child(videoId)
        .on("value", (snapshot) => {
          if (snapshot) {
            this.setState({
              video: snapshot.val(),
              videoPoster: snapshot.val().thumbnail || videoPoster,
            });
          } else {
            // if (snapshot === null || snapshot === undefined) {
            //   this.props.history.push("/error");
            // }
            console.log("no video", snapshot);
          }
        });

      commentsListener = firebase
        .database()
        .ref("AMA-QA")
        .child(videoId)
        .on("value", (snapshot) => {
          if (snapshot) {
            this.setState({ comments: objectToArray(snapshot.val()) });
          }
        });

      //increment the view count
      const videoRef = await firebase
        .database()
        .ref("videos")
        .child(videoId)
        .child("viewCount");
      videoRef.transaction(function (currentViewCount) {
        return (currentViewCount || 0) + 1;
      });
    }
  };

  componentWillUnmount() {
    if (needUnmount) {
      this.state.videoId &&
        firebase
          .database()
          .ref("videos")
          .child(this.state.videoId)
          .off("value", videoListener);

      this.state.videoId &&
        firebase
          .database()
          .ref("comments")
          .child(this.state.videoId)
          .off("value", commentsListener);
    }
  }

  render() {
    const { videoPoster, videoSrc, video } = this.state;

    if (video === null) {
      return (
        <div className="text-center" style={{ marginTop: 200 }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
        </div>
      );
    }

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: false,
      controls: true,
      fluid: true,
      aspectRatio: "16:9",
    };

    const { comments } = this.state;
    const commentsTree =
      comments && Object.keys(comments).length > 0
        ? createCommentsTree(comments)
        : [];

    return (
      <Fragment>
        <section className="bg-video">
          <div className="videoInfos container">
            <VideoPlayer
              {...videoJsOptions}
              poster={videoPoster}
              src={videoSrc}
            />
            <AmaDetails video={video} />
            <hr className="margin_top_z" />
            <AmaUserInfo video={video} />
            <hr />
            <Comments comments={commentsTree} video={video} />
          </div>
        </section>
      </Fragment>
    );
  }
}

export default AmaVideo;
