import React, { Component } from "react";
import { Form, Button, Dropdown } from "semantic-ui-react";
import { Picker, emojiIndex } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

class AddCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: this.props.isEditComment ? this.props.comment.comment : "",
      emojiPicker: false,
    };
    console.log();
  }

  handleCommentSubmit = async (isPrivate) => {
    const {
      addVideoComment,
      editVideoComment,
      videoId,
      closeForm,
      parentId,
      isEditComment,
    } = this.props;
    const { comment } = this.state;
    var ret = isEditComment
      ? editVideoComment(videoId, comment, parentId)
      : await addVideoComment(videoId, comment, parentId, isPrivate);
    if (parentId !== 0) {
      closeForm();
    }
    ret !== "loginNeeded" && this.setState({ comment: "" });
  };

  handleTogglePicker = () => {
    this.setState({ emojiPicker: !this.state.emojiPicker });
  };

  handleAddEmoji = (emoji) => {
    const oldComment = this.state.comment;
    const newComment = `${oldComment} ${emoji.native} `;
    this.setState({ comment: newComment, emojiPicker: false });
  };

  options = [
    {
      key: "privateReply",
      icon: "reply",
      text: "Private Reply",
      value: "privateReply",
      onClick: () => this.handleCommentSubmit(true),
    },
  ];

  render() {
    const {
      allowCancel,
      onShowReplyInVideoModal,
      parentComment,
      isEditComment,
      comment,
      videoOwner,
    } = this.props;
    const { emojiPicker } = this.state;

    return (
      <Form className={isEditComment ? "mt-0" : "mt-2"}>
        {emojiPicker && (
          <Picker
            set="apple"
            onSelect={this.handleAddEmoji}
            className="emojipicker"
            title="Pick your emoji"
            emoji="point_up"
            native
          />
        )}
        <Form.TextArea
          value={this.state.comment}
          onChange={(e, { value }) => {
            this.setState({ comment: value });
          }}
          placeholder={
            videoOwner
              ? "Say something to " + videoOwner.split(" ")[0] + "..."
              : ""
          }
          className="mb-0"
        />
        <Form.Field className="text-muted" style={{ fontSize: "14px" }}>
          <i className="fab fa-markdown"></i> Markdown is supported. (
          <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">
            cheatsheet
          </a>
          )
        </Form.Field>
        <Button onClick={this.handleTogglePicker}>
          <i className="far fa-laugh"></i>
        </Button>

        {parentComment && (
          <>
            <Button.Group className="ml-2" basic color="blue">
              <Button
                content={isEditComment ? "Save changes" : "Reply"}
                onClick={() => this.handleCommentSubmit(false)}
              />
              <Dropdown
                className="button icon"
                floating
                options={this.options}
                trigger={<React.Fragment />}
                style={{ borderLeft: 0 }}
              />
            </Button.Group>

            <Button
              content="Video Reply"
              basic
              color="red"
              onClick={onShowReplyInVideoModal}
              className="ml-2"
              style={{ color: "#fff" }}
            />
          </>
        )}
        {allowCancel && (
          <>
            <Button
              content={isEditComment ? "Save changes" : "Reply"}
              basic
              color="blue"
              onClick={() =>
                this.handleCommentSubmit(comment.isPrivate || false)
              }
              className="ml-2"
            />
            <Button
              content="Cancel"
              basic
              onClick={this.props.closeForm}
              className="ml-2"
            />
          </>
        )}
      </Form>
    );
  }
}

export default AddCommentForm;
