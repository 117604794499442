import React, { Component } from "react";
// import VideojsRecorder from "./VideojsRecorder";
import VideoRecorder from "react-video-recorder";
import PulseLoader from "react-spinners/PulseLoader";

// const videoJsOptions = {
//   controls: true,
//   width: 480,
//   height: 360,
//   fluid: true,
//   plugins: {
//     record: {
//       audio: true,
//       video: true,
//       maxLength: 120,
//       debug: true
//     }
//   }
// };
class RecordPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false
    };
  }

  componentDidMount() {
    if (this.props.type === "COMMENT") {
      this.props.modalHeader("Reply in a video");
    } else if (this.props.type === "NEW-VIDEO") {
      this.props.modalHeader("Record a video");
    } else if (this.props.type === "PRODUCT-PITCH") {
      this.props.modalHeader("Record a product pitch");
    } else if (this.props.isAMA) {
      this.props.modalHeader("Record an AMA video");
    } else if (this.props.AmaQuestion) {
      this.props.modalHeader("Record a question");
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.isUploading !== prevProps.isUploading) {
      this.props.modalHeader("Uploading");
    }
  };

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  continueSubmitVideoReply = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { showButton } = this.state;
    const {
      type,
      isUploading,
      muxUpload,
      isFileUpload,
      AmaAnswer,
      AmaQuestion
    } = this.props;

    var timeLimit;

    if (type === "PRODUCT-PITCH") {
      timeLimit = 30000;
    } else if (AmaQuestion) {
      timeLimit = 60000;
    } else if (AmaAnswer) {
      timeLimit = 300000;
    } else {
      timeLimit = 120000;
    }

    return (
      <div className="uploadForm">
        <div className="uploadMainContent">
          <div className="preview_video_file">
            {/* <VideojsRecorder {...videoJsOptions} /> */}
            <VideoRecorder
              isOnInitially
              onRecordingComplete={videoBlob => {
                this.setState({ showButton: true });
                this.props.setVideoBlob(videoBlob);
              }}
              timeLimit={timeLimit}
              useVideoInput={isFileUpload}
            />
          </div>
        </div>
        {showButton && (
          <div className="nextPrevBtn">
            {type === "COMMENT" && (
              <button
                className="good btn btn-danger my-1"
                onClick={this.props.onUploadVideoReply}
                disabled={isUploading || muxUpload === null ? true : false}
              >
                {isUploading || muxUpload === null ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Reply"
                )}
              </button>
            )}

            {AmaAnswer && (
              <button
                className="good btn btn-danger my-1"
                onClick={this.props.onUploadAmaAnswer}
                disabled={isUploading || muxUpload === null ? true : false}
              >
                {isUploading || muxUpload === null ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Reply"
                )}
              </button>
            )}

            {type === "PRODUCT-PITCH" && (
              <button
                className="good btn btn-danger my-1"
                onClick={this.props.onUploadProductPitchVideo}
                disabled={isUploading || muxUpload === null ? true : false}
              >
                {isUploading || muxUpload === null ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Submit"
                )}
              </button>
            )}

            {(type === "NEW-VIDEO" || (AmaQuestion && AmaAnswer === false)) && (
              <button
                className="good btn btn-danger my-1"
                onClick={this.continue}
              >
                Good
              </button>
            )}
            <button
              className="not-good btn btn-danger my-1"
              onClick={this.back}
            >
              Redo
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default RecordPreview;
