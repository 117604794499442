import React from "react";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";

const getName = (name) => {
  const nameArr = name.split(" ");
  if (nameArr.length > 1) {
    return nameArr[0][0] + nameArr[1][0];
  } else {
    if (nameArr[0].length > 1) {
      return nameArr[0].substring(0, 2);
    } else {
      return nameArr[0][0];
    }
  }
};

class VideoProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { video } = this.props;

    const letterLogo = getName(video.product.name);

    return (
      <div className="mb-4">
        <h5 className="sidebar-header">Update for product👇</h5>
        <div className="video-product-item p-0">
          <a href={"/product/" + video.product.id} target="_blank">
            {video.product.logoImg !== "" ? (
              <img className="logo" src={video.product.logoImg} />
            ) : (
              <Avatar variant="square">{letterLogo}</Avatar>
            )}
          </a>

          <a
            className="infos"
            href={"/product/" + video.product.id}
            target="_blank"
          >
            <p className="video-product-name">{video.product.name}</p>
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(VideoProduct);
