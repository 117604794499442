import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ConvertKitForm from "convertkit-react";
import { withRouter } from "react-router-dom";

const SubscribeForm = ({ status, message, onValidated }) => {
  let email, name;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="col px-0">
      <div className="subscription-form p-0 col-12">
        <input
          style={{ fontSize: "1em", padding: 5 }}
          ref={(node) => (email = node)}
          type="email"
          placeholder="Your email"
          className="form-control input-email"
        />
        <button className="btn btn-primary py-2 subscribe-btn" onClick={submit}>
          {status === null && "Subscribe"}
          {status === "success" && "Subscribed 🍻"}
          {status === "error" && "Error ⛈"}
          {status === "sending" && "Sending..."}
        </button>
      </div>
      <div className="col-12 px-0" style={{ fontSize: "14px" }}>
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </div>
  );
};

class LeadTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { video } = this.props;

    if (video.product.lead === "website") {
      var prefix = "https://";
      var webURL = video.product.website;
      if (webURL.substr(0, prefix.length) !== prefix) {
        webURL = prefix + webURL;
      }

      return (
        <div className="mb-4">
          <h5 className="sidebar-header">
            Go to{" "}
            <a href={webURL} target="_blank">
              <span className="video-product-name">
                <span style={{ fontWeight: 800 }}>{video.product.name}</span>
              </span>
            </a>
          </h5>
        </div>
      );
    } else if (video.product.lead === "mailchimp") {
      const mailChimpURL = video.product.leadMC;
      return (
        <div className="mb-4">
          <h5 className="sidebar-header">
            Subscribe to{" "}
            <span className="video-product-name">
              <span style={{ fontWeight: 800 }}>{video.product.name}</span>'s
              newsletter
            </span>
          </h5>
          <div className="video-product-item p-0">
            <MailchimpSubscribe
              url={mailChimpURL}
              render={({ subscribe, status, message }) => (
                <SubscribeForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </div>
      );
    } else if (video.product.lead === "convertkit") {
      const ckFormId = video.product.leadCK;
      return (
        <div className="mb-4">
          <h5 className="sidebar-header">
            Subscribe to{" "}
            <span className="video-product-name">
              <span style={{ fontWeight: 800 }}>{video.product.name}</span>'s
              newsletter
            </span>
          </h5>
          <div className="video-product-item p-0">
            <ConvertKitForm
              formId={ckFormId}
              hideName={true}
              newTab={true}
              template="clare"
              className="ck-form"
              submitText="Subscribe"
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withRouter(LeadTool);
