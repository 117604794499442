import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isAfter } from "date-fns";
import { setUserValue } from "../../redux/action/userAction";
import MeetupItem from "./MeetupItem";
import "./MeetupList.css";

class MeetupList extends React.Component {
  onShowAddNewMeetupModal = () => {
    this.props.setUserValue({ showAddNewMeetupModal: true });
  };
  render() {
    const { meetups, title, user, hideAvatar } = this.props;
    const { currentUser } = user;
    return (
      <div className="meetup-list">
        <h2
          style={{ textAlign: "center", fontWeight: "bold" }}
          className="mb-3"
        >
          {title}
        </h2>
        {meetups &&
          meetups[0] &&
          meetups.map(
            (meetup) =>
              Date.now() < meetup.expiredAt * 1000 && (
                <MeetupItem
                  key={meetup.id}
                  meetup={meetup}
                  hideAvatar={hideAvatar}
                  currentUser={currentUser}
                />
              )
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetupList);
