import React from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";

class NoYoutubeChannelAccessModal extends React.Component {
  handleModalClose = () => {
    this.props.setUserValue({ showNoAddYoutubeAccessModal: false });
  };

  goToProfilePage = () => {
    this.props.history.push("/profile");
  };

  render() {
    const { showNoAddYoutubeAccessModal } = this.props.user;

    return (
      <Modal show={showNoAddYoutubeAccessModal} onHide={this.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>How to add your YouTube channel?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>In order to add your YouTube channel, there're 3 steps 👇</p>
          <ListGroup>
            <ListGroup.Item variant="info">
              1. Complete your profile, add your name, bio, city and at least
              two social links 😎
            </ListGroup.Item>
            <ListGroup.Item variant="info">
              2. Record your 1st video on IndieLog, just a warm intro. Don't
              take it serious 😉
            </ListGroup.Item>
            <ListGroup.Item variant="info">
              3. All done! Come back this page and add your channel 🤗
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-5" onClick={this.goToProfilePage}>
            Go to profile
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NoYoutubeChannelAccessModal);
