import React from "react";
import { connect } from "react-redux";
import "firebase/database";
import StackGrid from "react-stack-grid";
import sizeMe from "react-sizeme";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";
import SingleVideoCard from "../../components/shared/SingleVideoCard";

class ProductVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onShowAddNewVideoModal = () => {
    const { currentUser } = this.props.user;
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({ showAddNewVideoModal: true });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  render() {
    const { videos } = this.props;

    if (videos === null) {
      return (
        <div className="text-center" style={{ marginTop: 100 }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
        </div>
      );
    }

    const {
      size: { width }
    } = this.props;

    if ((videos && videos.length === 0) || !videos) {
      return (
        <div className="text-center mt-3">
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "rgba(0, 0, 0, 0.85)"
            }}
          >
            No Videos Found!
          </p>
        </div>
      );
    }

    return (
      <div className="row container-fluid">
        <div className="col-12">
          <div className="row mt-4">
            {videos &&
              Object.keys(videos).length > 0 &&
              Object.values(videos).map(
                video =>
                  video.status === "video.asset.ready" && (
                    <SingleVideoCard
                      key={video.playbackIds[0].id}
                      video={video}
                      profileView={false}
                    />
                  )
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state && state.user
  };
};

const mapDispatchToProps = dispatch => ({
  setUserValue: state => {
    return dispatch(setUserValue(state));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(sizeMe()(ProductVideos));
