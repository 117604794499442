import React from "react";
import { Header, Segment, Feed } from "semantic-ui-react";
import JustJoinedProductsItem from "./JustJoinedProductsItem";
import "./JustJoinedProducts.css";

const JustJoinedProducts = ({ products, contextRef }) => {
  return (
    <div className="mt-4 mb-4">
      <Header attached="top" content="Products joined this week 👏" />
      <Segment attached style={{ overflow: "auto", maxHeight: "40vh" }}>
        <Feed>
          {products.length > 0 ? (
            products.map((product) => (
              <JustJoinedProductsItem key={product.id} product={product} />
            ))
          ) : (
            <h5 className="text-muted" style={{ fontSize: "16px" }}>
              Add your product this week? 💪
            </h5>
          )}
        </Feed>
      </Segment>
    </div>
  );
};

export default JustJoinedProducts;
