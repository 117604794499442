import React, { Fragment } from "react";
import { Form, Button, Feed, Icon } from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import { format, formatDistance, isToday, isSameDay } from "date-fns";
import { setUserValue } from "../../redux/action/userAction";
import { objectToArray } from "../Video/helpers";
import "./LazyUpdate.css";

let feedListener;

class LazyUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newLog: "",
      feeds: [],
      prevDay: Date.now() - 86400000,
      currDay: Date.now(),
      dateSet: false
    };
  }

  componentDidMount = async () => {
    feedListener = firebase
      .database()
      .ref("lazy-updates-feeds")
      .on("value", snapshot => {
        if (snapshot) {
          this.setState({ feeds: objectToArray(snapshot.val()) });
        }
      });
  };

  componentWillUnmount() {
    this.state.videoId &&
      firebase
        .database()
        .ref("lazy-updates-feeds")
        .off("value", feedListener);
  }

  renderFeeds = feeds => {
    if (!feeds) return [];

    var reversedFeeds = Object.values(feeds).reverse();
    const { prevDay, currDay } = this.state;

    return reversedFeeds.map(feed => {
      return (
        <>
          {/* {this.setDateHeader(feed, prevDay, currDay)} */}
          <Feed.Event key={feed.createdAt}>
            <Feed.Label image={feed.avatar} />
            <Feed.Content>
              <Feed.Summary>
                <a>{feed.displayName}</a>
                <Feed.Date>
                  {formatDistance(feed.createdAt, Date.now())} ago
                </Feed.Date>
              </Feed.Summary>
              <Feed.Extra text>{feed.log}</Feed.Extra>
              {/* <Feed.Meta>
              <Feed.Like>
                <Icon name="like" />
                {feed.likes} Likes
              </Feed.Like>
            </Feed.Meta> */}
            </Feed.Content>
          </Feed.Event>
        </>
      );
    });
  };

  postNewLog = async () => {
    const { currentUser } = this.props.user;
    const { newLog } = this.state;

    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    if (newLog === "") {
      toast.error("Sorry, log cannot be empty :(", {
        autoClose: 4000
      });
      return;
    }

    let newLogObj = currentUser.userName
      ? {
          displayName: currentUser.displayName,
          avatar: currentUser.avatar,
          uid: currentUser.uid,
          userName: currentUser.userName,
          email: currentUser.email || "",
          log: newLog,
          likes: 0,
          createdAt: Date.now()
        }
      : {
          displayName: currentUser.displayName,
          avatar: currentUser.avatar,
          uid: currentUser.uid,
          email: currentUser.email || "",
          log: newLog,
          likes: 0,
          createdAt: Date.now()
        };

    try {
      const newLogRef = await firebase
        .database()
        .ref("lazy-updates-feeds")
        .push(newLogObj);
      const newLogId = newLogRef.key;

      await firebase
        .database()
        .ref("lazy-updates")
        .child(currentUser.uid)
        .child(newLogId)
        .set(newLogObj);

      await firebase
        .database()
        .ref("activities")
        .child(currentUser.uid)
        .push({
          type: "New Lazy Update",
          content: {
            displayName: currentUser.displayName,
            avatar: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser.email || "",
            log: newLog
          },
          createdAt: Date.now()
        });
      this.setState({ newLog: "" });
    } catch (error) {
      await firebase
        .database()
        .ref("errors")
        .push({
          where: "postNewLog in LazyUpdate",
          error: error
        });
    }
  };

  render() {
    const { feeds, newLog } = this.state;
    return (
      <Fragment>
        <section className="bg-lazy-update">
          <div className="lazyUpdate container text-center">
            {/* <Form success>
              <Form.Input
                value={newLog}
                onChange={(e, { value }) => {
                  this.setState({ newLog: value });
                }}
                label="Skip recording? No problem!"
                placeholder="Add a lazy text log..."
              />
              <Button
                className="float-right btn btn-lazy-post"
                onClick={this.postNewLog}
              >
                Post
              </Button>
            </Form> */}
            <button
              type="button"
              className="btn btn-outline-success btn-lg mr-3"
            >
              New Feature
            </button>
            <button type="button" className="btn btn-outline-danger btn-lg">
              Report Bug
            </button>
            <Feed>{this.renderFeeds(feeds)}</Feed>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state && state.user
  };
};

const mapDispatchToProps = dispatch => ({
  setUserValue: state => {
    return dispatch(setUserValue(state));
  }
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LazyUpdate);
