import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import IndieTuberBanner from "./IndieTuberBanner";
import IndieTuberList from "./IndieTuberList";
import AddYoutubeChannelModal from "./AddYoutubeChannelModal";
import "./IndieTuber.css";

class IndieTuber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tubers: [],
    };
  }

  componentDidMount = async () => {
    await firebase
      .database()
      .ref("indietubers")
      .orderByChild("createdAt")
      .once("value", (snapshot) => {
        var tubersArray = [];
        snapshot.forEach((tuber) => {
          tubersArray.push(tuber.val());
        });
        this.setState({ isLoading: false, tubers: tubersArray.reverse() });
      });
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const { tubers, isLoading } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>{"IndieTuber on IndieLog"}</title>
          <meta
            name="description"
            content={"Find the IndieHacker who is also a YouTuber"}
          />
        </Helmet>
        <AddYoutubeChannelModal />
        <section className="bg-home">
          <IndieTuberBanner />
          <Grid className="mx-0">
            <Grid.Column width={16}>
              <div ref={this.handleContextRef}>
                <IndieTuberList tubers={tubers} isLoading={isLoading} />
              </div>
            </Grid.Column>
          </Grid>
        </section>
      </Fragment>
    );
  }
}

export default IndieTuber;
