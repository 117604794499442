/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { withRouter } from "react-router";
import { Modal, Container, Row, Col, Image } from "react-bootstrap";
import Linkify from "linkifyjs/react";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { compose } from "redux";
import firebase from "firebase/app";
import "firebase/database";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import { setUserValue } from "../../redux/action/userAction";
import indiehackersIcon from "../../assets/images/indiehackers.svg";
import NoNewVideoAccessModal from "../../components/shared/NoNewVideoAccessModal";

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  return Array.from({ length: count }, (_, i) => i);
}

const today = new Date();

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFollowers: false,
      showFollowing: false,
      uploadHistory: getRange(365).map((index) => {
        let tmpDate = shiftDate(today, -index);
        return {
          date: format(tmpDate, "PP"),
          count: 0,
        };
      }),
    };
  }

  componentDidMount = () => {
    const { uploadHistory } = this.state;
    var tmpUploadHistory = uploadHistory;

    // update uploadHistory array
    // eslint-disable-next-line array-callback-return
    this.props.videos.map((video) => {
      const daysFromNow =
        Math.floor(
          (Date.now() - today.getTimezoneOffset() * 60000) / 86400000
        ) -
        Math.floor(
          (video.createdAt - today.getTimezoneOffset() * 60000) / 86400000
        );
      if (daysFromNow <= 365) {
        let tmpDate = shiftDate(today, -daysFromNow);
        tmpUploadHistory[daysFromNow] = {
          date: format(tmpDate, "PP"),
          count: 1,
        };
      }
    });

    this.setState({ uploadHistory: tmpUploadHistory });
  };

  onShowProductsList = () => {
    this.props.onShowProducts();
  };

  onShowAllVideosList = () => {
    this.props.onShowAllVideos();
  };

  onShowFollowers = () => {
    this.setState({ showFollowers: true });
  };

  onShowFollowing = () => {
    this.setState({ showFollowing: true });
  };

  handleModalClose = () => {
    this.setState({ showFollowers: false, showFollowing: false });
  };

  onFollow = async () => {
    const { profileUser, user } = this.props;
    const { currentUser } = user;

    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    try {
      await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .child("following")
        .child(profileUser.uid)
        .set({
          user: {
            userName: profileUser.userName || "",
            uid: profileUser.uid,
            avatar: profileUser.avatar,
            displayName: profileUser.displayName,
            email: profileUser.email,
          },
        });

      await firebase
        .database()
        .ref("users")
        .child(profileUser.uid)
        .child("followers")
        .child(currentUser.uid)
        .set({
          user: {
            userName: currentUser.userName || "",
            uid: currentUser.uid,
            avatar: currentUser.avatar,
            displayName: currentUser.displayName,
            email: currentUser.email,
          },
        });

      await firebase
        .database()
        .ref("activities")
        .child(currentUser.uid)
        .push({
          type: "New Following",
          content: {
            user: profileUser,
          },
          createdAt: Date.now(),
        });
    } catch (err) {
      console.log("error", err);
    }
  };

  onUnfollow = async () => {
    const { profileUser, user } = this.props;
    const { currentUser } = user;

    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }
    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("following")
      .child(profileUser.uid)
      .remove();

    await firebase
      .database()
      .ref("users")
      .child(profileUser.uid)
      .child("followers")
      .child(currentUser.uid)
      .remove();

    await firebase
      .database()
      .ref("activities")
      .child(currentUser.uid)
      .push({
        type: "New Unfollow",
        content: {
          user: profileUser,
        },
        createdAt: Date.now(),
      });
  };

  sendDirectMessage = async () => {
    console.log("hey");
  };

  onShowAddNewVideoModal = () => {
    const { currentUser } = this.props.user;
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({ showAddNewVideoModal: true });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  onShowAddNewAMAModal = () => {
    const { currentUser } = this.props.user;
    if (currentUser.AmaAccess !== true) {
      this.props.setUserValue({ showAddNewVideoModal: true, isAMA: true });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  onShowAddNewProductModal = () => {
    this.props.onShowProducts();
    this.props.setUserValue({ showAddNewProductModal: true });
  };

  render() {
    const { profileUser, user, showVideos, showProducts } = this.props;
    const { currentUser } = user;
    const { showFollowers, showFollowing, uploadHistory } = this.state;

    if (profileUser === null) {
      return null;
    }

    return (
      <section className="jumbotron profileCta">
        <NoNewVideoAccessModal />
        <Modal show={showFollowers} onHide={this.handleModalClose} size="sm">
          <Modal.Header closeButton>
            <Modal.Title>Followers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {profileUser.followers &&
                Object.keys(profileUser.followers).length > 0 &&
                Object.values(profileUser.followers).map((follower) => {
                  return (
                    <a
                      href={
                        follower.user.userName
                          ? `/user/${follower.user.userName}`
                          : `/user/${follower.user.uid}`
                      }
                      target="_blank"
                      key={follower.user.uid}
                    >
                      <Row
                        className="show-grid mb-2"
                        style={{ cursor: "pointer" }}
                      >
                        <Col lg={1} md={1} sm={1} xs={1}></Col>
                        <Col lg={3} md={3} sm={3} xs={3}>
                          <Image
                            src={follower.user.avatar}
                            roundedCircle
                            fluid={false}
                          />
                        </Col>
                        <Col
                          lg={7}
                          md={7}
                          sm={7}
                          xs={7}
                          className="justify-content-center align-self-center pl-0"
                        >
                          {follower.user.displayName}
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}></Col>
                      </Row>
                    </a>
                  );
                })}
            </Container>
          </Modal.Body>
        </Modal>
        <Modal show={showFollowing} onHide={this.handleModalClose} size="sm">
          <Modal.Header closeButton>
            <Modal.Title>Following</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {profileUser.following &&
                Object.keys(profileUser.following).length > 0 &&
                Object.values(profileUser.following).map((following) => {
                  return (
                    <a
                      href={
                        following.user.userName
                          ? `/user/${following.user.userName}`
                          : `/user/${following.user.uid}`
                      }
                      target="_blank"
                      key={following.user.uid}
                    >
                      <Row
                        className="show-grid mb-2"
                        style={{ cursor: "pointer" }}
                      >
                        <Col lg={1} md={1} sm={1} xs={1}></Col>
                        <Col lg={3} md={3} sm={3} xs={3}>
                          <Image
                            src={following.user.avatar}
                            roundedCircle
                            fluid={false}
                          />
                        </Col>
                        <Col
                          lg={7}
                          md={7}
                          sm={7}
                          xs={7}
                          className="justify-content-center align-self-center pl-0"
                        >
                          {following.user.displayName}
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}></Col>
                      </Row>
                    </a>
                  );
                })}
            </Container>
          </Modal.Body>
        </Modal>

        <Container>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <Row className="mt-4">
                <Col
                  md="5"
                  lg="2"
                  sm="6"
                  style={{ paddingRight: 0, paddingLeft: 0 }}
                >
                  <div className="profileThumb text-center">
                    <img
                      src={profileUser.avatar}
                      className="avatar img-circle img-thumbnail mt-0"
                      alt="avatar"
                    />
                  </div>
                </Col>
                <Col md="7" lg="10" sm="6">
                  <div
                    className="row detailsText text-left"
                    style={{ paddingLeft: "15px" }}
                  >
                    <h3 className="mr-4">{profileUser.displayName}</h3>

                    {/* <div
                      className="direct-message mr-2"
                      onClick={this.sendDirectMessage}
                    >
                      <i
                        className="far fa-comment-dots fa-lg"
                        style={{
                          textAlign: "center",
                          margin: "auto",
                          color: "#00acee",
                        }}
                      ></i>
                    </div> */}

                    {(!currentUser ||
                      (currentUser && profileUser.uid !== currentUser.uid)) && (
                      <div className="btnSubs">
                        {currentUser &&
                        currentUser.following &&
                        currentUser.following[profileUser.uid] ? (
                          <div
                            onClick={this.onUnfollow}
                            className="btn btn-danger px-4 py-2"
                          >
                            Followed
                          </div>
                        ) : (
                          <div
                            onClick={this.onFollow}
                            className="btn btn-danger px-4 py-2"
                          >
                            Follow
                          </div>
                        )}
                      </div>
                    )}

                    {currentUser && profileUser.uid === currentUser.uid && (
                      <div className="btnSubs row">
                        <div
                          onClick={this.onShowAddNewVideoModal}
                          className="btn btn-danger user-btn ml-3 mr-3"
                        >
                          + Video
                        </div>
                        {currentUser.AmaAccess ? (
                          <div
                            onClick={this.onShowAddNewAMAModal}
                            className="btn btn-danger user-btn"
                          >
                            Start AMA
                          </div>
                        ) : (
                          <div
                            onClick={this.onShowAddNewProductModal}
                            className="btn btn-danger user-btn"
                          >
                            + Product
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="pt-2 user-bio">
                    <Linkify>{profileUser.bio}</Linkify>
                  </div>

                  <div className="pt-3">
                    <div className="row" style={{ paddingLeft: "15px" }}>
                      {profileUser.location && (
                        <span
                          className={
                            profileUser.socialLinks
                              ? "mr-5 user-location"
                              : "user-location"
                          }
                        >
                          <i className="fa fa-map-marker-alt fa-lg"></i>{" "}
                          {profileUser.location}
                        </span>
                      )}
                      {profileUser.socialLinks &&
                        profileUser.socialLinks.facebook && (
                          <a
                            href={
                              profileUser.socialLinks.facebook.split("/")
                                .length > 1
                                ? profileUser.socialLinks.facebook
                                : "https://facebook.com/" +
                                  profileUser.socialLinks.facebook
                            }
                            title="Facebook"
                            target="_blank"
                            className="mr-2"
                          >
                            <i className="fab fa-facebook-square fa-lg"></i>{" "}
                          </a>
                        )}
                      {profileUser.socialLinks &&
                        profileUser.socialLinks.twitter && (
                          <a
                            href={
                              profileUser.socialLinks.twitter.split("/")
                                .length > 1
                                ? profileUser.socialLinks.twitter
                                : "https://twitter.com/" +
                                  profileUser.socialLinks.twitter
                            }
                            title="Twitter"
                            target="_blank"
                            className="mr-2"
                          >
                            <i
                              className="fab fa-twitter fa-lg"
                              aria-hidden="true"
                            ></i>{" "}
                          </a>
                        )}
                      {profileUser.socialLinks &&
                        profileUser.socialLinks.indiehackers && (
                          <a
                            href={
                              profileUser.socialLinks.indiehackers.split("/")
                                .length > 1
                                ? profileUser.socialLinks.indiehackers
                                : "https://indiehackers.com/" +
                                  profileUser.socialLinks.indiehackers
                            }
                            title="IndieHackers"
                            target="_blank"
                            className="mr-2"
                          >
                            <img
                              src={indiehackersIcon}
                              className="indiehackers-icon mb-1"
                              alt="IndieHackers"
                              aria-hidden="true"
                            ></img>{" "}
                          </a>
                        )}
                      {profileUser.socialLinks &&
                        profileUser.socialLinks.instagram && (
                          <a
                            href={
                              profileUser.socialLinks.instagram.split("/")
                                .length > 1
                                ? profileUser.socialLinks.instagram
                                : "https://instagram.com/" +
                                  profileUser.socialLinks.instagram
                            }
                            title="Instagram"
                            target="_blank"
                            className="mr-2"
                          >
                            <i
                              className="fab fa-instagram fa-lg"
                              aria-hidden="true"
                            ></i>{" "}
                          </a>
                        )}
                      {profileUser.socialLinks &&
                        profileUser.socialLinks.personalWeb && (
                          <a
                            href={profileUser.socialLinks.personalWeb}
                            title="Personal website"
                            target="_blank"
                          >
                            <i
                              className="far fa-window-maximize fa-lg"
                              aria-hidden="true"
                            ></i>
                          </a>
                        )}
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  {((profileUser.videos &&
                    Object.keys(profileUser.videos).length !== 0) ||
                    (profileUser.followers &&
                      Object.keys(profileUser.followers).length !== 0) ||
                    (profileUser.following &&
                      Object.keys(profileUser.following).length !== 0)) && (
                    <div className="row mt-4 mb-4">
                      <div
                        className="col-3 text-center cursor-pointer"
                        onClick={this.onShowAllVideosList}
                        style={{
                          fontWeight: showVideos ? "800" : 400,
                        }}
                      >
                        <h4>
                          {(profileUser.videos &&
                            Object.keys(profileUser.videos).length) ||
                            0}
                        </h4>
                        <p className="stats-title">
                          {!profileUser.videos ||
                          (profileUser.videos &&
                            (Object.keys(profileUser.videos).length === 0 ||
                              Object.keys(profileUser.videos).length === 1))
                            ? "Video"
                            : "Videos"}
                        </p>
                      </div>
                      <div className="col-3 text-center">
                        <div
                          className="cursor-pointer"
                          onClick={this.onShowProductsList}
                          style={{
                            fontWeight: showProducts ? "800" : 400,
                          }}
                        >
                          <h4>
                            {(profileUser.products &&
                              Object.keys(profileUser.products).length) ||
                              0}
                          </h4>
                          <p className="stats-title">
                            {!profileUser.products ||
                            (profileUser.products &&
                              (Object.keys(profileUser.products).length === 0 ||
                                Object.keys(profileUser.products).length === 1))
                              ? "Product"
                              : "Products"}
                          </p>
                        </div>
                      </div>
                      <div className="col-3 text-center">
                        {profileUser.followers &&
                        Object.keys(profileUser.followers).length > 0 ? (
                          <div
                            className="cursor-pointer"
                            onClick={this.onShowFollowers}
                          >
                            <h4>
                              {(profileUser.followers &&
                                Object.keys(profileUser.followers).length) ||
                                0}
                            </h4>
                            <p className="stats-title">
                              {!profileUser.followers ||
                              (profileUser.followers &&
                                (Object.keys(profileUser.followers).length ===
                                  0 ||
                                  Object.keys(profileUser.followers).length ===
                                    1))
                                ? "Follower"
                                : "Followers"}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <h4>
                              {(profileUser.followers &&
                                Object.keys(profileUser.followers).length) ||
                                0}
                            </h4>
                            <p className="stats-title">
                              {!profileUser.followers ||
                              (profileUser.followers &&
                                (Object.keys(profileUser.followers).length ===
                                  0 ||
                                  Object.keys(profileUser.followers).length ===
                                    1))
                                ? "Follower"
                                : "Followers"}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="col-3 text-center">
                        {profileUser.following &&
                        Object.keys(profileUser.following).length > 0 ? (
                          <div
                            className="cursor-pointer"
                            onClick={this.onShowFollowing}
                          >
                            <h4>
                              {(profileUser.following &&
                                Object.keys(profileUser.following).length) ||
                                0}
                            </h4>
                            <p className="stats-title">Following</p>
                          </div>
                        ) : (
                          <div>
                            <h4>
                              {(profileUser.following &&
                                Object.keys(profileUser.following).length) ||
                                0}
                            </h4>
                            <p className="stats-title">Following</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Col>
                <Col md="12">
                  <CalendarHeatmap
                    startDate={shiftDate(today, -365)}
                    endDate={today}
                    values={uploadHistory}
                    classForValue={(value) => {
                      if (!value) {
                        return "color-empty";
                      }
                      return `color-github-${value.count}`;
                    }}
                    tooltipDataAttrs={(value) => {
                      return {
                        "data-tip": `${value.date}`,
                        // "data-tip": `${value.date}`
                      };
                    }}
                    showWeekdayLabels={false}
                  />
                  <ReactTooltip />
                </Col>
              </Row>
            </Col>
            <Col md="3"></Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserInfo);
