import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { getVideosForAMA } from "../../redux/action/videoAction";
import { setUserValue } from "../../redux/action/userAction";
import AmaBanner from "./AmaBanner";
import AmaCards from "./AmaCards";
import "./AMA.css";

class AMA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadedVideos: [],
      contextRef: {},
      hasMore: false,
    };
  }

  componentDidMount = async () => {
    let videos = await this.props.getVideosForAMA("");
    if (videos && videos.length === 18) {
      this.setState({ hasMore: true });
    } else {
      this.setState({ hasMore: false });
    }
    this.setState({ isLoading: false });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.videos !== prevProps.videos) {
      this.setState({
        loadedVideos: [
          ...this.state.loadedVideos,
          ...this.props.videos.arrayOfVideos,
        ],
      });
    }
  };

  getNextVideos = async () => {
    const { currentUser } = this.props.user;
    const { referenceToOldestKey } = this.props.videos;

    //ask user to login/signup to trigger load more
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    let videos = await this.props.getVideosForAMA(referenceToOldestKey);
    if (videos && videos.length < 18) {
      this.setState({ hasMore: false });
    }
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const { loading } = this.props;
    const { loadedVideos, hasMore, isLoading } = this.state;

    return (
      <Fragment>
        <section className="bg-home">
          <AmaBanner />
          <Grid className="mx-0">
            <Grid.Column width={16}>
              <div ref={this.handleContextRef}>
                <AmaCards
                  loading={loading}
                  isLoading={isLoading}
                  getNextVideos={this.getNextVideos}
                  videos={loadedVideos}
                  hasMore={hasMore}
                />
              </div>
            </Grid.Column>
            {!isLoading && !loading && hasMore && (
              <Grid.Column width={16}>
                <div className="text-center" style={{ marginTop: 20 }}>
                  <button
                    onClick={this.getNextVideos}
                    className="btn btn-danger my-1"
                  >
                    Load More
                  </button>
                </div>
              </Grid.Column>
            )}
          </Grid>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state && state.user,
  videos: state && state.videos,
  loading: state && state.async.loading,
});

const mapDispatchToProps = {
  getVideosForAMA,
  setUserValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(AMA);
