import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import SingleVideoCard from "../../components/shared/SingleVideoCard";

class UserVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { profileUser, videos } = this.props;

    if (profileUser === null) {
      return (
        <div className="text-center" style={{ marginTop: 100 }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
        </div>
      );
    }

    return (
      <div className="row container-fluid">
        <div className="col-12">
          <div className="row mt-4">
            {videos &&
              Object.keys(videos).length > 0 &&
              Object.values(videos).map(
                (video) =>
                  video.status === "video.asset.ready" && (
                    <SingleVideoCard
                      key={video.playbackIds[0].id}
                      video={video}
                      profileView={false}
                    />
                  )
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserVideos;
