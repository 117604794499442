import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../../redux/action/userAction";

class DeleteProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isSubmitting: false,
    };
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      isSubmitting: false,
    });
    this.props.setUserValue({ showDeleteProductModal: false });
  };

  onDeleteProduct = async (event) => {
    this.setState({ isSubmitting: true });
    const { product, user } = this.props;
    const { currentUser } = user;

    await firebase //push to /user-posts/{userId}/{requestId}
      .database()
      .ref("products")
      .child(product.id)
      .remove();

    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("products")
      .child(product.id)
      .remove();

    this.setState({ isSubmitting: false });
    this.props.setUserValue({
      showDeleteProductModal: false,
    });

    toast.success("Product deleted successfully", {
      autoClose: 4000,
    });

    this.props.history.push("/dashboard");
  };

  render() {
    const { showDeleteProductModal } = this.props.user;
    const { isSubmitting } = this.state;

    return (
      <Modal
        show={showDeleteProductModal}
        onHide={this.handleModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure to delete this product?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6 text-center">
              <button
                className=" btn btn-danger my-1"
                onClick={() => {
                  this.onDeleteProduct();
                }}
              >
                {isSubmitting ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Delete it"
                )}
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn btn-secondary my-1"
                onClick={() => this.handleModalClose()}
              >
                Go back
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteProductModal);
