import React, { Fragment } from "react";
import { withRouter } from "react-router";
import ReactMarkdown from "react-markdown";
import Linkify from "linkifyjs/react";
import { Modal, Alert } from "react-bootstrap";
import Confetti from "react-confetti";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { connect } from "react-redux";
import { compose } from "redux";
import { format, parseISO } from "date-fns";
import Comments from "./Comments/Comments";
import { objectToArray, createCommentsTree } from "./Comments/helpers";
import { setUserValue } from "../../redux/action/userAction";
import "./MeetupDetails.css";

var meetupListener;
var commentsListener;
class MeetupDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      meetup: null,
      dropConfetti: false,
      showConfirmQuit: false,
    };
  }

  componentDidMount = async () => {
    var meetupId = this.props.match.params.meetupId;
    meetupListener = await firebase
      .database()
      .ref("meetups")
      .child(meetupId)
      .on("value", (snapshot) => {
        if (!snapshot || !snapshot.val()) {
          this.props.history.push("/error");
        }
        this.setState({ meetup: snapshot.val() });
      });

    commentsListener = firebase
      .database()
      .ref("meetup-comments")
      .child(meetupId)
      .on("value", (snapshot) => {
        if (snapshot) {
          this.setState({ comments: objectToArray(snapshot.val()) });
        }
      });
  };

  componentWillUnmount = () => {
    var meetupId = this.props.match.params.meetupId;
    firebase
      .database()
      .ref("meetups")
      .child(meetupId)
      .off("value", meetupListener);

    firebase
      .database()
      .ref("meetup-comments")
      .child(meetupId)
      .off("value", commentsListener);
  };

  requestToJoin = async () => {
    const { currentUser } = this.props.user;
    const { meetup } = this.state;

    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    var dateISO = parseISO(meetup.meetingTime);
    var readableTime = format(dateISO, "MMMM d, yyyy h:mm aa");
    var icsTime = format(dateISO, "yyyy-M-d-H-m");

    await firebase
      .database()
      .ref("meetups")
      .child(meetup.id)
      .child("attendees")
      .child(currentUser.uid)
      .set({
        isHost: currentUser.uid === meetup.host.uid,
        email: currentUser.email || "",
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        id:
          currentUser.userName && currentUser.userName !== ""
            ? currentUser.userName
            : currentUser.uid,
        meetingLocalTime: readableTime,
        icsTime: icsTime,
      });

    this.setState({ dropConfetti: true });

    toast("You just signed up our meetup 🤗", {
      autoClose: 5000,
    });
  };

  requestToQuit = async () => {
    const { currentUser } = this.props.user;
    const { meetup } = this.state;

    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    await firebase
      .database()
      .ref("meetups")
      .child(meetup.id)
      .child("attendees")
      .child(currentUser.uid)
      .remove();

    this.handleModalClose();
    this.setState({ dropConfetti: false });
  };

  onShowQuitModal = async () => {
    this.setState({ showConfirmQuit: true });
  };

  handleModalClose = () => {
    this.setState({ showConfirmQuit: false });
  };

  render() {
    const { meetup, dropConfetti, showConfirmQuit } = this.state;
    const { currentUser } = this.props.user;
    if (!meetup) {
      return null;
    }
    const {
      id,
      meetingRoom,
      title,
      description,
      backgroundImg,
      meetingTime,
      duration,
      slots,
      attendees,
    } = meetup;

    var dateISO = parseISO(meetingTime);
    var readableTime = format(dateISO, "MMMM d, yyyy h:mm aa");

    const { comments } = this.state;
    const commentsTree =
      comments && Object.keys(comments).length > 0
        ? createCommentsTree(comments)
        : [];

    return (
      <Fragment>
        {dropConfetti && (
          <Confetti style={{ position: "fixed" }} recycle={false} />
        )}
        <Modal show={showConfirmQuit} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to quit?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6 text-left">
                <button
                  className=" btn btn-danger my-1"
                  onClick={() => {
                    this.requestToQuit();
                  }}
                >
                  Quit now
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  className="btn btn-secondary my-1"
                  onClick={() => this.handleModalClose()}
                >
                  Go back
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <section className="bg-dashboard">
          {attendees && currentUser && attendees[currentUser.uid] && (
            <Alert variant={"success"} style={{ textAlign: "center" }}>
              A meetup invitation was sent to {currentUser.email}
            </Alert>
          )}

          <div className="bg-white overflow-hidden">
            <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>

              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-9 lg:col-end-13">
                  <div className=" mb-8 ">
                    <div className="bg-white rsvp-card">
                      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6  lg:px-8">
                        <h2 className="text-xl leading-9 font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
                          Wanna join?
                        </h2>
                        <div className="rounded-md bg-green-50 p-3 mt-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-5 w-5 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <div className="ml-3">
                              <h3 className="text-lg leading-5 font-medium text-green-800">
                                {slots -
                                  ((attendees &&
                                    Object.values(attendees).length) ||
                                    0)}{" "}
                                slots remaining
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 flex justify-center">
                          <div className="w-full text-center flex rounded-md shadow">
                            {attendees &&
                            currentUser &&
                            attendees[currentUser.uid] ? (
                              <button
                                onClick={() => this.onShowQuitModal()}
                                className="w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-400 hover:bg-red-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                              >
                                🥳 You joined
                              </button>
                            ) : attendees &&
                              Object.values(attendees).length === slots ? (
                              <button
                                // onClick={() => this.requestToJoin()}
                                className="w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-400 hover:bg-red-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                                disabled
                              >
                                No available slot 🤣
                              </button>
                            ) : (
                              <button
                                onClick={() => this.requestToJoin()}
                                className="w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                              >
                                Request to join
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" mb-8 ">
                    <div className="bg-white rsvp-card">
                      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6  lg:px-8">
                        <h2 className="text-xl leading-9 font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
                          Attendees (
                          {(attendees && Object.values(attendees).length) || 0})
                        </h2>
                        <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2 mt-4">
                          {attendees &&
                            Object.values(attendees).length > 0 &&
                            Object.values(attendees).map((attendee, key) => {
                              return (
                                <li
                                  className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow"
                                  key={key}
                                >
                                  <a
                                    className="flex-1 flex flex-col p-4"
                                    href={"/user/" + attendee.id}
                                    target="_blank"
                                  >
                                    <img
                                      className="w-24 h-24 flex-shrink-0 mx-auto bg-black rounded-full"
                                      src={attendee.avatar}
                                      alt=""
                                    />
                                    <h3 className="mt-6 text-gray-900 text-lg leading-5 font-medium">
                                      {attendee.name}
                                    </h3>
                                    {attendee.isHost && (
                                      <span className="px-1 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">
                                        Host
                                      </span>
                                    )}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-8">
                  <div className="mx-auto text-base max-w-prose lg:max-w-none mb-4">
                    <p className="text-base leading-6 text-red-700 font-semibold tracking-wide uppercase">
                      {readableTime} ({duration} minutes)
                    </p>
                    <h1 className="mt-2 mb-8 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                      {title}
                    </h1>
                  </div>
                  <figure>
                    <img
                      className="w-full rounded-lg"
                      src={backgroundImg}
                      alt=""
                      width="1310"
                      height="873"
                    />
                  </figure>
                  <div className="text-lg max-w-prose mx-auto lg:max-w-none mt-4">
                    <Linkify style={{ whiteSpace: "pre-line" }}>
                      <ReactMarkdown
                        source={description}
                        className="meetup-description-markdown"
                      />
                    </Linkify>
                    <br />
                    <p>
                      🙏 If by any chance you cannot make it, please quit the
                      event so other people can take your spot.
                    </p>
                  </div>
                  {attendees && currentUser && attendees[currentUser.uid] && (
                    <>
                      <hr className="my-4" />
                      <div className="text-lg max-w-prose mx-auto lg:max-w-none bg-blue-100">
                        <h2>How to join? 👈</h2>
                        <ol style={{ paddingLeft: "1.5em" }}>
                          <li>
                            Go to{" "}
                            <a href="https://meet.indielog.com" target="_blank">
                              meet.indielog.com
                            </a>
                          </li>
                          <li>Login your IndieLog account</li>
                          <li>
                            Input the meetup id <strong>{meetingRoom}</strong>
                          </li>
                          <li>Done</li>
                        </ol>
                      </div>
                    </>
                  )}
                  <hr className="my-4" />
                  <h3>Comments</h3>
                  <Comments comments={commentsTree} meetup={meetup} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MeetupDetails);
