import React from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/database";
import { setUserValue } from "../../redux/action/userAction";
import NoNewVideoAccessModal from "../../components/shared/NoNewVideoAccessModal";
import { Modal, Button } from "react-bootstrap";
import swag_img from "../../assets/images/swag.png";

class AmaBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSwagModal: false, weekMeta: null };
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.weekId !== prevProps.weekId) {
      const weekRef = await firebase
        .database()
        .ref("weekly-topics")
        .child(this.props.weekId)
        .once("value");

      const meta = weekRef.val().meta;
      this.setState({ weekMeta: meta });
    }
  };

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onShowAddNewWeeklyVideoModal = () => {
    this.setState({ showSwagModal: false });
    const { currentUser } = this.props.user;
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({
        showAddNewVideoModal: true,
        isWeeklyVideo: true,
        weeklyTopicId: this.props.weekId,
      });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  onShowSwagModal = () => {
    this.setState({ showSwagModal: true });
  };

  handleModalClose = () => {
    this.setState({ showSwagModal: false });
  };

  render() {
    const { currentUser } = this.props.user;
    const { weekId } = this.props;
    const { weekMeta } = this.state;

    return (
      <section className="jumbotron text-center cta">
        <NoNewVideoAccessModal />
        <Modal
          show={this.state.showSwagModal}
          onHide={this.handleModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Wanna get it?{" "}
              <span role="img" aria-label="wow">
                🤩
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={swag_img} alt="cool swag" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="btn btn-danger my-1"
              onClick={this.onShowAddNewWeeklyVideoModal}
            >
              Record Yours
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="introsection-div">
          <div className="container pt-5 pb-5">
            <h1 className="home-banner-title">Ask Me Anything</h1>
            {!weekId && (
              <p className="lead text-muted">
                We are a community that connects you with successful indie
                hackers. Ask them questions now!
              </p>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AmaBanner);
