import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/database";
import { toast } from "react-toastify";
import getBlobDuration from "get-blob-duration";
import { format } from "date-fns";
import RecordVideo from "./RecordVideo";
import RecordPreview from "./RecordPreview";
import AddDetails from "./AddDetails";
import UploadResult from "./UploadResult";
const UpChunk = require("@mux/upchunk");

const today = new Date();

export class MainNewVideoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      passthroughId: "",
      modalHeader: "",
      title: "",
      description: "",
      uploadToYT: false,
      videoBlob: "",
      muxUpload: null,
      isUploading: false,
      progress: 0,
      uploadSucceed: true,
      isFileUpload: false,
      product: "",
      topic: "",
      video: null,
      date: new Date(),
    };
  }

  // Process to Next Step
  nextStep = (isFileUpload) => {
    const { step } = this.state;
    this.setState({ step: step + 1, isFileUpload: isFileUpload });
  };
  // Back to Previous Step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };
  // Try again, go back to step 1
  tryAgain = () => {
    this.setState({ step: 1 });
  };
  modalHeader = (title) => {
    this.setState({
      modalHeader: title,
    });
  };
  // Handle input fields changes
  handleChange = (input) => (e) => {
    const value =
      e.target.id === "uploadToYT" ? e.target.checked : e.target.value;
    this.setState({ [input]: value });
  };
  setVideoBlob = async (videoBlob) => {
    const duration = await getBlobDuration(videoBlob);
    if (duration >= 131) {
      toast.error(
        "Oops, we already gave you 10s extra, but your video is too long 😭",
        {
          autoClose: 4000,
        }
      );
      this.tryAgain();
      return;
    }
    this.setState({ videoBlob: videoBlob });
    var upload = firebase.functions().httpsCallable("muxCreateUploadURL");
    upload({ userId: this.props.currentUser.uid }).then((result) => {
      this.setState({ muxUpload: result.data });
    });
  };

  selectProduct = (product) => {
    this.setState({
      product: product,
    });
  };

  selectTopic = (topic) => {
    this.setState({
      topic: topic,
    });
  };

  selectDate = (date) => {
    this.setState({
      date: format(date, "PP"),
    });
  };

  onUploadVideoReply = () => {
    const { muxUpload, step } = this.state;
    const { videoId, commentParentId, currentUser } = this.props;

    this.setState({ isUploading: true });

    const passthrough = muxUpload.new_asset_settings.passthrough;

    var file = new File([this.state.videoBlob], "videoReply");
    const upload = UpChunk.createUpload({
      file,
      endpoint: this.state.muxUpload.url,
    });

    upload.on("success", async () => {
      let newComment = currentUser.userName
        ? {
            parentId: commentParentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            userName: currentUser.userName,
            email: currentUser.email || "",
            comment: "Your video reply will be ready soon...",
            date: Date.now(),
            type: "VIDEO",
          }
        : {
            parentId: commentParentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser.email || "",
            comment: "Your video reply will be ready soon...",
            date: Date.now(),
            type: "VIDEO",
          };

      const commentRef = await firebase
        .database()
        .ref("comments")
        .child(videoId)
        .push(newComment);
      const commentId = commentRef.key;

      await firebase.database().ref("mux-videos").child(passthrough).update({
        type: "COMMENT",
        videoId: videoId,
        commentId: commentId,
        commentParentId: commentParentId,
      });

      this.setState({
        isUploading: false,
        step: step + 2,
        uploadSucceed: true,
      });
    });

    upload.on("error", (err) => {
      this.setState({
        isUploading: false,
        step: step + 2,
        uploadSucceed: false,
      });
    });
  };

  onUploadProductPitchVideo = () => {
    const { muxUpload, step } = this.state;
    const { product } = this.props;

    this.setState({ isUploading: true });

    const passthrough = muxUpload.new_asset_settings.passthrough;

    var file = new File([this.state.videoBlob], "productPitch");
    const upload = UpChunk.createUpload({
      file,
      endpoint: this.state.muxUpload.url,
    });

    upload.on("success", async () => {
      await firebase.database().ref("mux-videos").child(passthrough).update({
        type: "PRODUCT-PITCH",
        product: product,
      });

      this.setState({
        isUploading: false,
        step: step + 2,
        uploadSucceed: true,
      });
    });

    upload.on("progress", (progress) => {
      this.setState({ progress: Math.ceil(progress.detail) });
    });

    upload.on("error", (err) => {
      this.setState({
        isUploading: false,
        step: step + 2,
        uploadSucceed: false,
      });
    });
  };

  onUploadVideo = () => {
    const {
      muxUpload,
      title,
      description,
      topic,
      uploadToYT,
      step,
      passthroughId,
      product,
    } = this.state;
    const { isWeeklyVideo, weeklyTopicId, isAMA, currentUser } = this.props;

    if (!isAMA && title === "") {
      toast.error("Error: title is required.", {
        autoClose: 4000,
      });
      return;
    }

    this.setState({ isUploading: true });

    const passthrough = muxUpload.new_asset_settings.passthrough;

    this.setState({ passthroughId: passthrough });

    var file = new File([this.state.videoBlob], "newVideo");
    const upload = UpChunk.createUpload({
      file,
      endpoint: this.state.muxUpload.url,
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    });

    //get first day of the week
    var firstDayOfWeek = null;
    if (isWeeklyVideo) {
      // var curr = new Date(); // get current date
      // var first = curr.getDate() - curr.getDay() + 1; //first day of the week
      // firstDayOfWeek = format(new Date(curr.setDate(first)), "PP");
      firstDayOfWeek =
        weeklyTopicId === "" || weeklyTopicId === undefined
          ? "have-you-tried-talking-to-your-customers"
          : weeklyTopicId;
    }

    upload.on("success", async () => {
      await firebase
        .database()
        .ref("mux-videos")
        .child(passthrough)
        .update({
          type: "NEW_VIDEO",
          title: title,
          description: description,
          topic: topic || "",
          // uploadToYT: uploadToYT,
          productId: product,
          isWeeklyVideo: isWeeklyVideo ? isWeeklyVideo : false,
          firstDayOfWeek: firstDayOfWeek ? firstDayOfWeek : "",
          // isFileUpload: isFileUpload
          shareOnYoutube: false,
          timezoneOffset: today.getTimezoneOffset(),
        });

      this.setState({
        // video: videoRef.val(),
        isUploading: false,
        step: step + 1,
        uploadSucceed: true,
      });
    });

    upload.on("progress", (progress) => {
      this.setState({ progress: Math.ceil(progress.detail) });
    });

    upload.on("error", (err) => {
      this.setState({
        isUploading: false,
        step: step + 1,
        uploadSucceed: false,
      });
    });
  };

  onStartAMA = () => {
    const { muxUpload, date, description, step } = this.state;

    this.setState({ isUploading: true });

    const passthrough = muxUpload.new_asset_settings.passthrough;

    var file = new File([this.state.videoBlob], "newAMA");
    const upload = UpChunk.createUpload({
      file,
      endpoint: this.state.muxUpload.url,
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    });

    upload.on("success", async () => {
      await firebase
        .database()
        .ref("mux-videos")
        .child(passthrough)
        .update({
          type: "NEW_AMA",
          title: "I'm " + this.props.currentUser.displayName + ". AMA!",
          description: description,
          Amadate: date,
        });
      this.setState({
        isUploading: false,
        step: step + 1,
        uploadSucceed: true,
      });
    });

    upload.on("progress", (progress) => {
      this.setState({ progress: Math.ceil(progress.detail) });
    });

    upload.on("error", (err) => {
      this.setState({
        isUploading: false,
        step: step + 1,
        uploadSucceed: false,
      });
    });
  };

  onUploadAmaQuestion = () => {
    const { muxUpload, title, step } = this.state;
    const { videoId, commentParentId, currentUser } = this.props;

    this.setState({ isUploading: true });

    const passthrough = muxUpload.new_asset_settings.passthrough;

    var file = new File([this.state.videoBlob], "AmaQuestion");
    const upload = UpChunk.createUpload({
      file,
      endpoint: this.state.muxUpload.url,
    });

    upload.on("success", async () => {
      let newComment = currentUser.userName
        ? {
            parentId: commentParentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            userName: currentUser.userName,
            email: currentUser.email || "",
            comment: title,
            date: Date.now(),
            type: "AMA-QA",
          }
        : {
            parentId: commentParentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser.email || "",
            comment: title,
            date: Date.now(),
            type: "AMA-QA",
          };

      const commentRef = await firebase
        .database()
        .ref("AMA-QA")
        .child(videoId)
        .push(newComment);
      const commentId = commentRef.key;

      await firebase.database().ref("mux-videos").child(passthrough).update({
        type: "AMA-QA",
        videoId: videoId,
        commentId: commentId,
        commentParentId: commentParentId,
      });

      this.setState({
        isUploading: false,
        step: step + 1,
        uploadSucceed: true,
      });
    });

    upload.on("progress", (progress) => {
      this.setState({ progress: Math.ceil(progress.detail) });
    });

    upload.on("error", (err) => {
      this.setState({
        isUploading: false,
        step: step + 2,
        uploadSucceed: false,
      });
    });
  };

  onUploadAmaAnswer = () => {
    const { muxUpload, step } = this.state;
    const { videoId, commentParentId, currentUser } = this.props;

    this.setState({ isUploading: true });

    const passthrough = muxUpload.new_asset_settings.passthrough;

    var file = new File([this.state.videoBlob], "AmaAnswer");
    const upload = UpChunk.createUpload({
      file,
      endpoint: this.state.muxUpload.url,
    });

    upload.on("success", async () => {
      let newComment = currentUser.userName
        ? {
            parentId: commentParentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            userName: currentUser.userName,
            email: currentUser.email || "",
            comment: "Your answer will be ready soon...",
            date: Date.now(),
            type: "AMA-QA",
          }
        : {
            parentId: commentParentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser.email || "",
            comment: "Your answer will be ready soon...",
            date: Date.now(),
            type: "AMA-QA",
          };

      const commentRef = await firebase
        .database()
        .ref("AMA-QA")
        .child(videoId)
        .push(newComment);
      const commentId = commentRef.key;

      await firebase.database().ref("mux-videos").child(passthrough).update({
        type: "AMA-QA",
        videoId: videoId,
        commentId: commentId,
        commentParentId: commentParentId,
      });

      this.setState({
        isUploading: false,
        step: step + 2,
        uploadSucceed: true,
      });
    });

    upload.on("progress", (progress) => {
      this.setState({ progress: Math.ceil(progress.detail) });
    });

    upload.on("error", (err) => {
      this.setState({
        isUploading: false,
        step: step + 2,
        uploadSucceed: false,
      });
    });
  };

  shareOnYoutube = async () => {
    const { passthroughId } = this.state;

    passthroughId &&
      (await firebase
        .database()
        .ref("mux-videos")
        .child(passthroughId)
        .update({ shareOnYoutube: true }));
  };

  render() {
    const {
      step,
      isUploading,
      uploadSucceed,
      muxUpload,
      isFileUpload,
      progress,
      video,
    } = this.state;
    const { title, description, uploadToYT } = this.state;
    const { type, isAMA, AmaQuestion, AmaAnswer } = this.props;
    const values = { title, description, uploadToYT };

    switch (step) {
      case 1:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
              <RecordVideo
                type={type}
                modalHeader={(title) => this.modalHeader(title)}
                nextStep={this.nextStep}
                isAMA={isAMA}
                AmaQuestion={AmaQuestion}
                AmaAnswer={AmaAnswer}
              />
            </Modal.Body>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
              <RecordPreview
                type={type}
                setVideoBlob={(videoBlob) => this.setVideoBlob(videoBlob)}
                modalHeader={(title) => this.modalHeader(title)}
                nextStep={(isFileUpload) => this.nextStep(isFileUpload)}
                prevStep={this.prevStep}
                onUploadVideoReply={this.onUploadVideoReply}
                onUploadAmaAnswer={this.onUploadAmaAnswer}
                onUploadProductPitchVideo={this.onUploadProductPitchVideo}
                isUploading={isUploading}
                muxUpload={muxUpload}
                isFileUpload={isFileUpload}
                isAMA={isAMA}
                AmaQuestion={AmaQuestion}
                AmaAnswer={AmaAnswer}
              />
            </Modal.Body>
          </Fragment>
        );
      case 3:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
              <AddDetails
                title={this.state.title}
                handleChange={this.handleChange}
                values={values}
                modalHeader={(title) => this.modalHeader(title)}
                videoBlob={this.state.videoBlob}
                onUploadVideo={this.onUploadVideo}
                onUploadAmaQuestion={this.onUploadAmaQuestion}
                onStartAMA={this.onStartAMA}
                isUploading={isUploading}
                muxUpload={muxUpload}
                selectProduct={(product) => this.selectProduct(product)}
                selectTopic={(topic) => this.selectTopic(topic)}
                selectDate={(date) => this.selectDate(date)}
                progress={progress}
                isAMA={isAMA}
                AmaQuestion={AmaQuestion}
                AmaAnswer={AmaAnswer}
              />
            </Modal.Body>
          </Fragment>
        );
      case 4:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <UploadResult
                type={type}
                modalHeader={(title) => this.modalHeader(title)}
                uploadSucceed={uploadSucceed}
                tryAgain={this.tryAgain}
                AmaQuestion={AmaQuestion}
                AmaAnswer={AmaAnswer}
                video={video}
                shareOnYoutube={this.shareOnYoutube}
              />
            </Modal.Body>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <RecordVideo
                modalHeader={(title) => this.modalHeader(title)}
                nextStep={this.nextStep}
              />
            </Modal.Body>
          </Fragment>
        );
    }
  }
}

export default MainNewVideoModal;
