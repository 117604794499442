import React from "react";
import Linkify from "linkifyjs/react";
import { withRouter } from "react-router";
import Confetti from "react-confetti";
import { Modal, Container, Row, Col } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/database";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { compose } from "redux";

class ChallengeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropConfetti: false,
      showConfirmQuit: false,
    };
  }
  onCheckChallenge = () => {
    const { challenge } = this.props;
    let path = "/challenges/" + challenge.url;
    this.props.history.push(path);
  };

  onJoinChallenge = async () => {
    const { challenge, user } = this.props;
    const { currentUser } = user;
    await firebase
      .database()
      .ref("challenges")
      .child(challenge.url)
      .child("participants")
      .child(currentUser.uid)
      .set({
        goal: challenge.goal,
        streak: 0,
        updates: 0,
        avatar: currentUser.avatar,
        createdAt: Date.now(),
        name: currentUser.displayName,
        email: currentUser.email,
        uid: currentUser.uid,
        products: currentUser.products,
      });

    const challengeRef = await firebase
      .database()
      .ref("challenges")
      .child(challenge.url)
      .child("joined");
    challengeRef.transaction(function (count) {
      return (count || 0) + 1;
    });

    this.setState({ dropConfetti: true });

    await firebase
      .database()
      .ref("challenge-participants")
      .child(currentUser.uid)
      .child(challenge.url)
      .set({
        challenge: challenge.url,
        goal: challenge.goal,
        streak: 0,
        updates: 0,
        status: "active",
      });

    toast("You just joined the " + challenge.title + " challenge! 💪", {
      autoClose: 5000,
    });
  };

  onQuitChallenge = async () => {
    const { challenge, user } = this.props;
    const { currentUser } = user;
    await firebase
      .database()
      .ref("challenges")
      .child(challenge.url)
      .child("participants")
      .child(currentUser.uid)
      .remove();

    const challengeRef = await firebase
      .database()
      .ref("challenges")
      .child(challenge.url)
      .child("joined");
    challengeRef.transaction(function (count) {
      return count - 1;
    });

    await firebase
      .database()
      .ref("challenge-participants")
      .child(currentUser.uid)
      .child(challenge.url)
      .remove();

    this.handleModalClose();
  };

  onShowQuitModal = async () => {
    this.setState({ showConfirmQuit: true });
  };

  handleModalClose = () => {
    this.setState({ showConfirmQuit: false });
  };

  render() {
    const { challenge, user } = this.props;
    const { currentUser } = user;
    const { dropConfetti, showConfirmQuit } = this.state;

    return (
      <div className="col-xxxl-2 col-xxl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-3 pr-0">
        {dropConfetti && (
          <Confetti style={{ position: "fixed" }} recycle={false} />
        )}
        <Modal show={showConfirmQuit} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to quit?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6 text-left">
                <button
                  className=" btn btn-danger my-1"
                  onClick={() => {
                    this.onQuitChallenge();
                  }}
                >
                  Quit now
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  className="btn btn-secondary my-1"
                  onClick={() => this.handleModalClose()}
                >
                  Go back
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="card challenge-card mb-4 box-shadow">
          <div className="card-body d-flex flex-column">
            <div className="profileThumb text-center">
              <img
                src={challenge.badge}
                className="challenge-badge mt-0 mx-auto"
                alt="avatar"
                onClick={() => this.onCheckChallenge()}
              />
            </div>
            <div
              className="text-center"
              onClick={() => this.onCheckChallenge()}
              style={{ cursor: "pointer" }}
            >
              <h3 className="challenge-title">{challenge.title}</h3>
            </div>

            <div className="row mt-4 mb-4">
              <div className="col-6 text-center">
                <h4 className="stat-number">{challenge.joined || 0}</h4>
                <p style={{ fontSize: "16px" }}>Participants</p>
              </div>
              <div className="col-6 text-center">
                <div>
                  <h4 className="stat-number">{challenge.done || 0}</h4>
                  <p style={{ fontSize: "16px" }}>Completed</p>
                </div>
              </div>
            </div>

            <div className="text-center challenge-description">
              <Linkify style={{ whiteSpace: "per-line", fontSize: "16px" }}>
                {challenge.details}
              </Linkify>
            </div>

            <div className="mt-auto pt-1 card-btn-group">
              {challenge.participants &&
              currentUser &&
              challenge.participants[currentUser.uid] ? (
                <button
                  onClick={() => this.onShowQuitModal()}
                  className="btn btn-outline-danger my-1"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Challenge Joined
                </button>
              ) : (
                <button
                  onClick={() => this.onJoinChallenge()}
                  className="btn btn-danger my-1"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Join Challenge
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

export default compose(withRouter, connect(mapStateToProps))(ChallengeCard);
