import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import SubscriptionForm from "../SubscriptionForm/SubscriptionForm";

// Layout Components
import Topbar from "./Topbar";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Topbar />
        {this.props.children}
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
