import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import configureStore from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const store = configureStore();

Sentry.init({
  dsn:
    "https://ffaefe478b404011be96b1d96c60bf86@o382460.ingest.sentry.io/5211332"
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

var firebaseConfig = {
  apiKey: "AIzaSyA5AshmLFeonS9iui9ldNKA0Mcha6wrPpE",
  authDomain: "lonely-dev.firebaseapp.com",
  databaseURL: "https://lonely-dev.firebaseio.com",
  projectId: "lonely-dev",
  storageBucket: "lonely-dev.appspot.com",
  messagingSenderId: "892358178598",
  appId: "1:892358178598:web:76820dbb68bfa2cc75f3e7",
  measurementId: "G-3P3PYFWC5C"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();
