import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import ImageUploader from "react-images-upload";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import Switch from "react-switch";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../../redux/action/userAction";
import MailchimpSubscribe from "react-mailchimp-subscribe";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class AddNewProductModal extends React.Component {
  constructor(props) {
    super(props);
    const { product } = this.props;

    this.state = {
      loading: false,
      logoImg: "",
      pictures: [],
      name: product ? product.name : "",
      description: product ? product.description : "",
      hashtag: product ? product.hashtag : "",
      website: product ? product.website : "",
      isLaunched: product ? product.isLaunched : "",
      phLink: product ? product.phLink : "",
      isUpdate: product ? true : false,
      hashtagTaken: false,
      lead: product ? product.lead : "",
      leadMC: product && product.leadMC ? product.leadMC : "",
      leadCK: product && product.leadCK ? product.leadCK : "",
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.product !== prevProps.product) {
      const { product } = this.props;
      this.setState({
        name: product.name,
        description: product.description,
        hashtag: product.hashtag,
        website: product.website,
        isLaunched: product.isLaunched,
        phLink: product.phLink,
        lead: product.lead || "",
        leadMC: product.leadMC || "",
        leadCK: product.leadCK || "",
        isUpdate: true,
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      loading: false,
      logoImg: "",
      name: "",
      description: "",
      hashtag: "",
      hashtagTaken: false,
      website: "",
      isLaunched: false,
      phLink: "",
      lead: "",
      leadMC: "",
      leadCK: "",
      isSubmitting: false,
      isUpdate: false,
    });
    this.props.setUserValue({ showAddNewProductModal: false });
  };

  handleInputChange = (event) => {
    const value =
      event.target.id === "isLaunched"
        ? event.target.checked
        : event.target.value;
    this.setState({ [event.target.id]: value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  updateProduct = async (event) => {
    this.setState({ isSubmitting: true });
    const {
      name,
      description,
      website,
      isLaunched,
      phLink,
      lead,
      leadMC,
      leadCK,
      pictures,
    } = this.state;
    const { user, product } = this.props;
    const { currentUser } = user;

    if (name === "" || description === "") {
      toast.error("Oops, Name and Pitch cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var fileURL = product.logoImg;
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref(currentUser.uid);
      var logoName = pictures[0].name;
      var fileRef = storageRef.child("products").child(name).child(logoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    await firebase //add to /products/{productId}
      .database()
      .ref("products")
      .child(product.id)
      .update({
        name: name,
        description: description,
        website: website,
        logoImg: fileURL,
        isLaunched: isLaunched,
        phLink: phLink,
        lead: lead,
        leadMC: leadMC,
        leadCK: leadCK,
      });

    await firebase //add to /users/{userId}/products/{productId}
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("products")
      .child(product.id)
      .update({
        name: name,
        description: description,
        website: website,
        logoImg: fileURL,
        isLaunched: isLaunched,
        phLink: phLink,
        lead: lead,
        leadMC: leadMC,
        leadCK: leadCK,
      });

    this.setState({ isSubmitting: false });
    this.props.setUserValue({ showAddNewProductModal: false });
    toast("Update your product successfully 🎉", {
      autoClose: 4000,
    });
  };

  submitNewProduct = async (event) => {
    this.setState({ isSubmitting: true });
    const {
      name,
      description,
      hashtag,
      website,
      isLaunched,
      phLink,
      lead,
      leadMC,
      leadCK,
      pictures,
      isUpdate,
    } = this.state;
    const { currentUser } = this.props.user;

    if (name === "" || description === "") {
      toast.error("Oops, Name and Pitch cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    // check if hashtag is taken or not

    const hashtagLower = hashtag.replace("#", "").toLowerCase();

    var hashtagRegex = /^([a-zA-Z0-9]{2,})+$/;
    if (hashtagLower.match(hashtagRegex) === null) {
      //didn't pass the hashtag validator, toast error and return
      if (hashtagLower.length < 2) {
        toast.error("Hashtag must be at least 2 characters long", {
          autoClose: 4000,
        });
        this.setState({ isSubmitting: false });
        return;
      }
      toast.error("Hashtag can only contain letters and numbers", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    const backgroundColor = `hsl(${Math.floor(
      Math.random() * 360
    )}, 100%, 93%)`;

    const productRef = await firebase
      .database()
      .ref("products")
      .child(hashtagLower)
      .once("value");

    if (productRef.exists() === false) {
      await firebase //add new product
        .database()
        .ref("products")
        .child(hashtagLower)
        .update({
          id: hashtagLower,
          ownerId: currentUser.uid,
          name: name,
          description: description,
          hashtag: hashtag,
          website: website,
          isLaunched: isLaunched,
          phLink: phLink,
          lead: lead,
          leadMC: leadMC,
          leadCK: leadCK,
          backgroundColor: backgroundColor,
          videoCount: 0,
          createdAt: Date.now(),
          user: {
            uid: currentUser.uid,
            userName: currentUser.userName || "",
            displayName: currentUser.displayName,
            avatar: currentUser.avatar,
            bio: currentUser.bio || "",
          },
        });
    } else {
      //hashtag is taken
      // toast.error("Hashtag is taken.", {
      //   autoClose: 4000
      // });

      this.setState({ hashtagTaken: true, isSubmitting: false });
      return;
    }

    var fileURL = "";
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref(currentUser.uid);
      var logoName = pictures[0].name;
      var fileRef = storageRef.child("products").child(name).child(logoName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    }

    await firebase //add to /products/{productId}
      .database()
      .ref("products")
      .child(hashtagLower)
      .update({
        logoImg: fileURL,
      });

    const newProduct = {
      ownerId: currentUser.uid,
      name: name,
      description: description,
      hashtag: hashtag,
      website: website,
      logoImg: fileURL,
      isLaunched: isLaunched,
      lead: lead,
      leadMC: leadMC,
      leadCK: leadCK,
      phLink: phLink,
      id: hashtagLower,
      backgroundColor: backgroundColor,
      createdAt: Date.now(),
    };
    await firebase //add to /users/{userId}/products/{productId}
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("products")
      .child(hashtagLower)
      .set(newProduct);

    this.setState({ isSubmitting: false });
    this.props.setUserValue({ showAddNewProductModal: false });
    toast.success("Add a new product successfully", {
      autoClose: 4000,
    });
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (logoImg) =>
        this.setState({
          logoImg,
          loading: false,
        })
      );
    }
  };

  render() {
    const { product } = this.props;
    const { showAddNewProductModal } = this.props.user;
    const {
      hashtagTaken,
      isLaunched,
      lead,
      isUpdate,
      isSubmitting,
    } = this.state;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Modal
        show={showAddNewProductModal}
        onHide={this.handleModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isUpdate ? "Update your product" : "Add a new product"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name" className="mb-0">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="IndieLog"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="description" className="mb-0">
              <Form.Label>Pitch</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="A video community for makers to share daily work and life"
                value={this.state.description}
                onChange={this.handleInputChange}
                maxLength="60"
              />
              <Form.Text className="text-muted">
                {60 - this.state.description.length} characters
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="hashtag" className="mb-0">
              <Form.Label>Hashtag</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="#indielog"
                value={this.state.hashtag}
                onChange={this.handleInputChange}
                disabled={product}
              />
              {!hashtagTaken ? (
                <Form.Text className="text-muted">For tagging videos</Form.Text>
              ) : (
                <Form.Text className="text-danger">
                  Sorry, this tag is taken.
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="website" className="mb-0">
              <Form.Label>Website (optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://indielog.com"
                value={this.state.website}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="lead">
              <Form.Label>Lead tools</Form.Label>
              <Form.Control
                as="select"
                value={lead}
                onChange={this.handleInputChange}
              >
                <option value="">None</option>
                <option value="mailchimp">MailChimp</option>
                <option value="convertkit">ConvertKit</option>
                <option value="website">Website link</option>
              </Form.Control>
            </Form.Group>

            {lead === "mailchimp" && (
              <Form.Group controlId="leadMC" className="mb-0">
                <Form.Label>MailChimp URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="https:////xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn"
                  value={this.state.leadMC}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            )}

            {lead === "convertkit" && (
              <Form.Group controlId="leadCK" className="mb-0">
                <Form.Label>ConvertKit Form Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="123456"
                  value={this.state.leadCK}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            )}

            {/* <Form.Group controlId="isLaunched">
              <Form.Check
                type="switch"
                id="isLaunched"
                label="Launched yet?"
                checked={isLaunched}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            {isLaunched && (
              <Form.Group controlId="phLink">
                <Form.Label>PH Link (optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="https://www.producthunt.com/posts/your-product"
                  value={this.state.phLink}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            )} */}

            <ImageUploader
              withIcon={true}
              buttonText="Upload your logo"
              label="Max size: 2MB, accepted: jpg, gif, png"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png"]}
              maxFileSize={2097152}
              singleImage={true}
              withPreview={true}
            />
          </Form>
          <button
            onClick={product ? this.updateProduct : this.submitNewProduct}
            className="btn btn-danger my-1"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddNewProductModal);
