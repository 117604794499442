import React, { Component } from "react";
import { Form, Button, Dropdown } from "semantic-ui-react";

class AddCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: this.props.isEditComment ? this.props.comment.comment : "",
      emojiPicker: false,
    };
    console.log();
  }

  handleCommentSubmit = async () => {
    const {
      addMeetupComment,
      editMeetupComment,
      meetupId,
      closeForm,
      parentId,
      isEditComment,
    } = this.props;
    const { comment } = this.state;
    var ret = isEditComment
      ? editMeetupComment(meetupId, comment, parentId)
      : await addMeetupComment(meetupId, comment, parentId);
    if (parentId !== 0) {
      closeForm();
    }
    ret !== "loginNeeded" && this.setState({ comment: "" });
  };

  render() {
    const {
      allowCancel,
      parentComment,
      isEditComment,
      meetupHost,
    } = this.props;

    return (
      <Form className={isEditComment ? "mt-0" : "mt-2"}>
        <Form.TextArea
          value={this.state.comment}
          onChange={(e, { value }) => {
            this.setState({ comment: value });
          }}
          placeholder={meetupHost ? "Say something to the host..." : ""}
          className="mb-2"
        />

        {parentComment && (
          <Button
            content={isEditComment ? "Save changes" : "Add a comment"}
            onClick={() => this.handleCommentSubmit(false)}
            basic
            color="blue"
            className="px-4"
          />
        )}
        {allowCancel && (
          <>
            <Button
              content={isEditComment ? "Save changes" : "Reply"}
              basic
              color="blue"
              onClick={() => this.handleCommentSubmit()}
              className="ml-2 px-4"
            />
            <Button
              content="Cancel"
              basic
              onClick={this.props.closeForm}
              className="ml-2"
            />
          </>
        )}
      </Form>
    );
  }
}

export default AddCommentForm;
