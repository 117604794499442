import React, { Fragment } from "react";
import { Form, Button, Feed, Input } from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import { format, formatDistance, isToday, isSameDay } from "date-fns";
import { setUserValue } from "../../redux/action/userAction";
import { objectToArray } from "../Video/helpers";
import "./DirectMessage.css";

let feedListener;

class LazyUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newLog: "",
      feeds: [],
      prevDay: Date.now() - 86400000,
      currDay: Date.now(),
      dateSet: false,
    };
  }

  componentDidMount = async () => {
    feedListener = firebase
      .database()
      .ref("lazy-updates-feeds")
      .on("value", (snapshot) => {
        if (snapshot) {
          this.setState({ feeds: objectToArray(snapshot.val()) });
        }
      });
  };

  componentWillUnmount() {
    this.state.videoId &&
      firebase.database().ref("lazy-updates-feeds").off("value", feedListener);
  }

  renderFeeds = (feeds) => {
    if (!feeds) return [];

    var reversedFeeds = Object.values(feeds).reverse();
    const { prevDay, currDay } = this.state;

    return reversedFeeds.map((feed) => {
      return (
        <>
          {/* {this.setDateHeader(feed, prevDay, currDay)} */}
          <Feed.Event key={feed.createdAt}>
            <Feed.Label image={feed.avatar} />
            <Feed.Content>
              <Feed.Summary>
                <a>{feed.displayName}</a>
                <Feed.Date>
                  {formatDistance(feed.createdAt, Date.now())} ago
                </Feed.Date>
              </Feed.Summary>
              <Feed.Extra text>{feed.log}</Feed.Extra>
              {/* <Feed.Meta>
              <Feed.Like>
                <Icon name="like" />
                {feed.likes} Likes
              </Feed.Like>
            </Feed.Meta> */}
            </Feed.Content>
          </Feed.Event>
        </>
      );
    });
  };

  postNewLog = async () => {
    const { currentUser } = this.props.user;
    const { newLog } = this.state;

    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    if (newLog === "") {
      toast.error("Sorry, log cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    let newLogObj = currentUser.userName
      ? {
          displayName: currentUser.displayName,
          avatar: currentUser.avatar,
          uid: currentUser.uid,
          userName: currentUser.userName,
          email: currentUser.email || "",
          log: newLog,
          likes: 0,
          createdAt: Date.now(),
        }
      : {
          displayName: currentUser.displayName,
          avatar: currentUser.avatar,
          uid: currentUser.uid,
          email: currentUser.email || "",
          log: newLog,
          likes: 0,
          createdAt: Date.now(),
        };

    try {
      const newLogRef = await firebase
        .database()
        .ref("lazy-updates-feeds")
        .push(newLogObj);
      const newLogId = newLogRef.key;

      await firebase
        .database()
        .ref("lazy-updates")
        .child(currentUser.uid)
        .child(newLogId)
        .set(newLogObj);

      await firebase
        .database()
        .ref("activities")
        .child(currentUser.uid)
        .push({
          type: "New Lazy Update",
          content: {
            displayName: currentUser.displayName,
            avatar: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser.email || "",
            log: newLog,
          },
          createdAt: Date.now(),
        });
      this.setState({ newLog: "" });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "postNewLog in LazyUpdate",
        error: error,
      });
    }
  };

  render() {
    const { feeds, newLog } = this.state;
    return (
      <Fragment>
        <section className="bg-messages">
          <div className="content messages">
            <div className="container top-wrap">
              <div className="row">
                <div className="col-lg-4 d-none d-md-none d-lg-flex">
                  <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <ul>
                      <li className="border-t border-gray-200">
                        <a
                          href="#"
                          className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                        >
                          <div className="flex items-center px-4 py-4 sm:px-6">
                            <div className="min-w-0 flex-1 flex items-center">
                              <div className="flex-shrink-0">
                                <img
                                  className="h-12 w-12 rounded-full"
                                  src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                  alt=""
                                />
                              </div>
                              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                                <div>
                                  <div className="text-lg leading-5 font-medium text-indigo-600">
                                    Ted Fox
                                  </div>
                                  <div className="mt-2 flex items-center text-md leading-5 text-gray-500">
                                    30 minutes ago
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <svg
                                className="h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8">
                  <Form onSubmit={this.postNewLog}>
                    <Input
                      value={newLog}
                      action={{ icon: "send" }}
                      onChange={(e, { value }) => {
                        console.log(value);
                        this.setState({ newLog: value });
                      }}
                      placeholder="Write a new message..."
                      className="w-full"
                    />
                  </Form>

                  {/* <button
              type="button"
              className="btn btn-outline-success btn-lg mr-3"
            >
              New Feature
            </button>
            <button type="button" className="btn btn-outline-danger btn-lg">
              Report Bug
            </button> */}
                  <Feed>{this.renderFeeds(feeds)}</Feed>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LazyUpdate);
