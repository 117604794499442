import React from "react";
import { format } from "date-fns";
import firebase from "firebase/app";
import "firebase/database";
import { Header, Segment } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  return Array.from({ length: count }, (_, i) => i);
}

const today = new Date();

class HeatmapHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      videoCount: 0,
      uploadHistory: getRange(365).map(index => {
        let tmpDate = shiftDate(today, -index);
        return {
          date: format(tmpDate, "PP"),
          count: 0
        };
      })
    };
  }

  componentDidMount = async () => {
    const videosRef = await firebase
      .database()
      .ref("users")
      .child(this.props.user.uid)
      .child("videos")
      .once("value");

    const allUserVideos = videosRef.val();
    const { uploadHistory } = this.state;
    var tmpUploadHistory = uploadHistory;

    const videos = (allUserVideos && Object.values(allUserVideos)) || [];
    // eslint-disable-next-line array-callback-return
    videos.map(video => {
      const daysFromNow =
        Math.floor(
          (Date.now() - today.getTimezoneOffset() * 60000) / 86400000
        ) -
        Math.floor(
          (video.createdAt - today.getTimezoneOffset() * 60000) / 86400000
        );
      if (daysFromNow <= 365) {
        let tmpDate = shiftDate(today, -daysFromNow);
        tmpUploadHistory[daysFromNow] = {
          date: format(tmpDate, "PP"),
          count: 1
        };
      }
    });

    this.setState({
      uploadHistory: tmpUploadHistory,
      videoCount: videos.length
    });
  };

  render() {
    const { uploadHistory, videoCount } = this.state;
    const { user } = this.props;
    const headerContent =
      videoCount > 0
        ? "This is your journey, " + user.displayName.split(" ")[0] + " 👇"
        : "Hey " +
          user.displayName.split(" ")[0] +
          ", add a video to your journey 👇";

    return (
      <div className="mt-4 mb-4">
        <Header attached="top" content={headerContent} />
        <Segment attached>
          <CalendarHeatmap
            startDate={shiftDate(today, -365)}
            endDate={today}
            values={uploadHistory}
            classForValue={value => {
              if (!value) {
                return "color-empty";
              }
              return `color-github-${value.count}`;
            }}
            tooltipDataAttrs={value => {
              return {
                "data-tip": `${value.date}`
                // "data-tip": `${value.date}`
              };
            }}
            showWeekdayLabels={false}
          />
          <ReactTooltip />
        </Segment>
      </div>
    );
  }
}

export default HeatmapHistory;
