import React, { Component } from "react";
import { connect } from "react-redux";
import { setUserValue } from "../../../redux/action/userAction";
import failedImg from "../../../assets/images/failed.png";

class EditResult extends Component {
  componentDidMount() {
    const { editSucceed } = this.props;
    this.props.modalHeader(editSucceed ? "Edit Successful" : "Edit Failed");
  }
  onClose = () => {
    this.props.setUserValue({ showEditVideoModal: false });
  };

  onTryAgain = () => {
    this.props.tryAgain();
  };

  render() {
    const { editSucceed } = this.props;
    return (
      <div className="uploadForm">
        <div className="uploadMainContent">
          {editSucceed ? (
            <div className="fileUploaderWrapper text-center">
              <div className="uploadIcon">
                <img
                  className="img-fluid"
                  src="https://media1.giphy.com/media/g9582DNuQppxC/giphy.gif?cid=ecf05e47ibtkj6mhht2m6gpzy157hwtxvlxlzqlijwrfqh8i&rid=giphy.gifC"
                  alt="success"
                />
              </div>
              <div className="uploadBTN recordBtn">
                <h4>Nice! You are all set 🙌</h4>
                <button
                  type="button"
                  className="btn btn-danger my-1"
                  onClick={this.onClose}
                >
                  Click to close
                </button>
              </div>
            </div>
          ) : (
            <div className="fileUploaderWrapper text-center">
              <div className="uploadIcon">
                <img src={failedImg} className="img-fluid" alt="failure" />
              </div>
              <div className="uploadBTN recordBtn">
                <h4>Sorry, something bad just happened 🙀</h4>
                <button
                  type="button"
                  className="btn btn-danger my-1"
                  onClick={this.onTryAgain}
                >
                  Click to edit again
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(null, mapDispatchToProps)(EditResult);
