import React, { Component } from "react";
import { Feed } from "semantic-ui-react";
import { withRouter } from "react-router";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";

const getName = (name) => {
  const nameArr = name.split(" ");
  if (nameArr.length > 1) {
    return nameArr[0][0] + nameArr[1][0];
  } else {
    if (nameArr[0].length > 1) {
      return nameArr[0].substring(0, 2);
    } else {
      return nameArr[0][0];
    }
  }
};

class ThisWeekProductsItem extends Component {
  render() {
    const { product } = this.props;

    const letterLogo = getName(product.name);
    return (
      <Feed.Event>
        <Feed.Content>
          <Feed.Summary>
            <div
              className="week-product-item"
              style={{
                backgroundColor: product.backgroundColor,
              }}
            >
              <Badge color="secondary" badgeContent={product.count}>
                {product.logoImg !== "" ? (
                  <img
                    className="logo"
                    src={product.logoImg}
                    onClick={() =>
                      this.props.history.push("/product/" + product.id)
                    }
                  />
                ) : (
                  <Avatar
                    variant="square"
                    onClick={() =>
                      this.props.history.push("/product/" + product.id)
                    }
                    className="this-week-product-avatar"
                    style={{ marginLeft: 0 }}
                  >
                    {letterLogo}
                  </Avatar>
                )}
              </Badge>

              <div
                className="infos"
                onClick={() =>
                  this.props.history.push("/product/" + product.id)
                }
              >
                <p className="week-product-name">{product.name}</p>
              </div>
              <div className="user">
                <img
                  className="profile-pic"
                  src={product.user.avatar}
                  onClick={() =>
                    this.props.history.push(
                      "/user/" +
                        (product.user.userName !== ""
                          ? product.user.userName
                          : product.user.uid)
                    )
                  }
                />
              </div>
            </div>
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    );
  }
}

export default withRouter(ThisWeekProductsItem);
