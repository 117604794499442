import React, { Component } from "react";
import { connect } from "react-redux";
import { Feed } from "semantic-ui-react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";
import { toast } from "react-toastify";
import { setUserValue } from "../../../redux/action/userAction";

class NewMembersSidebarItem extends Component {
  sayHi = async () => {
    const { member, currentUser, setUserValue } = this.props;

    if (!currentUser) {
      setUserValue({ showSignInModal: true });
      return;
    }

    await firebase
      .database()
      .ref("new-members")
      .child(member.uid)
      .child("welcomedBy")
      .child(currentUser.uid)
      .set({
        displayName: currentUser.displayName,
        userName: currentUser.userName || "",
        avatar: currentUser.avatar,
        email: currentUser.email || "",
        uid: currentUser.uid,
      });

    toast("👋 You just said hi to " + member.displayName, {
      autoClose: 3000,
    });
  };

  render() {
    const { member, currentUser } = this.props;
    const userName = member.userName ? member.userName : member.uid;

    return (
      <Feed.Event>
        <Feed.Label>
          <img src={member.avatar || "/assets/user.png"} alt="" />
        </Feed.Label>
        <Feed.Content>
          <Feed.Summary>
            <Feed.User as={Link} to={{ pathname: "/user/" + userName }}>
              {member.displayName}
            </Feed.User>{" "}
            joined our community{" "}
            <span
              onClick={() => this.sayHi()}
              className={
                member.welcomedBy &&
                currentUser &&
                member.welcomedBy[currentUser.uid]
                  ? "said-hi"
                  : "say-hi waving-hand inline-block"
              }
            >
              👋
            </span>
          </Feed.Summary>
          <Feed.Extra text>
            {member.bio && member.bio.length > 120
              ? member.bio.substring(0, 120) + "..."
              : member.bio}
          </Feed.Extra>
        </Feed.Content>
      </Feed.Event>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state && state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewMembersSidebarItem);
