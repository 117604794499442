import React from "react";
import { withRouter } from "react-router";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import "./productItem.css";

const getName = (name) => {
  const nameArr = name.split(" ");
  if (nameArr.length > 1) {
    return nameArr[0][0] + nameArr[1][0];
  } else {
    if (nameArr[0].length > 1) {
      return nameArr[0].substring(0, 2);
    } else {
      return nameArr[0][0];
    }
  }
};

class ProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { product, hideAvatar } = this.props;

    if (!product) return null;
    const {
      name,
      description,
      logoImg,
      hashtag,
      backgroundColor,
      videoCount,
      // user: { name, profilePic }
    } = product;

    const hashtagLower = hashtag.replace("#", "").toLowerCase();

    const letterLogo = getName(name);

    return (
      <div
        className="product-item"
        style={{
          // backgroundColor: `hsl(${Math.floor(Math.random() * 360)}, 100%, 93%)`
          backgroundColor: backgroundColor,
        }}
      >
        <Badge color="secondary" badgeContent={videoCount}>
          {logoImg !== "" ? (
            <img
              className="logo"
              src={logoImg}
              onClick={() =>
                this.props.history.push("/product/" + hashtagLower)
              }
            />
          ) : (
            <Avatar
              variant="square"
              onClick={() =>
                this.props.history.push("/product/" + hashtagLower)
              }
              className="product-item-avatar"
            >
              {letterLogo}
            </Avatar>
          )}
        </Badge>

        <div
          className="infos"
          onClick={() => this.props.history.push("/product/" + hashtagLower)}
        >
          <p className="product-name">{name}</p>
          <p className="product-description">{description}</p>
        </div>
        {!hideAvatar && (
          <div className="user">
            <img
              className="profile-pic"
              src={product.user.avatar}
              onClick={() =>
                this.props.history.push(
                  "/user/" +
                    (product.user.userName !== ""
                      ? product.user.userName
                      : product.user.uid)
                )
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ProductItem);
