import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home/Home";
import Weekly from "./pages/Weekly/Weekly";
import WatchVideo from "./pages/Video/Video";
import Dashboard from "./pages/Dashboard/Dashboard";
import Invite from "./pages/Invite/Invite";
import LazyUpdate from "./pages/LazyUpdate/LazyUpdate";
import DirectMessage from "./pages/DirectMessage/DirectMessage";
import Notifications from "./pages/Notifications/Notifications";
import ProductPage from "./pages/Product/Product";
import IndieTuber from "./pages/IndieTuber/IndieTuber";
import Challenge from "./pages/Challenge/Challenge";
import ChallengeDetails from "./pages/Challenge/ChallengeDetails";
import AllProducts from "./pages/AllProducts/AllProducts";
import Meetup from "./pages/Meetup/Meetup";
import MeetupDetails from "./pages/Meetup/MeetupDetails";
import Settings from "./pages/Settings/Settings";
import Profile from "./pages/Profile/Profile";
import AMA from "./pages/AMA/AMA";
import AmaVideo from "./pages/AmaVideo/AmaVideo";
import Privacy from "./pages/Privacy/Privacy";
import PageError from "./pages/PageError/PageError";
import "./App.scss";
import "./css/video-react.css";
import "./css/materialdesignicons.min.css";

toast.configure();
class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => (
                <Layout>
                  <Home {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/topics"
              component={(props) => (
                <Layout>
                  <Weekly {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path={["/topics/:weekId"]}
              component={(props) => (
                <Layout>
                  <Weekly {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/ama"
              component={(props) => (
                <Layout>
                  <AMA {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path={["/ama/:sessionId"]}
              component={(props) => (
                <Layout>
                  <AmaVideo {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path={["/log"]}
              component={(props) => (
                <Layout>
                  <LazyUpdate {...props} />
                </Layout>
              )}
            />
            {/* <Route
              exact
              path={["/messages"]}
              component={(props) => (
                <Layout>
                  <DirectMessage {...props} />
                </Layout>
              )}
            /> */}
            <Route
              exact
              path={["/notifications"]}
              component={(props) => (
                <Layout>
                  <Notifications {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path={["/video/:videoId"]}
              component={(props) => (
                <Layout>
                  <WatchVideo {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/dashboard"
              component={(props) => (
                <Layout>
                  <Dashboard {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/invite"
              component={(props) => (
                <Layout>
                  <Invite {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path={["/user/:userId"]}
              component={(props) => (
                <Layout>
                  <Dashboard {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path={["/product/:productId"]}
              component={(props) => (
                <Layout>
                  <ProductPage {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/products"
              component={(props) => (
                <Layout>
                  <AllProducts {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/meetups"
              component={(props) => (
                <Layout>
                  <Meetup {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/meetups/:meetupId"
              component={(props) => (
                <Layout>
                  <MeetupDetails {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/indietuber"
              component={(props) => (
                <Layout>
                  <IndieTuber {...props} />
                </Layout>
              )}
            />
            {/* <Route
              exact
              path="/challenges"
              component={(props) => (
                <Layout>
                  <Challenge {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path={["/challenges/:challengeId"]}
              component={(props) => (
                <Layout>
                  <ChallengeDetails {...props} />
                </Layout>
              )}
            /> */}
            <Route
              exact
              path="/profile"
              component={(props) => (
                <Layout>
                  <Profile {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/settings"
              component={(props) => (
                <Layout>
                  <Settings {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/privacy"
              component={(props) => (
                <Layout>
                  <Privacy {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/error"
              component={(props) => (
                <Layout>
                  <PageError {...props} />
                </Layout>
              )}
            />
            <Route
              component={(props) => (
                <Layout>
                  <PageError {...props} />
                </Layout>
              )}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
