import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { MainNewVideoModal } from "./MainNewVideoModal";
import { setUserValue } from "../../../redux/action/userAction";
import "./NewVideoModal.css";

class NewVideoModal extends React.Component {
  handleModalClose = () => {
    this.props.setUserValue({
      showAddNewVideoModal: false,
      isWeeklyVideo: false,
      product: null,
      weeklyTopicId: "",
      AmaAnswer: false,
      AmaQuestion: false,
    });
  };

  render() {
    const { user, type } = this.props;

    const {
      showAddNewVideoModal,
      isAMA,
      AmaQuestion,
      AmaAnswer,
      videoId,
      commentParentId,
      isWeeklyVideo,
      weeklyTopicId,
      product,
    } = user;

    return (
      <Modal
        show={showAddNewVideoModal}
        onHide={this.handleModalClose}
        backdrop="static"
        size="lg"
      >
        <MainNewVideoModal
          currentUser={user.currentUser}
          type={type}
          videoId={videoId}
          commentParentId={commentParentId}
          product={product}
          isWeeklyVideo={isWeeklyVideo}
          weeklyTopicId={weeklyTopicId}
          isAMA={isAMA}
          AmaQuestion={AmaQuestion}
          AmaAnswer={AmaAnswer}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewVideoModal);
