import React, { Component } from "react";
import { Feed } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";

class RecentActivityItem extends Component {
  renderSummary = (activity) => {
    const userName = activity.user.userName
      ? activity.user.userName
      : activity.user.uid;
    switch (activity.type) {
      case "newMember": //done V
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname:
                  "/user/" +
                  (activity.user.userName
                    ? activity.user.userName
                    : activity.user.uid),
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            joined our community 🥳
          </div>
        );
      case "newIndietuber": //done V
        return (
          <div>
            <Feed.User
              as={Link}
              to={{ pathname: "/user/" + activity.user.uid }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            added a channel to{" "}
            <Link to={{ pathname: "/indietuber" }}>IndieTuber</Link> 👏
          </div>
        );
      case "newTextComment": //done V
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            post a reply to a{" "}
            <Link
              to={{ pathname: "/video/" + activity.videoId + "?autoplay=off" }}
            >
              video
            </Link>
          </div>
        );
      case "newVideoComment": //done V
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            made a cool video 🎥 reply to a{" "}
            <Link
              to={{ pathname: "/video/" + activity.videoId + "?autoplay=off" }}
            >
              video
            </Link>
          </div>
        );
      case "newProductAdded": //done V
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            added a product{" "}
            <Link to={{ pathname: "/product/" + activity.product.id }}>
              {activity.product.name}
            </Link>{" "}
            🎉
          </div>
        );
      case "newProductPitchVideo": //done V
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            added a pitch video 🎥 to the product{" "}
            <Link to={{ pathname: "/product/" + activity.product.id }}>
              {activity.product.name}
            </Link>
          </div>
        );
      case "newVideo": //done
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            shared a{" "}
            <Link to={{ pathname: "/video/" + activity.videoId }}>video</Link>{" "}
            🎥
          </div>
        );
      case "newAmaQuestion":
        console.log("ama question", activity);
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            asked a question in {activity.amaOp}'s{" "}
            <Link to={{ pathname: "/ama/" + activity.amaId }}>AMA</Link>
          </div>
        );
      case "newAmaAnswer":
        return (
          <div>
            <Feed.User
              as={Link}
              to={{
                pathname: "/user/" + userName,
              }}
            >
              {activity.user.displayName}
            </Feed.User>{" "}
            answered your question in{" "}
            <Link to={{ pathname: "/ama/" + activity.amaId }}>AMA</Link>
          </div>
        );
      default:
        return;
    }
  };

  render() {
    const { activity } = this.props;
    return (
      <Feed.Event>
        <Feed.Label
          as={Link}
          to={{
            pathname:
              "/user/" +
              (activity.user.userName
                ? activity.user.userName
                : activity.user.uid),
          }}
        >
          <img src={activity.user.avatar || "/assets/user.png"} alt="" />
        </Feed.Label>
        <Feed.Content>
          <Feed.Summary>{this.renderSummary(activity)}</Feed.Summary>
          <Feed.Meta>
            <Feed.Date>
              {formatDistance(activity.createdAt, Date.now())} ago
            </Feed.Date>
          </Feed.Meta>
        </Feed.Content>
      </Feed.Event>
    );
  }
}

export default RecentActivityItem;
