import React from "react";
import {
  Modal,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { format, parseISO, getTime } from "date-fns";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import { uuid } from "uuidv4";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ImageUploader from "react-images-upload";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../../redux/action/userAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddNewMeetupModal.css";

class AddNewMeetupModal extends React.Component {
  constructor(props) {
    super(props);

    var d = new Date();
    this.state = {
      loading: false,
      pictures: [],
      title: "",
      shortDescription: "",
      description: "",
      meetingTime: d.setHours(d.setMinutes(new Date(), 0), 8),
      duration: "",
      slots: 0,
      isSubmitting: false,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      pictures: [],
      title: "",
      shortDescription: "",
      description: "",
      date: "",
      time: "",
      slots: 0,
      isSubmitting: false,
    });
    this.props.setUserValue({ showAddNewMeetupModal: false });
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({ [event.target.id]: value });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  submitNewMeetup = async (event) => {
    this.setState({ isSubmitting: true });
    const {
      title,
      shortDescription,
      description,
      meetingTime,
      duration,
      slots,
      pictures,
    } = this.state;

    const { currentUser } = this.props.user;

    if (title === "" || shortDescription === "" || description === "") {
      toast.error("Oops, title and description cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    if (slots > 10) {
      toast.error("Sorry, slots cannot exceed 10.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var newName = "";
    var tmpName = "";

    newName = title
      .toLowerCase()
      // eslint-disable-next-line no-useless-escape
      .replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, "-")
      .replace(/^(-)+|(-)+$/g, "");

    var meetupRef = null;
    do {
      tmpName = newName + "-" + uuid().split("-")[0];
      // eslint-disable-next-line no-await-in-loop
      meetupRef = await firebase
        .database()
        .ref("meetups")
        .child(tmpName)
        .once("value");
    } while (meetupRef && meetupRef.exists() === true);
    newName = tmpName;

    // expires 1 day later, in seconds
    const expiredAt = Math.floor(getTime(meetingTime) / 1000) + 86400;

    // not allow to join the room 10 minute before
    const notBefore = Math.floor(getTime(meetingTime) / 1000) - 600;

    var createRoom = firebase.functions().httpsCallable("dailycoCreateRoom");
    const result = await createRoom({
      expiredAt: expiredAt,
      notBefore: notBefore,
    });
    const dailyRoomName = result.data.name;

    await firebase.database().ref("meetup-rooms").child(dailyRoomName).set({
      meetupURL: newName,
      meetingTime: meetingTime,
      meetingRoom: dailyRoomName,
    });

    await firebase //add new meetup
      .database()
      .ref("meetups")
      .child(newName)
      .update({
        id: newName,
        meetingRoom: dailyRoomName,
        title: title,
        shortDescription: shortDescription,
        description: description,
        meetingTime: meetingTime,
        duration: duration,
        slots: slots,
        host: {
          displayName: currentUser.displayName,
          uid: currentUser.uid,
          email: currentUser.email,
          avatar: currentUser.avatar,
        },
        expiredAt: expiredAt,
        notBefore: notBefore,
        createdAt: Date.now(),
      });

    var fileURL = "";
    if (pictures[0]) {
      var storageRef = await firebase.storage().ref(currentUser.uid);
      var imgName = pictures[0].name;
      var fileRef = storageRef.child("meetups").child(newName).child(imgName);

      var uploadedFile = await fileRef.put(pictures[0]);
      fileURL = await uploadedFile.ref.getDownloadURL();
    } else {
      fileURL =
        "https://images.unsplash.com/photo-1501353163335-102e39d92607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3450&q=80";
    }

    await firebase.database().ref("meetups").child(newName).update({
      backgroundImg: fileURL,
    });

    var readableTime = format(meetingTime, "MMMM d, yyyy h:mm aa");
    var icsTime = format(meetingTime, "yyyy-M-d-H-m");

    await firebase
      .database()
      .ref("meetups")
      .child(newName)
      .child("attendees")
      .child(currentUser.uid)
      .set({
        email: currentUser.email || "",
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        id:
          currentUser.userName && currentUser.userName !== ""
            ? currentUser.userName
            : currentUser.uid,
        isHost: true,
        meetingLocalTime: readableTime,
        icsTime: icsTime,
      });

    this.setState({ isSubmitting: false });
    this.props.setUserValue({ showAddNewMeetupModal: false });
    toast("Created a new meetup successfully", {
      autoClose: 4000,
    });
  };

  handleDescriptionChange = (description) => {
    this.setState({ description: description });
  };

  render() {
    const { showAddNewMeetupModal } = this.props.user;
    const { isSubmitting } = this.state;

    return (
      <Modal
        show={showAddNewMeetupModal}
        onHide={this.handleModalClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Host a new meetup </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title" className="mb-0">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={this.state.title}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="shortDescription" className="mb-0">
              <Form.Label>Brief intro</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                maxLength="120"
                value={this.state.shortDescription}
                onChange={this.handleInputChange}
              />
              <Form.Text className="text-muted">
                {120 - this.state.shortDescription.length} characters
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="description" className="mb-0">
              <Form.Label>Event details</Form.Label>
              <SimpleMDE
                id="description"
                value={this.state.description}
                onChange={this.handleDescriptionChange}
                options={{
                  autofocus: false,
                  spellChecker: false,
                  status: false,
                  onToggleFullScreen: false,
                  toolbar: [
                    "bold",
                    "italic",
                    "heading",
                    "|",
                    "quote",
                    "unordered-list",
                    "ordered-list",
                    "|",
                    "code",
                    "link",
                    "image",
                    "|",
                    "preview",
                  ],
                }}
              />
            </Form.Group>

            <Form.Group controlId="slots" className="mb-0">
              <Form.Label>How many slots?</Form.Label>
              <Form.Control
                required
                type="number"
                max={10}
                placeholder=""
                value={this.state.slots}
                onChange={this.handleInputChange}
              />
              <Form.Text className="text-muted">
                To keep the meetup productive, maximum is 10
              </Form.Text>
            </Form.Group>

            <Row>
              <Col sm={12} md={12} lg={6}>
                <Form.Group controlId="date" className="mb-0">
                  <Form.Label className="mr-2">Date</Form.Label>
                  <DatePicker
                    selected={this.state.meetingTime}
                    onChange={(date) => {
                      this.setState({ meetingTime: date });
                    }}
                    showTimeSelect
                    placeholderText="Select a date and time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="meetup-date-picker"
                  />
                </Form.Group>
              </Col>

              <Col sm={12} md={12} lg={6}>
                <Form.Group controlId="duration" className="mb-0">
                  <InputGroup>
                    <Form.Label className="mr-2">Meetup Duration</Form.Label>
                    <FormControl
                      type="number"
                      aria-label="Meeting duration"
                      aria-describedby="duration"
                      value={this.state.duration}
                      onChange={this.handleInputChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="duration">minutes</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <ImageUploader
              withIcon={true}
              buttonText="Upload the image"
              label="Max size: 20MB, accepted: jpg, gif, png"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
              maxFileSize={20971520}
              singleImage={true}
              withPreview={true}
            />
          </Form>
          <button
            onClick={this.submitNewMeetup}
            className="btn btn-danger my-1"
            disabled={isSubmitting ? true : false}
          >
            {isSubmitting ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddNewMeetupModal);
