import React, { Fragment } from "react";
import firebase from "firebase/app";
import "firebase/database";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Alert } from "react-bootstrap";
import Input from "./Input";
import PulseLoader from "react-spinners/PulseLoader";
import "./Invite.scss";

class Invite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      message:
        "Hey, I'm vlogging my startup journey on IndieLog. It's a cool community for Indie Hackers / Makers. Come take a look and let's share our journey together 🤗",
      isSending: false,
      isSent: false,
    };
  }

  selectedTags = (tags) => {
    let emails = [];
    if (tags.length > 0) {
      emails = tags.map((tag) => {
        return tag.displayValue;
      });

      this.setState({ emails: emails });
    }
  };

  handleMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSending: true });
    const { emails, message } = this.state;
    const { currentUser } = this.props.user;

    const inviteObj = {
      emails: emails,
      message: message,
      inviter: {
        name: currentUser.displayName,
        email: currentUser.email,
        avatar: currentUser.avatar,
        uid: currentUser.uid,
        userName: currentUser.userName,
      },
      createdAt: Date.now(),
    };

    await firebase.database().ref("invite-friends").push(inviteObj);
    setTimeout(() => this.setState({ isSending: false, isSent: true }), 1000);
  };

  render() {
    const { emails, message, isSending, isSent } = this.state;
    return (
      <Fragment>
        <main className="bg-light invite-container">
          <div className="headerSpace container"></div>
          <div className="container mt-5 px-5">
            {isSent && (
              <Alert
                variant="success"
                onClose={() => this.setState({ isSent: false })}
              >
                <p>
                  We've sent the invite to your friend. Thanks for your shoutout
                  ❣️
                </p>
              </Alert>
            )}
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <div className="card shadow rounded card-content">
                  <h4 className="card-header py-4">Invite your friends</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-body">
                        <form className="form-content">
                          <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Comma-separated emails"
                              hidden
                            />
                            <Input selectedTags={this.selectedTags} />
                            <span
                              className="text-muted"
                              style={{ fontSize: "12px" }}
                            >
                              Type email and hit <strong>Enter</strong> to add
                            </span>
                          </div>

                          <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                              className="form-control"
                              id="message"
                              rows="6"
                              placeholder="Write a personalized message to your friends"
                              value={message}
                              onChange={(e) => this.handleMessage(e)}
                            />
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* <div className="col-md-4 aside">
                      <aside className="card-body">
                        <h5 className="card-title pt-4 pb-2">
                          Want some free stickers?
                        </h5>
                        <p className="card-text">
                          Simply invite at least 3 friends, and you could get
                          our IndieLog stickers 👇
                        </p>

                        <div className="image-wrap">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRxbJGUsFGCEQu69ijELim2N1W08JzSQiRX0g&usqp=CAU"
                            alt="team"
                          />
                        </div>
                      </aside>
                    </div> */}
                  </div>
                  <div className="card-footer py-4">
                    <button
                      className="btn btn-danger my-1"
                      disabled={!emails || emails.length < 1}
                      onClick={this.handleSubmit}
                    >
                      {isSending ? (
                        <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                      ) : (
                        "Send Invite"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

export default compose(withRouter, connect(mapStateToProps))(Invite);
