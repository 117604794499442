import React from "react";
import { Header, Segment, Feed } from "semantic-ui-react";
import NewMembersSidebarItem from "./NewMembersSidebarItem";
import "./NewMembersSidebar.css";

const NewMembersSidebar = ({ newMembers, user, contextRef }) => {
  return (
    <div className="mt-4 mb-4">
      <Header attached="top" content="Say 👋 to our new friends" />
      <Segment attached>
        <Feed>
          {newMembers.map((member) => (
            <NewMembersSidebarItem
              key={member.uid}
              member={member}
              currentUser={user}
            />
          ))}
        </Feed>
      </Segment>
    </div>
  );
};

export default NewMembersSidebar;
