import React from "react";
import { formatDistance } from "date-fns";
import Tippy from "@tippyjs/react";
import { useSpring } from "framer-motion";
import Avatar from "@material-ui/core/Avatar";
import "tippy.js/dist/tippy.css"; // optional
import "./SingleVideoCard.css";
import spamImg from "../../assets/images/spam-poster.png";

const SingleVideoCard = (props) => {
  const { video, profileView, homePage } = props;
  const springConfig = { damping: 15, stiffness: 300 };
  const initialScale = 0.5;
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(initialScale, springConfig);

  function onMount() {
    scale.set(1);
    opacity.set(1);
  }

  function onHide({ unmount }) {
    const cleanup = scale.onChange((value) => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });

    scale.set(initialScale);
    opacity.set(0);
  }

  function getName(name) {
    const nameArr = name.split(" ");
    if (nameArr.length > 1) {
      return nameArr[0][0] + nameArr[1][0];
    } else {
      if (nameArr[0].length > 1) {
        return nameArr[0].substring(0, 2);
      } else {
        return nameArr[0][0];
      }
    }
  }

  if (!video.reported) {
    var img =
      video.moreData.aspect_ratio === "3:4" ||
      video.moreData.aspect_ratio === "9:16"
        ? (video.thumbnail &&
            video.thumbnail + "&width=400&height=228&fit_mode=pad") ||
          "https://image.mux.com/" +
            video.playbackIds[0].id +
            "/thumbnail.png?width=400&height=228&fit_mode=pad" // for vertical video
        : video.thumbnail &&
          video.thumbnail ===
            "https://image.mux.com/" +
              video.playbackIds[0].id +
              "/thumbnail.png"
        ? video.thumbnail + "?width=400"
        : video.thumbnail
        ? video.thumbnail + "&width=400"
        : "https://image.mux.com/" +
          video.playbackIds[0].id +
          "/thumbnail.png?width=400";

    var gif =
      "https://image.mux.com/" +
      video.playbackIds[0].id +
      "/animated.gif?fps=5";
  } else {
    img = spamImg;
    gif = spamImg;
  }

  var videoId = video.playbackIds[0].id;

  const letterLogo =
    video.product && video.product.name ? getName(video.product.name) : "";

  return (
    <div
      className={
        homePage
          ? "col-xxxl-2 col-xxl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-3 pr-0"
          : "col-xxxl-2 col-xxl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 mb-3 pr-0"
      }
    >
      <div className="card">
        <a
          className="card-image"
          href={"/video/" + (video.titleURL ? video.titleURL : videoId)}
        >
          <img src={img} loading="lazy" className="img-fluid" alt="" />
          <span className="play_time">
            {new Date(video.duration * 1000).toISOString().substr(14, 5)}
          </span>
          <div className="hover-card">
            <img src={gif} loading="lazy" className="img-fluid" alt="" />
          </div>
        </a>
        <div className="card-body">
          <div className="video_titles position-relative overflow-hidden">
            {!profileView && (
              <a
                className="vdo_imgs"
                href={
                  "/user/" +
                  (video.user.userName ? video.user.userName : video.user.uid)
                }
              >
                <img src={video.user.avatar} alt="" />
              </a>
            )}
            <div className="vdo_metas">
              <a href={"/video/" + (video.titleURL ? video.titleURL : videoId)}>
                <h4 style={{ marginBottom: 4 }}>
                  {video.product && (
                    <>
                      <Tippy
                        render={(attrs) => (
                          <div className="product-card" {...attrs}>
                            <a href="/" className="product-card__header">
                              <div className="product-card__logo">
                                {video.product.logoImg !== "" ? (
                                  <img
                                    src={video.product.logoImg}
                                    className="user-avatar__img"
                                    alt=""
                                  />
                                ) : (
                                  <Avatar
                                    variant="square"
                                    className="user-avatar__img"
                                  >
                                    {letterLogo}
                                  </Avatar>
                                )}
                              </div>
                              <div className="product-card__names">
                                <div className="product-card__full-name">
                                  {video.product.name}
                                </div>
                                <div className="product-card__product-pitch">
                                  {video.product.description}
                                </div>
                              </div>
                            </a>
                          </div>
                        )}
                        animation={true}
                        onMount={onMount}
                        onHide={onHide}
                        placement={"bottom-start"}
                        // interactive={true}
                      >
                        <span
                          className="product-hashtag"
                          style={{ color: "inherit" }}
                        >
                          #{video.product.id}
                        </span>
                      </Tippy>

                      <span> </span>
                    </>
                  )}
                  {video.title.length <= 60
                    ? video.title
                    : video.title.substr(0, 60) + " ..."}
                </h4>
              </a>
              <h5>{video.user.displayName}</h5>
              <h5>
                {/* {video.viewCount} views .{" "} */}
                {formatDistance(video.createdAt, Date.now())} ago
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVideoCard;
