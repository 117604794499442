import React from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";

class NoNewVideoAccessModal extends React.Component {
  handleModalClose = () => {
    this.props.setUserValue({ showNoNewVideoAccessModal: false });
  };

  goToProfilePage = () => {
    this.props.history.push("/profile");
  };

  render() {
    const { showNoNewVideoAccessModal } = this.props.user;

    return (
      <Modal show={showNoNewVideoAccessModal} onHide={this.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>You don't have creator's access.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            To gain creator's access, please go to profile page to complete your
            profile. We only need some basic information:
          </p>
          <ListGroup>
            <ListGroup.Item variant="info">1. Your name</ListGroup.Item>
            <ListGroup.Item variant="info">2. Where you live</ListGroup.Item>
            <ListGroup.Item variant="info">3. Your bio</ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-5" onClick={this.goToProfilePage}>
            Go to profile
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NoNewVideoAccessModal);
