import React from "react";
import Linkify from "linkifyjs/react";
import { useHistory } from "react-router-dom";
import "./AMA.css";

const AmaUserCard = props => {
  const { video } = props;
  const history = useHistory();

  const onShowDetailedAMA = video => {
    let path = "/ama/" + video.titleURL;
    history.push(path);
  };

  const onCheckProfile = user => {
    let path = "/user/" + user.userName || user.uid;
    history.push(path);
  };

  return (
    <div className="col-xxxl-2 col-xxl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-3 pr-0">
      <div className="ama-user-card">
        <div className="profileThumb text-center">
          <img
            src={video.user.avatar}
            className="avatar img-circle img-thumbnail mt-0"
            alt="avatar"
            onClick={() => onShowDetailedAMA(video)}
          />
          <span className="ama-status">• Ongoing</span>
        </div>
        <div
          className="detailsText text-center"
          onClick={() => onCheckProfile(video.user)}
          style={{ cursor: "pointer" }}
        >
          <h3>{video.user.displayName}</h3>
        </div>
        <div className="pt-2 text-center">
          <Linkify>{video.user.bio}</Linkify>
        </div>
        <div className="pt-3 text-center">
          <button
            onClick={() => onShowDetailedAMA(video)}
            className="btn btn-danger ama-user"
            style={{ fontSize: "15px" }}
          >
            Ask
          </button>
        </div>
      </div>
    </div>
  );
};

export default AmaUserCard;
