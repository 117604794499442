import React from "react";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";

class NextVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadedVideos: [],
    };
  }

  componentDidMount = async () => {
    const { video } = this.props;
    var arrayOfVideos = [];

    const allVideos = await firebase
      .database()
      .ref("videos")
      .orderByChild("createdAt")
      .endAt(video.createdAt)
      .limitToLast(7)
      .once("value");

    allVideos &&
      allVideos.forEach((eachVideo) => {
        if (eachVideo.val().createdAt !== video.createdAt) {
          arrayOfVideos.push(eachVideo.val());
        }
      });

    this.setState({
      loadedVideos: arrayOfVideos.reverse(),
      isLoading: false,
    });
  };

  render() {
    const { loadedVideos } = this.state;
    const that = this;
    return (
      <div>
        <h5 className="sidebar-header">Next videos</h5>
        {loadedVideos.length > 0 &&
          loadedVideos.map((video) => {
            const videoThumbnail =
              video.moreData.aspect_ratio === "3:4" ||
              video.moreData.aspect_ratio === "9:16"
                ? (video.thumbnail &&
                    video.thumbnail + "&width=400&height=228&fit_mode=pad") ||
                  "https://image.mux.com/" +
                    video.playbackIds[0].id +
                    "/thumbnail.png?width=400&height=228&fit_mode=pad" // for vertical video
                : video.thumbnail &&
                  video.thumbnail ===
                    "https://image.mux.com/" +
                      video.playbackIds[0].id +
                      "/thumbnail.png"
                ? video.thumbnail + "?width=400"
                : video.thumbnail
                ? video.thumbnail + "&width=400"
                : "https://image.mux.com/" +
                  video.playbackIds[0].id +
                  "/thumbnail.png?width=400";

            const videoURL = video.titleURL
              ? video.titleURL
              : video.playbackIds[0].id;

            return (
              <div className="next-video-item p-0" key={videoURL}>
                <a href={"/video/" + videoURL}>
                  <img className="thumbnail" src={videoThumbnail} />
                </a>
                <div className="infos">
                  <a className="next-video-title" href={"/video/" + videoURL}>
                    {video.title.substring(0, 30) + "..."}
                  </a>
                  <a
                    className="next-video-user"
                    href={
                      "/user/" +
                      (video.user.userName !== ""
                        ? video.user.userName
                        : video.user.uid)
                    }
                  >
                    {video.user.displayName}
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default withRouter(NextVideos);
