import React from "react";
import { Header, Segment, Feed, Sticky } from "semantic-ui-react";
import RecentActivityItem from "./RecentActivityItem";

const RecentActivity = ({ activities, contextRef }) => {
  const reversedActivities =
    (activities &&
      Object.values(activities).length > 0 &&
      Object.values(activities).reverse()) ||
    [];
  return (
    <Sticky context={contextRef} offset={100}>
      <Header attached="top" content="Recent activities" />
      <Segment attached style={{ overflow: "auto", maxHeight: "30vh" }}>
        <Feed>
          {reversedActivities.map((activity) => (
            <RecentActivityItem key={activity.createdAt} activity={activity} />
          ))}
        </Feed>
      </Segment>
    </Sticky>
  );
};

export default RecentActivity;
