/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import Linkify from "linkifyjs/react";
import "react-calendar-heatmap/dist/styles.css";
import { setUserValue } from "../../redux/action/userAction";
import VideoPlayer from "./VideoPlayer";

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  return Array.from({ length: count }, (_, i) => i);
}

function getName(name) {
  return name.split(" ").slice(0, 2).join("+");
}

const today = new Date();

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFollowers: false,
      showFollowing: false,
      uploadHistory: getRange(365).map((index) => {
        return {
          date: shiftDate(today, -index),
          count: 0,
        };
      }),
      showVideoPitch: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.videos !== prevProps.videos) {
      const { uploadHistory } = this.state;
      var tmpUploadHistory = uploadHistory;

      // update uploadHistory array
      this.props.videos.map((video) => {
        const daysFromNow =
          Math.floor(
            (Date.now() - today.getTimezoneOffset() * 60000) / 86400000
          ) -
          Math.floor(
            (video.createdAt - today.getTimezoneOffset() * 60000) / 86400000
          );
        if (daysFromNow <= 365) {
          tmpUploadHistory[daysFromNow] = {
            date: shiftDate(today, -daysFromNow),
            count: 1,
          };
        }
      });

      this.setState({ uploadHistory: tmpUploadHistory });
    }
  };

  onShowProductsList = () => {
    this.props.onShowProducts();
  };

  onShowAllVideosList = () => {
    this.props.onShowAllVideos();
  };

  onShowFollowers = () => {
    this.setState({ showFollowers: true });
  };

  onShowFollowing = () => {
    this.setState({ showFollowing: true });
  };

  handleModalClose = () => {
    this.setState({ showFollowers: false, showFollowing: false });
  };

  onShowPitchVideo = () => {
    this.setState({ showVideoPitch: true });
  };

  onShowLogo = () => {
    this.setState({ showVideoPitch: false });
  };

  onShowRecordPitchVideoModal = (product) => () => {
    const { currentUser } = this.props.user;
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({
        showAddNewVideoModal: true,
        product: product,
      });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };
  render() {
    const { product, user } = this.props;
    const { currentUser } = user;
    const { showVideoPitch } = this.state;

    if (product === null) {
      return null;
    }

    const letterLogo =
      "https://ui-avatars.com/api/?name=" +
      getName(product.name) +
      "&background=ffffff&color=222831&bold=true&size=256)";

    const pitchVideoSrc = product.hasPitchVideo
      ? "https://stream.mux.com/" +
        product.pitchVideo.playbackIds[0].id +
        ".m3u8"
      : "";

    const pitchVideoPoster = product.hasPitchVideo
      ? "https://image.mux.com/" +
        product.pitchVideo.playbackIds[0].id +
        "/thumbnail.png"
      : "";

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: true,
      controls: true,
      fluid: true,
      // aspectRatio: "16:9"
    };

    return (
      <section className="jumbotron profileCta">
        <div className="ProfileSection mt-4">
          <div className="profileThumb text-center">
            {!showVideoPitch ? (
              <img
                src={product.logoImg !== "" ? product.logoImg : letterLogo}
                className="avatar img-circle img-thumbnail mx-auto"
                alt="avatar"
              />
            ) : (
              product.hasPitchVideo && (
                <VideoPlayer
                  {...videoJsOptions}
                  poster={pitchVideoPoster}
                  src={pitchVideoSrc}
                />
              )
            )}
          </div>
          <div className="profileThumb text-center mb-4">
            {!showVideoPitch ? (
              product.hasPitchVideo ? (
                <button
                  onClick={this.onShowPitchVideo}
                  className="btn btn-outline-light product-video-btn"
                >
                  <i className="far fa-play-circle"></i> Pitch
                </button>
              ) : (
                currentUser &&
                currentUser.uid === product.ownerId && (
                  <button
                    onClick={this.onShowRecordPitchVideoModal(product)}
                    className="btn btn-outline-light product-video-btn"
                  >
                    <i className="far fa-play-circle"></i> Record 30s Pitch
                    Video
                  </button>
                )
              )
            ) : (
              <button
                onClick={this.onShowLogo}
                className="btn btn-outline-light product-video-btn"
              >
                <i className="fas fa-camera"></i> Logo
              </button>
            )}
          </div>

          <div className="detailsText text-center">
            <h3 className="mb-2">{product.name}</h3>
            <Linkify>{product.description}</Linkify>
          </div>
          <div className="row pt-2">
            <div className="col-lg-2 col-md-2 text-center" />
            <div className="col-lg-8 col-md-8 text-center">
              {product.website && (
                <span className="mb-2">
                  <Linkify>{product.website}</Linkify>
                </span>
              )}

              {currentUser && currentUser.uid === product.ownerId && (
                <div className="pt-2">
                  <span
                    className="edit-product"
                    onClick={() =>
                      this.props.setUserValue({
                        showAddNewProductModal: true,
                      })
                    }
                  >
                    <i className="far fa-edit"></i> Edit
                  </span>
                  <span
                    className="delete-product"
                    onClick={() =>
                      this.props.setUserValue({
                        showDeleteProductModal: true,
                      })
                    }
                  >
                    <i className="fas fa-trash-alt"></i> Delete
                  </span>
                </div>
              )}
            </div>
            <div className="col-lg-2 col-md-2 text-center" />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductInfo);
