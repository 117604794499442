import React from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";

class RequestMeetupHostModal extends React.Component {
  handleModalClose = () => {
    this.props.setUserValue({ showRequestMeetupHostModal: false });
  };

  goToMyTwitter = () => {
    window.open("https://twitter.com/damengchen");
  };

  render() {
    const { showRequestMeetupHostModal } = this.props.user;

    return (
      <Modal show={showRequestMeetupHostModal} onHide={this.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request permission to become a host</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            In order to request the access to host a meetup on your own, please
            contact Damon from IndieLog 👇
          </p>
          <ListGroup>
            <ListGroup.Item variant="info">
              Email:{" "}
              <a href="mailto:damon@lonely.dev?subject=Request access to become a meetup host">
                damon@lonely.dev
              </a>
            </ListGroup.Item>
            <ListGroup.Item variant="info">
              Twitter:{" "}
              <a href="https://twitter.com/damengchen" target="_blank">
                @damengchen
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-5" onClick={this.goToMyTwitter}>
            Go to Twitter
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestMeetupHostModal);
