import React from "react";
import { Header, Segment, Feed } from "semantic-ui-react";
import ThisWeekProductsItem from "./ThisWeekProductsItem";
import "./ThisWeekProducts.css";

const ThisWeekProducts = ({ products, contextRef }) => {
  return (
    <div className="mt-4 mb-4">
      <Header attached="top" content="Products checked in this week" />
      <Segment attached>
        <Feed>
          {products.length > 0 ? (
            products.map((product) => (
              <ThisWeekProductsItem key={product.id} product={product} />
            ))
          ) : (
            <h5 className="text-muted" style={{ fontSize: "16px" }}>
              Be the first to update this week? 💪
            </h5>
          )}
        </Feed>
      </Segment>
    </div>
  );
};

export default ThisWeekProducts;
