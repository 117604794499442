import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import ChallengeBanner from "./ChallengeBanner";
import ChallengeList from "./ChallengeList";
import "./Challenge.css";

class IndieTuber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{"Challenges on IndieLog"}</title>
          <meta
            name="description"
            content={
              "Push yourself to make consistent progress. It's continuous progress makes your product different."
            }
          />
        </Helmet>
        <section className="bg-home">
          <ChallengeBanner />
          <Grid className="mx-0">
            <Grid.Column width={16}>
              <div ref={this.handleContextRef}>
                <ChallengeList />
              </div>
            </Grid.Column>
          </Grid>
        </section>
      </Fragment>
    );
  }
}

export default IndieTuber;
