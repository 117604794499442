import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { format } from "date-fns";
import RecentActivity from "../../components/shared/RecentActivity/RecentActivity";
import NewMembersSidebar from "../../components/shared/NewMembersSidebar/NewMembersSidebar";
import ThisWeekProducts from "../../components/shared/ThisWeekProducts/ThisWeekProducts";
import HeatmapHistory from "../../components/shared/HeatmapHistory/HeatmapHistory";
import TodoApp from "../../components/shared/TodoApp/TodoApp";
import SingleVideoCard from "../../components/shared/SingleVideoCard";
import PulseLoader from "react-spinners/PulseLoader";

class HomeVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { weeklySubmissions: 0 };
  }

  componentDidMount = async () => {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay() + 1; //first day of the week
    var firstDayOfWeek = format(new Date(curr.setDate(first)), "PP");

    const weeklyRef = await firebase
      .database()
      .ref("weekly-topics")
      .child("have-you-tried-talking-to-your-customers")
      .once("value");

    this.setState({
      weeklySubmissions:
        weeklyRef && weeklyRef.val() ? weeklyRef.val().meta.count : 0,
    });
  };

  render() {
    const {
      videos,
      activities,
      newMembers,
      products,
      contextRef,
      isLoading,
      loading,
      hasMore,
      getNextVideos,
      user,
    } = this.props;

    const { weeklySubmissions } = this.state;

    if (isLoading) {
      return (
        <div className="text-center" style={{ marginTop: 100 }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
        </div>
      );
    }

    return (
      <div className="row container-fluid mr-0 pr-0">
        <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-6">
          <div className="row mt-4">
            {videos && Object.values(videos).length > 0 && (
              <>
                {/* <UpcomingWeeklyTopicCard /> */}
                {/* <WeeklyTopicCard
                  weeklySubmissions={weeklySubmissions}
                  currentWeek={true}
                  homePage={true}
                  week="have-you-tried-talking-to-your-customers"
                /> */}
                {Object.values(videos).map(
                  (video) =>
                    video.status === "video.asset.ready" && (
                      <SingleVideoCard
                        key={video.playbackIds[0].id}
                        video={video}
                        profileView={false}
                        homePage={true}
                      />
                    )
                )}
              </>
            )}
            {!isLoading && !loading && hasMore && (
              <div className="col text-center" style={{ marginTop: 20 }}>
                <button
                  onClick={getNextVideos}
                  className="btn btn-danger px-5 py-2"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 d-none d-sm-none d-md-block">
          {user && <TodoApp user={user} />}
          <NewMembersSidebar newMembers={newMembers} user={user} />
          <ThisWeekProducts products={products} />
          <RecentActivity activities={activities} contextRef={contextRef} />
        </div>
      </div>
    );
  }
}

export default HomeVideos;
