import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import IndieTuberCard from "./IndieTuberCard";

class IndieTuberList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { tubers, isLoading } = this.props;

    if (isLoading || tubers === null) {
      return (
        <div className="text-center" style={{ marginTop: 100 }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
        </div>
      );
    }
    return (
      <div className="row container-fluid">
        <div className="col-12">
          <div className="row mt-4">
            {tubers &&
              Object.values(tubers).length > 0 &&
              Object.values(tubers).map(tuber => (
                <IndieTuberCard key={tuber.channelId} tuber={tuber} />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default IndieTuberList;
