import React, { Component } from "react";
import { Comment, Dropdown } from "semantic-ui-react";
import AddCommentForm from "./AddCommentForm";
import { Link } from "react-router-dom";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "antd";
import firebase from "firebase/app";
import "firebase/database";
import { formatDistance } from "date-fns";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import Linkify from "linkifyjs/react";
import { toast } from "react-toastify";
import { Modal, ListGroup, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import VideoPlayer from "./VideoPlayer";
import { setUserValue } from "../../../redux/action/userAction";
import CodeBlock from "../../../utils/CodeBlock";
import LinkRenderer from "../../../utils/LinkRenderer";

class RenderComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
      selectedCommentId: null,
      showVideoModal: false,
      showVideoId: "",
      showReportVideoModal: false,
      reportReason: "",
      reportComment: null,
      isEditComment: false,
    };
  }

  handleCloseReplyForm = () => {
    this.setState({
      selectedCommentId: null,
      showReplyForm: false,
      isEditComment: false,
    });
    this.props.setUserValue({ showAddNewVideoModal: false });
  };

  handleOpenReplyForm = (id) => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id,
      });
    }
  };

  handleOpenEditReplyForm = (id) => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id,
        isEditComment: true,
      });
    }
  };

  onShowReplyInVideoModal = (meetupId, commentParentId) => () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({
        showAddNewVideoModal: true,
        meetupId: meetupId,
        commentParentId: commentParentId,
      });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  addMeetupComment = async (meetupId, comment, parentId) => {
    const { currentUser } = this.props;
    if (!currentUser) {
      toast.error("Sorry, You have to log in first. :(", {
        autoClose: 4000,
      });
      return alert("You have to log in first.");
    }
    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    let newComment = currentUser.userName
      ? {
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          userName: currentUser.userName,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
          type: "TEXT",
        }
      : {
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
          type: "TEXT",
        };

    try {
      await firebase
        .database()
        .ref("meetup-comments")
        .child(meetupId)
        .push(newComment);

      await firebase
        .database()
        .ref("activities")
        .child(currentUser.uid)
        .push({
          type: "New Comment",
          content: {
            meetupId: meetupId,
            parentId: parentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser || "",
            comment: comment,
          },
          createdAt: Date.now(),
        });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "addMeetupComment in RenderComments",
        error: error,
      });
    }
  };

  editMeetupComment = async (meetupId, comment, parentId) => {
    const { currentUser } = this.props;
    if (!currentUser) {
      toast.error("Sorry, You have to log in first. :(", {
        autoClose: 4000,
      });
      return alert("You have to log in first.");
    }
    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    try {
      await firebase
        .database()
        .ref("meetup-comments")
        .child(meetupId)
        .child(parentId)
        .update({ comment: comment, updatedAt: Date.now() });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "editMeetupComment in RenderComments",
        error: error,
      });
    }
  };

  onClickVideoReply = (videoPlaybackId) => () => {
    this.setState({ showVideoModal: true, showVideoId: videoPlaybackId });
  };

  handleModalClose = () => {
    this.setState({ showVideoModal: false, showReportVideoModal: false });
  };

  reportVideo = (comment) => () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    this.setState({ showReportVideoModal: true, reportComment: comment });
  };

  sendReport = async () => {
    const { reportComment, reportReason } = this.state;
    const { meetupId, currentUser } = this.props;

    //add the report to /report/{reportId}
    await firebase
      .database()
      .ref("comment-reports")
      .child(meetupId)
      .child(reportComment.id)
      .set({
        comment: reportComment,
        commentVideoURL:
          "https://stream.mux.com/" + reportComment.playbackIds[0].id + ".m3u8",
        reason: reportReason,
        judge: "pending",
        reportedBy: currentUser,
      });
    //update the report status in comment's object
    await firebase
      .database()
      .ref("comments")
      .child(meetupId)
      .child(reportComment.id)
      .update({ badComment: "pending" });

    toast.success("Thanks for reporting! We will take a look soon.", {
      autoClose: 4000,
    });
    this.setState({ showReportVideoModal: false });
  };

  onLike = (comment) => async () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    await firebase
      .database()
      .ref("comments")
      .child(this.props.meetupId)
      .child(comment.id)
      .child("likedBy")
      .child(currentUser.uid)
      .set({
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        userName: currentUser.userName || "",
        userId: currentUser.uid,
      });
  };

  onUnlike = (comment) => async () => {
    const { currentUser } = this.props;

    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    await firebase
      .database()
      .ref("comments")
      .child(this.props.meetupId)
      .child(comment.id)
      .child("likedBy")
      .child(currentUser.uid)
      .remove();
  };

  goToUserProfile = (user) => () => {
    console.log("goto ");
    this.props.history.push(
      "/user/" + (user.userName ? user.userName : user.userId)
    );
  };

  render() {
    const { meetupId, comment, currentUser, setUserValue } = this.props;
    const {
      showVideoModal,
      showVideoId,
      showReplyForm,
      selectedCommentId,
      showReportVideoModal,
      isEditComment,
    } = this.state;
    var nestedComments;
    if (comment && comment.childNodes && comment.childNodes.length > 0) {
      nestedComments = (
        <Comment.Group threaded>
          {(comment.childNodes || []).map((comment, index) => {
            return (
              <RenderComments
                key={index}
                comment={comment}
                currentUser={currentUser}
                setUserValue={setUserValue}
                meetupId={meetupId}
                history={this.props.history}
              />
            );
          })}
        </Comment.Group>
      );
    } else {
      nestedComments = null;
    }

    return (
      <Comment key={comment.id}>
        <Comment.Avatar
          src={comment.photoURL || "/assets/user.png"}
          as={Link}
          to={
            comment.userName
              ? `/user/${comment.userName}`
              : `/user/${comment.uid}`
          }
        />
        {!isEditComment ? (
          <Comment.Content>
            <Comment.Author
              as={Link}
              to={
                comment.userName
                  ? `/user/${comment.userName}`
                  : `/user/${comment.uid}`
              }
            >
              {comment.displayName}
            </Comment.Author>
            <Comment.Metadata>
              <div>{formatDistance(comment.date, Date.now())} ago</div>
            </Comment.Metadata>
            <Comment.Text>
              <Linkify style={{ whiteSpace: "per-line" }}>
                <ReactMarkdown
                  source={comment.comment}
                  renderers={{ code: CodeBlock, link: LinkRenderer }}
                />
              </Linkify>
            </Comment.Text>
            <Comment.Actions>
              <Comment.Action onClick={this.handleOpenReplyForm(comment.id)}>
                Reply
              </Comment.Action>
              {currentUser && comment && currentUser.uid === comment.uid && (
                <Comment.Action
                  onClick={this.handleOpenEditReplyForm(comment.id)}
                  className="mb-1"
                >
                  <i className="far fa-edit"></i>
                </Comment.Action>
              )}

              {showReplyForm && selectedCommentId === comment.id && (
                <AddCommentForm
                  addMeetupComment={this.addMeetupComment}
                  meetupId={meetupId}
                  closeForm={this.handleCloseReplyForm}
                  parentId={comment.id}
                  allowCancel={true}
                  parentComment={false}
                  isEditComment={isEditComment}
                  comment={comment}
                />
              )}
            </Comment.Actions>
          </Comment.Content>
        ) : (
          <Comment.Content>
            <Comment.Author
              as={Link}
              to={
                comment.userName
                  ? `/user/${comment.userName}`
                  : `/user/${comment.uid}`
              }
            >
              {comment.displayName}
            </Comment.Author>
            <Comment.Metadata>
              <div>{formatDistance(comment.date, Date.now())} ago</div>
            </Comment.Metadata>
            {showReplyForm && comment && selectedCommentId === comment.id && (
              <AddCommentForm
                editMeetupComment={this.editMeetupComment}
                meetupId={meetupId}
                closeForm={this.handleCloseReplyForm}
                parentId={comment.id}
                allowCancel={true}
                parentComment={false}
                isEditComment={isEditComment}
                comment={comment}
              />
            )}
          </Comment.Content>
        )}

        {nestedComments}
      </Comment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RenderComments);
