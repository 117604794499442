import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { getVideosForWeekly } from "../../redux/action/videoAction";
import { setUserValue } from "../../redux/action/userAction";
import WeeklyBanner from "./WeeklyBanner";
import WeeklyVideos from "./WeeklyVideos";
import "./Weekly.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hasWeekId: false,
      loadedVideos: [],
      contextRef: {},
      hasMore: false,
    };
  }

  componentDidMount = async () => {
    var weekId = this.props.match.params.weekId;

    this.setState({ hasWeekId: weekId ? true : false });

    let videos = await this.props.getVideosForWeekly(
      "",
      weekId ? weekId : "have-you-tried-talking-to-your-customers"
    );
    if (videos && videos.length === 18) {
      this.setState({ hasMore: true });
    } else {
      this.setState({ hasMore: false });
    }
    this.setState({ isLoading: false, weekId: weekId });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.videos !== prevProps.videos) {
      this.setState({
        loadedVideos: [
          ...this.state.loadedVideos,
          ...this.props.videos.arrayOfWeeklyVideos,
        ],
      });
    }
  };

  getNextVideos = async () => {
    const { currentUser } = this.props.user;
    const { referenceToWeeklyOldestKey } = this.props.videos;
    const { weekId } = this.state;

    //ask user to login/signup to trigger load more
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    let videos = await this.props.getVideosForWeekly(
      referenceToWeeklyOldestKey,
      weekId ? "have-you-tried-talking-to-your-customers" : "Apr 20, 2020"
    );

    if (videos && videos.length < 18) {
      this.setState({ hasMore: false });
    }
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const { loading } = this.props;
    const { loadedVideos, hasMore, isLoading, weekId, hasWeekId } = this.state;

    return (
      <Fragment>
        <section className="bg-home">
          {hasWeekId && <WeeklyBanner weekId={weekId} />}
          <Grid className="mx-0">
            <Grid.Column width={16}>
              <div ref={this.handleContextRef}>
                <WeeklyVideos
                  loading={loading}
                  isLoading={isLoading}
                  getNextVideos={this.getNextVideos}
                  videos={loadedVideos}
                  hasMore={hasMore}
                  weekId={weekId}
                  hasWeekId={hasWeekId}
                />
              </div>
            </Grid.Column>
            {!isLoading && !loading && hasMore && (
              <Grid.Column width={16}>
                <div className="text-center" style={{ marginTop: 20 }}>
                  <button
                    onClick={this.getNextVideos}
                    className="btn btn-danger px-5 py-2"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    Load More
                  </button>
                </div>
              </Grid.Column>
            )}
          </Grid>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state && state.user,
  videos: state && state.videos,
  loading: state && state.async.loading,
});

const mapDispatchToProps = {
  getVideosForWeekly,
  setUserValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
