import React from "react";
import { Header, Segment, Feed } from "semantic-ui-react";
import WeeklyTopProductsItem from "./WeeklyTopProductsItem";
import "./WeeklyTopProducts.css";

const WeeklyTopProducts = ({ products, contextRef }) => {
  return (
    <div className="mt-4 mb-4">
      <Header attached="top" content="Weekly Top 10 🎉" />
      <Segment attached>
        <Feed>
          {products.length > 0 ? (
            products.map((product) => (
              <WeeklyTopProductsItem key={product.id} product={product} />
            ))
          ) : (
            <h5 className="text-muted" style={{ fontSize: "16px" }}>
              Be the first to update this week? 💪
            </h5>
          )}
        </Feed>
      </Segment>
    </div>
  );
};

export default WeeklyTopProducts;
