import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { EditVideoModal } from "./EditVideoModal";
import { setUserValue } from "../../../redux/action/userAction";
import "./EditVideo.css";

class EditVideo extends React.Component {
  handleModalClose = () => {
    this.props.setUserValue({ showEditVideoModal: false });
  };

  render() {
    const { showEditVideoModal } = this.props.user;

    return (
      <Modal
        show={showEditVideoModal}
        onHide={this.handleModalClose}
        backdrop="static"
        size="lg"
      >
        <EditVideoModal
          currentUser={this.props.user.currentUser}
          video={this.props.video}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state && state.user
  };
};

const mapDispatchToProps = dispatch => ({
  setUserValue: state => {
    return dispatch(setUserValue(state));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditVideo);
