import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { format } from "date-fns";
import SingleVideoCard from "../../components/shared/SingleVideoCard";
import PulseLoader from "react-spinners/PulseLoader";
import WeeklyTopicCard from "../../components/shared/WeeklyTopicCard";

class WeeklyVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { weeklyKeyArray: null };
  }

  componentDidMount = async () => {
    const weeklyRef = await firebase
      .database()
      .ref("weekly-topics")
      .orderByChild("createdAt")
      .once("value");

    var weekDataArray = [];
    // var curr = new Date(); // get current date
    // var first = curr.getDate() - curr.getDay() + 1; //first day of the week
    // var firstDayOfWeek = format(new Date(curr.setDate(first)), "PP");
    weeklyRef.forEach((week) => {
      weekDataArray.push(week.val().meta);
    });

    this.setState({
      weeklyKeyArray: weekDataArray.reverse(),
    });
  };

  render() {
    const { videos, isLoading, hasWeekId } = this.props;

    const { weeklyKeyArray } = this.state;

    if (isLoading || weeklyKeyArray === null) {
      return (
        <div className="text-center" style={{ marginTop: 100 }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
        </div>
      );
    }
    return (
      <div className="row container-fluid">
        <div className="col-12">
          <div className="row mt-4">
            {hasWeekId &&
              videos &&
              Object.values(videos).length > 0 &&
              Object.values(videos).map(
                (video) =>
                  video.status === "video.asset.ready" && (
                    <SingleVideoCard
                      key={video.playbackIds[0].id}
                      video={video}
                      profileView={false}
                    />
                  )
              )}
            {/* return all past weeks cards */}
            {!hasWeekId &&
              weeklyKeyArray &&
              weeklyKeyArray.length > 0 &&
              weeklyKeyArray.map((week) => (
                <WeeklyTopicCard
                  count={week.count}
                  topic={week.topic}
                  week={week.id}
                  backgroundImg={week.backgroundImg}
                  key={week.topic}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default WeeklyVideos;
