import { SET_USER_VALUE } from "../constant";

const initialState = {
  currentUser: null,
  referenceToOldestKey: "",
  showSignInModal: false,
  showSignOutModal: false,
  showAddNewVideoModal: false,
  showAddYoutubeChannelModal: false,
  isAMA: false,
  AmaQuestion: false,
  AmaAnswer: false,
  showNoNewVideoAccessModal: false,
  showNoAddYoutubeAccessModal: false,
  showEditVideoModal: false,
  showAddNewProductModal: false,
  showAddNewMeetupModal: false,
  showRequestMeetupHostModal: false,
  videoId: "",
  commentParentId: "",
  weeklyTopicId: "",
};

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_VALUE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
