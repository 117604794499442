import React, { Component } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";

class EditDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: (this.props.video.product && this.props.video.product.id) || "",
      isProcessing: false,
      thumbnailSelected: null
    };
  }
  componentDidMount() {
    this.props.modalHeader("Edit Video");
  }

  componentDidUpdate = prevProps => {
    if (this.props.isProcessing !== prevProps.isProcessing) {
      this.props.modalHeader("Processing");
    }
  };

  updateThumbnail = () => {
    // console.log("update thumbnail");
  };

  handleSelectProduct = event => {
    const { selectProduct } = this.props;
    selectProduct(event.target.value);
    this.setState({ product: event.target.value });
  };

  render() {
    const {
      video,
      user,
      handleChange,
      isProcessing,
      selectThumbnail
    } = this.props;

    const { thumbnailSelected, product } = this.state;

    const duration = video.duration;
    const thumbnail1 =
      "https://image.mux.com/" + video.playbackIds[0].id + "/thumbnail.png";
    const thumbnail2 =
      "https://image.mux.com/" +
      video.playbackIds[0].id +
      "/thumbnail.png?time=" +
      duration / 3;
    const thumbnail3 =
      "https://image.mux.com/" +
      video.playbackIds[0].id +
      "/thumbnail.png?time=" +
      (duration / 3) * 2;
    const thumbnail4 =
      "https://image.mux.com/" +
      video.playbackIds[0].id +
      "/thumbnail.png?time=" +
      duration;

    const products = user.currentUser.products || [];

    return (
      <div className="uploadForm">
        <div className="uploadMainContent">
          <div className="tabbedStepForm">
            <div className="first_step">
              <div className="row">
                <div className="col-md-12 order-md-1 order-sm-2 order-xs-2">
                  <h5>Details</h5>
                  <form>
                    <div className="form-group pt-0 mb-0">
                      <label htmlFor="exampleInputEmail1">
                        Title (required)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add a title"
                        onChange={handleChange("title")}
                        defaultValue={video.title}
                        disabled={isProcessing ? true : false}
                      />
                    </div>
                    <div className="form-group  mb-0">
                      <label htmlFor="exampleInputPassword1">
                        Descriptions
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Tell us about your video"
                        onChange={handleChange("description")}
                        defaultValue={video.description}
                        rows="4"
                        disabled={isProcessing ? true : false}
                      ></textarea>
                    </div>
                    <Form.Group controlId="formGridState">
                      <Form.Label>Product</Form.Label>
                      <Form.Control
                        as="select"
                        value={product}
                        onChange={this.handleSelectProduct}
                      >
                        <option value="">Choose...</option>
                        {Object.keys(products).length > 0 &&
                          Object.keys(products).map(product => {
                            return <option key={product}>{product}</option>;
                          })}
                      </Form.Control>
                    </Form.Group>
                  </form>
                  <h5>Thumbnails</h5>
                  <p className="addionalText pt-2">
                    Default thumbnail is a frame selected from the middle of the
                    video (this default may change at any time). If you happened
                    to close your eyes, or you don't like it, you can select
                    another one.
                  </p>
                  <div className="row videoThumb">
                    <div className="col-3">
                      <div
                        className={
                          (!thumbnailSelected &&
                            (!video.thumbnail ||
                              video.thumbnail === thumbnail1)) ||
                          thumbnailSelected === 1
                            ? "video_thumbnail_selected"
                            : "video_thumbnail"
                        }
                      >
                        <img
                          src={thumbnail1}
                          className="img-fluid"
                          alt=""
                          onClick={() => {
                            this.setState({ thumbnailSelected: 1 });
                            selectThumbnail(thumbnail1);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className={
                          (!thumbnailSelected &&
                            video.thumbnail === thumbnail2) ||
                          thumbnailSelected === 2
                            ? "video_thumbnail_selected"
                            : "video_thumbnail"
                        }
                      >
                        <img
                          src={thumbnail2}
                          className="img-fluid"
                          alt=""
                          onClick={() => {
                            this.setState({ thumbnailSelected: 2 });
                            selectThumbnail(thumbnail2);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className={
                          (!thumbnailSelected &&
                            video.thumbnail === thumbnail3) ||
                          thumbnailSelected === 3
                            ? "video_thumbnail_selected"
                            : "video_thumbnail"
                        }
                      >
                        <img
                          src={thumbnail3}
                          className="img-fluid"
                          alt=""
                          onClick={() => {
                            this.setState({ thumbnailSelected: 3 });
                            selectThumbnail(thumbnail3);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className={
                          (!thumbnailSelected &&
                            video.thumbnail === thumbnail4) ||
                          thumbnailSelected === 4
                            ? "video_thumbnail_selected"
                            : "video_thumbnail"
                        }
                      >
                        <img
                          src={thumbnail4}
                          className="img-fluid"
                          alt=""
                          onClick={() => {
                            this.setState({ thumbnailSelected: 4 });
                            selectThumbnail(thumbnail4);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nextPrevBtn mt-3">
              <button
                onClick={this.props.onDoneEditing}
                className="good btn btn-danger my-1"
                disabled={isProcessing ? true : false}
              >
                {isProcessing ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Done Editing"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state && state.user
  };
};

export default connect(mapStateToProps)(EditDetails);
