import React from "react";
import { withRouter } from "react-router";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "antd";
import { format, parseISO } from "date-fns";
import "./MeetupItem.css";

class MeetupItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { meetup, currentUser } = this.props;

    if (!meetup) return null;

    const {
      id,
      title,
      shortDescription,
      meetingTime,
      slots,
      attendees,
    } = meetup;

    var dateISO = parseISO(meetingTime);
    var month = format(dateISO, "MMM");
    var day = format(dateISO, "dd");
    var readableTime = format(dateISO, "MMMM d, yyyy h:mm aa");

    return (
      <div
        className="meetup-item"
        style={{
          backgroundColor: "#f9fcfb",
        }}
      >
        <div className="meetup-date">
          <span className="meetup-date-month">{month}</span>
          <span className="meetup-date-day">{day}</span>
        </div>

        <div
          className="infos"
          onClick={() => this.props.history.push("/meetups/" + id)}
        >
          <p className="meetup-time">{readableTime}</p>
          <p className="meetup-name">{title}</p>
          <p className="meetup-description">{shortDescription}</p>
          <AvatarGroup max={11}>
            {attendees &&
              Object.values(attendees).map((attendee, index) => {
                return (
                  <Tooltip title={attendee.name} placement="bottom" key={index}>
                    <Avatar
                      alt={attendee.name}
                      src={attendee.avatar}
                      className="meetup-attendee"
                      // onClick={this.goToUserProfile(attendee)}
                    />
                  </Tooltip>
                );
              })}
          </AvatarGroup>
        </div>

        {attendees && currentUser && attendees[currentUser.uid] && (
          <button
            // onClick={this.onShowAddNewProductModal}
            className="items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-400 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out ml-3"
          >
            🥳 You joined
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(MeetupItem);
