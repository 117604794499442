import {
  SET_VIDEO_VALUE,
  FETCH_VIDEOS,
  FETCH_WEEKLY_VIDEOS,
  FETCH_AMA_VIDEOS,
} from "../constant";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../action/asyncActions";
import firebase from "firebase/app";
import "firebase/database";
import { format } from "date-fns";

export const setVideoValue = (objValues) => ({
  type: SET_VIDEO_VALUE,
  payload: objValues,
});

export const getVideos = (lastVideo, limitNumber) => async (
  dispatch,
  getState
) => {
  let arrayOfVideos = [];
  let arrayOfKeys = [];
  let limit = limitNumber ? limitNumber : 36;
  try {
    dispatch(asyncActionStart());
    lastVideo === ""
      ? await firebase
          .database()
          .ref("videos")
          .orderByChild("createdAt")
          .limitToLast(limit)
          .once("value", (snapshot) => {
            snapshot.forEach((video) => {
              arrayOfVideos.push(video.val());
              arrayOfKeys.push(video.val().createdAt);
            });
          })
      : await firebase
          .database()
          .ref("videos")
          .orderByChild("createdAt")
          .endAt(lastVideo)
          .limitToLast(limit + 1)
          .once("value", (snapshot) => {
            snapshot.forEach((video) => {
              if (video.val().createdAt !== lastVideo) {
                arrayOfVideos.push(video.val());
                arrayOfKeys.push(video.val().createdAt);
              }
            });
          });

    if (arrayOfVideos.length === 0) {
      dispatch(asyncActionFinish());
      return arrayOfVideos;
    }
    dispatch({
      type: FETCH_VIDEOS,
      payload: {
        arrayOfVideos: arrayOfVideos.reverse(),
        referenceToOldestKey: arrayOfKeys[0],
      },
    });
    dispatch(asyncActionFinish());
    return arrayOfVideos;
  } catch (error) {
    await firebase.database().ref("errors").push({
      where: "getVideos",
      error: error,
    });
    dispatch(asyncActionError());
  }
};

export const getVideosForWeekly = (lastVideo, week) => async (
  dispatch,
  getState
) => {
  let arrayOfWeeklyVideos = [];
  let arrayOfKeys = [];
  var curr = new Date(); // get current date
  var first = curr.getDate() - curr.getDay() + 1; //first day of the week
  var firstDayOfWeek = format(new Date(curr.setDate(first)), "PP");
  try {
    dispatch(asyncActionStart());
    lastVideo === ""
      ? await firebase
          .database()
          .ref("weekly-topics")
          .child(week)
          .orderByChild("createdAt")
          .limitToLast(18)
          .once("value", (snapshot) => {
            snapshot.forEach((video) => {
              arrayOfWeeklyVideos.push(video.val());
              arrayOfKeys.push(video.val().createdAt);
            });
          })
      : await firebase
          .database()
          .ref("weekly-topics")
          .child(week)
          .orderByChild("createdAt")
          .endAt(lastVideo)
          .limitToLast(19)
          .once("value", (snapshot) => {
            snapshot.forEach((video) => {
              if (video.val().createdAt !== lastVideo) {
                arrayOfWeeklyVideos.push(video.val());
                arrayOfKeys.push(video.val().createdAt);
              }
            });
          });

    if (arrayOfWeeklyVideos.length === 0) {
      dispatch(asyncActionFinish());
      return arrayOfWeeklyVideos;
    }
    dispatch({
      type: FETCH_WEEKLY_VIDEOS,
      payload: {
        arrayOfWeeklyVideos: arrayOfWeeklyVideos.reverse(),
        referenceToWeeklyOldestKey: arrayOfKeys[0],
      },
    });
    dispatch(asyncActionFinish());
    return arrayOfWeeklyVideos;
  } catch (error) {
    await firebase.database().ref("errors").push({
      where: "getVideosForWeekly",
      error: error,
    });
    dispatch(asyncActionError());
  }
};

export const getVideosForAMA = (lastVideo) => async (dispatch, getState) => {
  let arrayOfVideos = [];
  let arrayOfKeys = [];
  try {
    dispatch(asyncActionStart());
    lastVideo === ""
      ? await firebase
          .database()
          .ref("AMAs")
          .orderByChild("createdAt")
          .limitToLast(18)
          .once("value", (snapshot) => {
            snapshot.forEach((video) => {
              arrayOfVideos.push(video.val());
              arrayOfKeys.push(video.val().createdAt);
            });
          })
      : await firebase
          .database()
          .ref("videos")
          .orderByChild("createdAt")
          .endAt(lastVideo)
          .limitToLast(19)
          .once("value", (snapshot) => {
            snapshot.forEach((video) => {
              if (video.val().createdAt !== lastVideo) {
                arrayOfVideos.push(video.val());
                arrayOfKeys.push(video.val().createdAt);
              }
            });
          });

    if (arrayOfVideos.length === 0) {
      dispatch(asyncActionFinish());
      return arrayOfVideos;
    }
    dispatch({
      type: FETCH_AMA_VIDEOS,
      payload: {
        arrayOfVideos: arrayOfVideos.reverse(),
        referenceToOldestKey: arrayOfKeys[0],
      },
    });
    dispatch(asyncActionFinish());
    return arrayOfVideos;
  } catch (error) {
    await firebase.database().ref("errors").push({
      where: "getVideos",
      error: error,
    });
    dispatch(asyncActionError());
  }
};
