import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import firebase from "firebase/app";
import "firebase/database";
import ChallengeCard from "./ChallengeCard";

var challengesListener;
class ChallengeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { challenges: [], isLoading: true };
  }

  componentDidMount = async () => {
    var challengeArray = [];
    challengesListener = await firebase
      .database()
      .ref("challenges")
      .orderByChild("id")
      .on("value", (snapshot) => {
        challengeArray = [];
        snapshot.forEach((challenge) => {
          challengeArray.push(challenge.val());
        });
        this.setState({ challenges: challengeArray, isLoading: false });
      });
  };

  componentWillUnmount() {
    firebase.database().ref("challenges").off("value", challengesListener);
  }

  render() {
    const { challenges, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="text-center" style={{ marginTop: 100 }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
        </div>
      );
    }

    return (
      <div className="row container-fluid">
        <div className="col-12">
          <div className="row mt-4">
            {challenges.map((challenge, i) => (
              <ChallengeCard key={i} challenge={challenge} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ChallengeList;
