import React from "react";
import { withRouter } from "react-router";
import "./WeeklyTopicCard.css";
import topic_img from "../../assets/images/weeklyCardBackground/talk-to-customers.png";

class WeeklyTopicCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      weeklySubmissions,
      currentWeek,
      count,
      topic,
      week,
      backgroundImg,
      homePage,
    } = this.props;

    return (
      <div
        className={
          homePage
            ? "col-xxxl-2 col-xxl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12  mb-3 pr-0"
            : "col-xxxl-2 col-xxl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12  mb-3 pr-0"
        }
      >
        <div className="card shadow-sm">
          {currentWeek ? (
            <a className="card-image" href={"/topics/" + week}>
              <img src={topic_img} className="img-fluid" alt="" />
              <div className="weekly_topic">
                <div>
                  <i className="far fa-clock mr-2 mb-3"></i>
                  New topic
                </div>
                <div style={{ alignSelf: "center" }}>
                  <span style={{ fontSize: "30px", lineHeight: "1.3" }}>
                    Have you tried talking to your customers?
                  </span>
                </div>
              </div>
            </a>
          ) : (
            <a className="card-image" href={"/topics/" + week}>
              <img src={backgroundImg} className="img-fluid" alt="" />
              <div className="weekly_topic">
                <div style={{ alignSelf: "center" }}>
                  <span style={{ fontSize: "35px", lineHeight: "1.3" }}>
                    {topic}
                  </span>
                </div>
              </div>
            </a>
          )}
          <div className="card-body row weekly-topic-meta">
            <div className="col-6" style={{ alignSelf: "center" }}>
              <h5 className="ml-3">
                {currentWeek
                  ? `${weeklySubmissions} submissions`
                  : `${count} submissions`}
              </h5>
            </div>
            <div className="col-6" style={{ alignSelf: "center" }}>
              <button
                onClick={() =>
                  currentWeek
                    ? this.props.history.push("/topics")
                    : this.props.history.push("/topics/" + week)
                }
                className="btn btn-outline-primary mr-3"
                style={{
                  float: "right",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                  paddingLeft: "2em",
                  paddingRight: "2em",
                }}
              >
                Attend
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WeeklyTopicCard);
