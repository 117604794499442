import React from "react";
import { TagInput } from "reactjs-tag-input";

const Keys = {
  TAB: 9,
  SPACE: 32,
  COMMA: 188,
};

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tags: [] };
    this.textInput = React.createRef();
  }

  onTagsChanged = (tags) => {
    this.setState({ tags });
    setTimeout(() => this.textInput.current.input.focus(), 100);
    this.props.selectedTags([...tags]);
  };

  componentDidMount = () => {
    setTimeout(() => this.textInput.current.input.focus(), 100);
  };

  render() {
    return (
      <TagInput
        type="text"
        ref={this.textInput}
        tags={this.state.tags}
        delimiters={`[ENTER, TAB]`}
        onTagsChanged={this.onTagsChanged}
        placeholder=""
        autoFocus
        wrapperStyle={`
        background: transparent;
        box-shadow: none;
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        width: 100%;
        border: 1px solid rgb(214, 216, 218);
        border-radius: 6px;
      `}
        inputStyle={`
      background: transparent;
        &::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 1rem;
        font-style: normal;
        color: #6c757d;
        }
      `}
        tagStyle={`
      background: #77B6EA;
      `}
      />
    );
  }
}

export default Input;
