import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { toast } from "react-toastify";

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    const notifications = this.props.currentUser.notifications;
    this.state = {
      notifyNewComment: notifications ? notifications.notifyNewComment : true,
      notifyNewFollower: notifications ? notifications.notifyNewFollower : true,
      notifyNewVideo: notifications ? notifications.notifyNewVideo : true,
    };
    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeText = (event) => {
    // console.log("change: ", [event.target.name], event.target.checked);
    this.setState({ [event.target.name]: event.target.checked });
  };

  onUpdateSettings = async () => {
    const { notifyNewComment, notifyNewFollower, notifyNewVideo } = this.state;
    await firebase
      .database()
      .ref("users")
      .child(this.props.currentUser.uid)
      .update({
        notifications: {
          notifyNewComment: notifyNewComment,
          notifyNewFollower: notifyNewFollower,
          notifyNewVideo: notifyNewVideo,
        },
      });
    toast.success("Your settings are saved.", {
      autoClose: 3000,
    });
  };

  render() {
    const { notifyNewComment, notifyNewFollower, notifyNewVideo } = this.state;
    return (
      <div className="col-sm-9">
        <div className="border">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-notification-tab"
                data-toggle="tab"
                href="#nav-notification"
                role="tab"
                aria-controls="nav-notification"
                aria-selected="true"
              >
                Send me Email when
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-notification"
              role="tabpanel"
              aria-labelledby="nav-notification-tab"
            >
              <div className="form" id="registrationForm">
                <div className="form-group">
                  <div className="row">
                    <div className="col-11">
                      <label className="input_label">
                        I receive new video comment
                      </label>
                    </div>
                    <div className="col-1">
                      <div className="custom-control custom-switch">
                        <input
                          name="notifyNewComment"
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          checked={notifyNewComment}
                          onChange={this.onChangeText}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch1"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-11">
                      <label className="input_label">
                        I receive new follower
                      </label>
                    </div>
                    <div className="col-1">
                      <div className="custom-control custom-switch">
                        <input
                          name="notifyNewFollower"
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch2"
                          checked={notifyNewFollower}
                          onChange={this.onChangeText}
                          value={notifyNewFollower}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch2"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-11">
                      <label className="input_label">
                        New videos from people I follow
                      </label>
                    </div>
                    <div className="col-1">
                      <div className="custom-control custom-switch">
                        <input
                          name="notifyNewVideo"
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch3"
                          checked={notifyNewVideo}
                          onChange={this.onChangeText}
                          value={notifyNewVideo}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch3"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group text-right border-less">
                  <div className="col-12">
                    <button
                      className="btn btn-lg btn-primary"
                      onClick={this.onUpdateSettings}
                    >
                      <i className="fas fa-save"></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserSettings;
