import React from "react";
import VideoProduct from "./VideoProduct";
import LeadTool from "./LeadTool";
import NextVideos from "./NextVideos";
import "./VideoRightSide.css";

class VideoRightSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { video } = this.props;
    return (
      <div className="mt-4">
        {video.product && <VideoProduct video={video} />}
        {video.product && video.product.lead !== "" && (
          <LeadTool video={video} />
        )}
        <NextVideos video={video} />
      </div>
    );
  }
}

export default VideoRightSide;
