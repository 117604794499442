import {
  SET_VIDEO_VALUE,
  FETCH_VIDEOS,
  FETCH_WEEKLY_VIDEOS,
  FETCH_AMA_VIDEOS
} from "../constant";

const initialState = {
  referenceToOldestKey: "",
  arrayOfVideos: [],
  referenceToWeeklyOldestKey: "",
  arrayOfWeeklyVideos: []
};

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VIDEO_VALUE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case FETCH_VIDEOS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case FETCH_WEEKLY_VIDEOS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case FETCH_AMA_VIDEOS: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}
