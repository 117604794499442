/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { withRouter } from "react-router";
import { Modal, Container, Row, Col } from "react-bootstrap";
import Linkify from "linkifyjs/react";
import { format } from "date-fns";
import { connect } from "react-redux";
import { compose } from "redux";
import firebase from "firebase/app";
import "firebase/database";
import Confetti from "react-confetti";
import { toast } from "react-toastify";
import { setUserValue } from "../../redux/action/userAction";

const today = new Date();

class ChallengeInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropConfetti: false,
      showConfirmQuit: false,
    };
  }

  onJoinChallenge = async () => {
    const { user, challenge } = this.props;
    const { currentUser } = user;
    await firebase
      .database()
      .ref("challenges")
      .child(challenge.url)
      .child("participants")
      .child(currentUser.uid)
      .set({
        goal: challenge.goal,
        streak: 0,
        updates: 0,
        avatar: currentUser.avatar,
        createdAt: Date.now(),
        name: currentUser.displayName,
        email: currentUser.email,
        uid: currentUser.uid,
        products: currentUser.products,
      });

    const challengeRef = await firebase
      .database()
      .ref("challenges")
      .child(challenge.url)
      .child("joined");
    challengeRef.transaction(function (count) {
      return (count || 0) + 1;
    });

    this.setState({ dropConfetti: true });

    await firebase
      .database()
      .ref("challenge-participants")
      .child(currentUser.uid)
      .child(challenge.url)
      .set({
        challenge: challenge.url,
        goal: challenge.goal,
        streak: 0,
        updates: 0,
        status: "active",
      });

    toast("You just joined the " + challenge.title + " challenge! 💪", {
      autoClose: 5000,
    });
  };

  onShowQuitModal = async () => {
    this.setState({ showConfirmQuit: true });
  };

  onQuitChallenge = async () => {
    const { currentUser } = this.props.user;
    await firebase
      .database()
      .ref("challenges")
      .child(this.props.challenge.url)
      .child("participants")
      .child(currentUser.uid)
      .remove();

    const challengeRef = await firebase
      .database()
      .ref("challenges")
      .child(this.props.challenge.url)
      .child("joined");
    challengeRef.transaction(function (count) {
      return count - 1;
    });

    await firebase
      .database()
      .ref("challenge-participants")
      .child(currentUser.uid)
      .child(this.props.challenge.url)
      .remove();

    this.handleModalClose();
  };

  handleModalClose = () => {
    this.setState({ showConfirmQuit: false });
  };

  render() {
    const { currentUser } = this.props.user;
    const { challenge } = this.props;
    const { dropConfetti, showConfirmQuit } = this.state;

    if (!currentUser) {
      return null;
    }

    return (
      <section className="jumbotron profileCta">
        {dropConfetti && (
          <Confetti style={{ position: "fixed" }} recycle={false} />
        )}
        <Modal show={showConfirmQuit} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to quit?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6 text-left">
                <button
                  className=" btn btn-danger my-1"
                  onClick={() => {
                    this.onQuitChallenge();
                  }}
                >
                  Quit now
                </button>
              </div>
              <div className="col-6 text-right">
                <button
                  className="btn btn-secondary my-1"
                  onClick={() => this.handleModalClose()}
                >
                  Go back
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Container>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <Row className="mt-4">
                <Col
                  md="5"
                  lg="2"
                  sm="6"
                  style={{ paddingRight: 0, paddingLeft: 0 }}
                >
                  <div className="profileThumb text-center">
                    <img
                      src={challenge.badge}
                      className="challenge-badge mt-0"
                      alt="avatar"
                    />
                  </div>
                </Col>
                <Col md="7" lg="10" sm="6">
                  <div
                    className="row detailsText text-left"
                    style={{ paddingLeft: "15px" }}
                  >
                    <h3 className="mr-4">{challenge.title}</h3>

                    {challenge.participants &&
                    challenge.participants[currentUser.uid] ? (
                      <button
                        onClick={() => this.onShowQuitModal()}
                        className="btn btn-outline-danger my-1"
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Challenge Joined
                      </button>
                    ) : (
                      <button
                        onClick={() => this.onJoinChallenge()}
                        className="btn btn-danger my-1"
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Join Challenge
                      </button>
                    )}
                  </div>
                  <div className="pt-2 user-bio">
                    <Linkify>{challenge.details}</Linkify>
                  </div>
                </Col>
                <Col md="12">
                  <div className="row mt-4 mb-4">
                    <div className="col-4 text-center">
                      <div>
                        <h4>{challenge.joined || 0}</h4>
                        <p className="stats-title">Participants</p>
                      </div>
                    </div>
                    <div className="col-4 text-center">
                      <div>
                        <h4>{challenge.done || 0}</h4>
                        <p className="stats-title">Completed</p>
                      </div>
                    </div>
                    <div className="col-4 text-center">
                      <div>
                        <h4>{challenge.videoCount || 0}</h4>
                        <p className="stats-title">Updates</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="3"></Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChallengeInfo);
