import React from "react";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import Switch from "react-switch";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";

class AddYoutubeChannelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetched: false,
      userUid: "",
      channelId: "",
      title: "",
      description: "",
      thumbnail: "",
      stats: null,
      isFetching: false,
      isSubmitting: false,
    };

    this.handleLaunchChange = this.handleLaunchChange.bind(this);
  }

  handleLaunchChange(isLaunched) {
    this.setState({ isLaunched });
  }

  handleModalClose = () => {
    this.setState({
      loading: false,
      fetched: false,
      userUid: "",
      channelId: "",
      title: "",
      description: "",
      thumbnail: "",
      stats: null,
      isFetching: false,
      isSubmitting: false,
    });
    this.props.setUserValue({ showAddYoutubeChannelModal: false });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  fetchAnotherOne = () => {
    this.setState({
      loading: false,
      fetched: false,
      userUid: "",
      channelId: "",
      title: "",
      description: "",
      thumbnail: "",
      stats: null,
      isFetching: false,
      isSubmitting: false,
    });
  };

  fetchChannelInfo = async (event) => {
    this.setState({
      fetched: false,
      isFetching: true,
      title: "",
      description: "",
      thumbnail: "",
      stats: null,
    });

    const { channelId } = this.state;
    const that = this;
    var fetchYoutubeChannelInfo = firebase
      .functions()
      .httpsCallable("fetchYoutubeChannelInfo");
    fetchYoutubeChannelInfo({
      channelId: channelId,
    })
      .then(async (result) => {
        const snippet = result.data.items[0].snippet;
        const statistics = result.data.items[0].statistics;
        that.setState({
          isFetching: false,
          fetched: true,
          title: snippet.title,
          description: snippet.description.substring(0, 256),
          thumbnail: snippet.thumbnails.medium.url,
          stats: statistics,
        });
      })
      .catch((err) => {
        toast.error("Sorry, wrong Channel ID, please try another one.", {
          autoClose: 4000,
        });
        that.setState({ isFetching: false, fetched: false });
      });
  };

  submitYoutubeChannel = async () => {
    this.setState({ isSubmitting: true });
    const {
      userUid,
      channelId,
      title,
      description,
      thumbnail,
      stats,
    } = this.state;
    const { currentUser } = this.props.user;

    if (title === "" || description === "") {
      toast.error("Oops, Title and Description cannot be empty.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    if (description.length > 256) {
      toast.error("Oops, Description length cannot exceed 256 characters.", {
        autoClose: 4000,
      });
      this.setState({ isSubmitting: false });
      return;
    }

    var user = currentUser;
    if (userUid !== "") {
      const userRef = await firebase
        .database()
        .ref("users")
        .child(userUid)
        .once("value");

      user = userRef.val();
    }

    const channelRef = await firebase
      .database()
      .ref("indietubers")
      .child(channelId)
      .once("value");

    if (channelRef.exists()) {
      await firebase //add to /indietubers/{channelId}
        .database()
        .ref("indietubers")
        .child(channelId)
        .update({
          title: title,
          description: description,
          thumbnail: thumbnail,
          stats: stats,
          subscriberCount: stats.subscriberCount,
        });
    } else {
      const channelObj = {
        user: {
          uid: user.uid,
          userName: user.userName || "",
          displayName: user.displayName,
          avatar: user.avatar,
        },
        channelId: channelId,
        title: title,
        description: description,
        thumbnail: thumbnail,
        stats: stats,
        subscriberCount: stats.subscriberCount,
        createdAt: Date.now(),
        points: 0,
      };
      await firebase //add to /indietubers/{channelId}
        .database()
        .ref("indietubers")
        .child(channelId)
        .set(channelObj);
    }

    await firebase //add to /users/{userId}/socialLinks/
      .database()
      .ref("users")
      .child(user.uid)
      .child("socialLinks")
      .update({ youtube: "www.youtube.com/channel/" + channelId });

    this.setState({ isSubmitting: false });
    this.props.setUserValue({ showAddYoutubeChannelModal: false });
    toast.success("Your YouTube channel is added successfully", {
      autoClose: 4000,
    });
  };

  render() {
    const { showAddYoutubeChannelModal, currentUser } = this.props.user;
    const {
      fetched,
      channelId,
      title,
      description,
      thumbnail,
      userUid,
      isFetching,
      isSubmitting,
    } = this.state;

    return (
      <Modal
        show={showAddYoutubeChannelModal}
        onHide={this.handleModalClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add your channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="channelId" className="mb-0">
              <Form.Label>
                YouTube Channel ID (
                <a
                  href="https://support.google.com/youtube/answer/3250431?hl=en"
                  target="_blank"
                >
                  where to find 🤔
                </a>
                )
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={channelId}
                onChange={this.handleInputChange}
              />
            </Form.Group>

            {fetched && (
              <>
                {currentUser && currentUser.userName === "damon" && (
                  <Form.Group controlId="userUid" className="mb-0">
                    <Form.Label>User UID</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={userUid}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                )}

                <Form.Group controlId="title" className="mb-0">
                  <Form.Label>Channel Photo</Form.Label>
                  <div>
                    <img className="rounded channel-pic" src={thumbnail} />
                  </div>
                </Form.Group>

                <Form.Group controlId="title" className="mb-0">
                  <Form.Label>Channel Title</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={title}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="description" className="mb-0">
                  <Form.Label>
                    Channel Description (256 characters limit)
                  </Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={5}
                    maxLength="256"
                    value={description}
                    onChange={this.handleInputChange}
                  />
                  <Form.Text className="text-muted">
                    {256 - description.length} characters
                  </Form.Text>
                </Form.Group>
              </>
            )}
          </Form>

          {fetched ? (
            <>
              <button
                onClick={this.fetchAnotherOne}
                className="btn btn-secondary my-1"
                disabled={isSubmitting ? true : false}
              >
                {isFetching ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Fetch Another One"
                )}
              </button>
              <button
                onClick={this.submitYoutubeChannel}
                className="btn btn-danger my-1"
                style={{ float: "right" }}
                disabled={isSubmitting ? true : false}
              >
                {isSubmitting ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : (
                  "Submit"
                )}
              </button>
            </>
          ) : (
            <button
              onClick={this.fetchChannelInfo}
              className="btn btn-primary my-1"
              disabled={isSubmitting ? true : false}
            >
              {isFetching ? (
                <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
              ) : (
                "Fetch Your Channel"
              )}
            </button>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddYoutubeChannelModal);
