import React, { Fragment } from "react";
import { withRouter } from "react-router";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { connect } from "react-redux";
import { compose } from "redux";
import ChallengeInfo from "./ChallengeInfo";
import LeaderBoard from "./LeaderBoard";
import "./Challenge.css";
import { setUserValue } from "../../redux/action/userAction";

import sevenDaysImg from "./asset/7_days.png";
import fourWeeksImg from "./asset/4_weeks.png";
import twentyFiveWeeksImg from "./asset/25_weeks.png";
import fiftyWeeksImg from "./asset/50_weeks.png";

var challengeListener;
class ChallengeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { challenge: null };
  }

  componentDidMount = async () => {
    var challengeId = this.props.match.params.challengeId;

    challengeListener = await firebase
      .database()
      .ref("challenges")
      .child(challengeId)
      .on("value", (snapshot) => {
        this.setState({ challenge: snapshot.val() });
      });
  };

  componentWillUnmount = () => {
    var challengeId = this.props.match.params.challengeId;
    firebase
      .database()
      .ref("challenges")
      .child(challengeId)
      .off("value", challengeListener);
  };

  render() {
    const { challenge } = this.state;

    if (!challenge) {
      return null;
    }

    return (
      <Fragment>
        <section className="bg-dashboard">
          <ChallengeInfo challenge={challenge} />
          <LeaderBoard challenge={challenge} />
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChallengeDetails);
