import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { setUserValue } from "../../redux/action/userAction";
import ProductItem from "../ProductItem/ProductItem";
import "./productList.css";

class ProductList extends React.Component {
  onShowAddNewProductModal = () => {
    this.props.setUserValue({ showAddNewProductModal: true });
  };
  render() {
    const { products, profileUser, user, hideAvatar } = this.props;
    const { currentUser } = user;
    return (
      <div className="product-list">
        {products && products[0] ? (
          products.map((product) => (
            <ProductItem
              key={product.id}
              product={product}
              hideAvatar={hideAvatar}
            />
          ))
        ) : (
          <div className="text-center">
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              No Products Found!
            </p>
            {currentUser && profileUser && profileUser.uid === currentUser.uid && (
              <Button
                variant="btn btn-danger my-1"
                onClick={this.onShowAddNewProductModal}
              >
                Add a product
              </Button>
            )}
          </div>
        )}
        {currentUser &&
          profileUser &&
          profileUser.uid === currentUser.uid &&
          products &&
          products[0] && (
            <div className="text-center mt-4">
              <Button
                variant="btn btn-danger my-1"
                onClick={this.onShowAddNewProductModal}
              >
                Add a product
              </Button>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
