import React from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/database";
import { setUserValue } from "../../redux/action/userAction";
import { Modal, Button } from "react-bootstrap";

class IndieTuberBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.weekId !== prevProps.weekId) {
      const weekRef = await firebase
        .database()
        .ref("weekly-topics")
        .child(this.props.weekId)
        .once("value");

      const meta = weekRef.val().meta;
      this.setState({ weekMeta: meta });
    }
  };

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onShowAddNewProductModal = () => {
    this.props.setUserValue({ showAddNewProductModal: true });
  };

  render() {
    const { currentUser } = this.props.user;

    return (
      <section className="jumbotron text-center cta">
        <div className="introsection-div">
          <div className="container pt-4">
            <h1 className="home-banner-title">Made by Awesome Indies</h1>

            <p className="lead text-muted">
              Add your product here. Let's record how we build our product from
              the start to thriving 🥳
            </p>

            <p>
              {currentUser ? (
                <button
                  onClick={this.onShowAddNewProductModal}
                  className="btn btn-danger px-5 py-2"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  Add Your Product 🤗
                </button>
              ) : (
                <button
                  onClick={this.onShowAuthModal}
                  className="btn btn-danger px-5 py-2"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  Get Started
                </button>
              )}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IndieTuberBanner);
