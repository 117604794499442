import React from "react";
import { isSameWeek } from "date-fns";
import firebase from "firebase/app";
import "firebase/database";
import { Helmet } from "react-helmet";
import PulseLoader from "react-spinners/PulseLoader";
import MeetupBanner from "./MeetupBanner";
import MeetupList from "./MeetupList";
import "./Meetup.css";

class Meetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetupsArray: [],
      contextRef: {},
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    const meetupsRef = await firebase
      .database()
      .ref("meetups")
      .orderByChild("createdAt")
      .once("value");

    var meetupsArray = [];
    meetupsRef.forEach((meetup) => {
      meetupsArray.push(meetup.val());
    });

    this.setState({
      allMeetups: meetupsArray.reverse(),
      isLoading: false,
    });
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const { allMeetups, isLoading } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <main className="bg-light products-container">
            <div className="text-center" style={{ marginTop: 150 }}>
              <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
            </div>
          </main>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{"Meet the real maker - IndieLog"}</title>
          <meta
            name="description"
            content={"Virtual meetup with other makers"}
          />
        </Helmet>

        <main className="bg-light products-container">
          <MeetupBanner />
          <div className="container">
            <div className="row" ref={this.handleContextRef}>
              <MeetupList meetups={allMeetups} title={"Upcoming Events"} />
              {/* <MeetupList meetups={allMeetups} title={"Past Events"} /> */}
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Meetup;
