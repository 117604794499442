import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { Picker, emojiIndex } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

class AddCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: this.props.isEditComment ? this.props.comment.comment : "",
      emojiPicker: false
    };
    console.log();
  }

  handleCommentSubmit = () => {
    const {
      addVideoComment,
      editVideoComment,
      videoId,
      closeForm,
      parentId,
      isEditComment
    } = this.props;
    const { comment } = this.state;
    isEditComment
      ? editVideoComment(videoId, comment, parentId)
      : addVideoComment(videoId, comment, parentId);
    if (parentId !== 0) {
      closeForm();
    }
    this.setState({ comment: "" });
  };

  handleTogglePicker = () => {
    this.setState({ emojiPicker: !this.state.emojiPicker });
  };

  handleAddEmoji = emoji => {
    const oldComment = this.state.comment;
    const newComment = `${oldComment} ${emoji.native} `;
    this.setState({ comment: newComment, emojiPicker: false });
  };

  // colonToUnicode = comment => {
  //   return comment.replace(/:[A-Za-z0-9_+-]+:/g, x => {
  //     x = x.replace(/:/g, "");
  //     let emoji = emojiIndex.emojis[x];
  //     console.log("***", emoji);
  //     if (typeof emoji !== "undefined") {
  //       let unicode = emoji.native;
  //       if (typeof unicode !== "undefined") {
  //         return unicode;
  //       }
  //     }
  //     x = ":" + x + ":";
  //     return x;
  //   });
  // };

  render() {
    const {
      allowCancel,
      onShowAmaQuestionModal,
      parentComment,
      isEditComment
    } = this.props;
    const { emojiPicker } = this.state;

    return (
      <Form className={isEditComment ? "mt-0" : "mt-2"}>
        {parentComment && (
          <Button
            content="Ask Now"
            color="red"
            onClick={onShowAmaQuestionModal}
          />
        )}
      </Form>
    );
  }
}

export default AddCommentForm;
