import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUserValue } from "../../redux/action/userAction";
import letsgoImg from "./asset/lets-go.gif";

class LeaderBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCheckProfile = (user) => () => {
    this.props.history.push("/user/" + user.uid);
  };

  render() {
    const { challenge, user } = this.props;
    const { currentUser } = user;
    const participants = challenge.participants;
    return (
      <div className="home-page">
        <div className="leaderboard-table">
          <div className="flex flex-col">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Overall
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Products
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Weeks
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-base leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Updates
                      </th>
                      <th className="px-auto py-3 border-b border-gray-200 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {/* Yourself */}
                    {/* <tr>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-lg leading-5 text-gray-500">
                        You
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full"
                              src="https://www.gstatic.com/tv/thumb/persons/589228/589228_v9_ba.jpg"
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-lg leading-5 font-medium text-gray-900">
                              Mark Z
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                        <div className="flex relative z-0 overflow-hidden">
                          {Object.values([0, 1]).map(() => {
                            return (
                              <img
                                className="relative z-30 inline-block h-8 w-8 rounded-full text-white shadow-solid"
                                src="https://facebookbrand.com/wp-content/uploads/2019/10/flogo_RGB_HEX-BRC-Site-250.png?w=250&h=250"
                                alt=""
                              />
                            );
                          })}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-lg leading-5 text-gray-500">
                        5{" "}
                        <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          20%
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-lg leading-5 text-gray-500">
                        10
                      </td>
                    </tr> */}
                    {/* End of yourself */}

                    {participants &&
                      Object.values(participants).map((participant, i) => {
                        return (
                          <tr
                            className={
                              currentUser && participant.uid === currentUser.uid
                                ? "bg-blue-100"
                                : ""
                            }
                          >
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-lg leading-5 text-gray-500">
                              {i + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  <Link
                                    to={{
                                      pathname: "/user/" + participant.uid,
                                    }}
                                    target="_blank"
                                  >
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={participant.avatar}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                                <div className="ml-4">
                                  <Link
                                    to={{
                                      pathname: "/user/" + participant.uid,
                                    }}
                                    target="_blank"
                                  >
                                    <div className="text-lg leading-5 font-medium text-gray-900">
                                      {participant.name}
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                              <div className="flex relative z-0 overflow-hidden">
                                {participant.products &&
                                  Object.values(participant.products).map(
                                    (product) => {
                                      return (
                                        <Link
                                          to={{
                                            pathname: "/product/" + product.id,
                                          }}
                                          target="_blank"
                                        >
                                          <img
                                            className="relative z-30 inline-block h-10 w-10 rounded-full text-white"
                                            src={product.logoImg}
                                            alt=""
                                          />
                                        </Link>
                                      );
                                    }
                                  )}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-lg leading-5 text-gray-500">
                              {participant.streak || 0}{" "}
                              <span class="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                {((participant.streak || 0) * 100) /
                                  challenge.goal +
                                  "%"}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-lg leading-5 text-gray-500">
                              {participant.updates || 0}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {!(participants && Object.values(participants).length > 0) && (
                  <div className="text-center">
                    <div className="lets-go">
                      <img
                        className="img-fluid"
                        src={letsgoImg}
                        alt="let's go"
                      />
                    </div>
                    <div className="my-2">
                      <h4>Be the first one to join the challenge 💪</h4>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

export default compose(withRouter, connect(mapStateToProps))(LeaderBoard);
