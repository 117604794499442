import React from "react";
import Linkify from "linkifyjs/react";
import { useHistory } from "react-router-dom";

function fnum(x) {
  if (x < 9999) {
    return x;
  }

  if (x < 1000000) {
    return Math.round(x / 1000) + "K";
  }

  if (x < 10000000) {
    return (x / 1000000).toFixed(1) + "M";
  }

  if (x < 1000000000) {
    return Math.round(x / 1000000) + "M";
  }

  if (x < 1000000000000) {
    return Math.round(x / 1000000000) + "B";
  }

  return "1T+";
}

const IndieTuberCard = (props) => {
  const { tuber } = props;
  const history = useHistory();

  const onVisitYoutube = (tuber) => {
    var channelURL = "https://www.youtube.com/channel/" + tuber.channelId;
    window.open(channelURL);
  };

  const onCheckProfile = (tuber) => {
    const user = tuber.user;
    let path = "/user/" + user.userName || user.uid;
    history.push(path);
  };

  return (
    <div className="col-xxxl-2 col-xxl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-3 pr-0">
      <div class="card indietuber-card mb-4 box-shadow">
        <div class="card-body d-flex flex-column">
          <div className="profileThumb text-center">
            <img
              src={tuber.thumbnail}
              className="avatar img-circle img-thumbnail mt-0 mx-auto"
              alt="avatar"
              onClick={() => onVisitYoutube(tuber)}
            />
          </div>
          <div
            className="text-center"
            onClick={() => onVisitYoutube(tuber)}
            style={{ cursor: "pointer" }}
          >
            <h3 className="tuber-title">{tuber.title}</h3>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-4 text-center">
              <h4 className="stat-number">
                {fnum(tuber.stats.subscriberCount)}
              </h4>
              <p>Subscribers</p>
            </div>
            <div className="col-4 text-center">
              <div>
                <h4 className="stat-number">{fnum(tuber.stats.videoCount)}</h4>
                <p>Videos</p>
              </div>
            </div>
            <div className="col-4 text-center">
              <div>
                <h4 className="stat-number">{fnum(tuber.stats.viewCount)}</h4>
                <p>Views</p>
              </div>
            </div>
          </div>

          <div className="text-center tuber-description">
            <Linkify style={{ whiteSpace: "per-line" }}>
              {tuber.description.padEnd(256)}
            </Linkify>
          </div>

          <div className="mt-auto pt-1 card-btn-group">
            <button
              onClick={() => onCheckProfile(tuber)}
              className="btn btn-primary my-1"
              style={{
                fontSize: "15px",
                marginRight: "10px",
              }}
            >
              Check Profile
            </button>
            <button
              onClick={() => onVisitYoutube(tuber)}
              className="btn btn-danger my-1"
              style={{
                fontSize: "15px",
              }}
            >
              Visit Youtube
            </button>
          </div>
        </div>
      </div>

      {/* <div className="card indietuber-card">
        <div className="profileThumb text-center">
          <img
            src={tuber.thumbnail}
            className="avatar img-circle img-thumbnail mt-0"
            alt="avatar"
            onClick={() => onVisitYoutube(tuber)}
          />
        </div>
        <div
          className="text-center"
          onClick={() => onVisitYoutube(tuber)}
          style={{ cursor: "pointer" }}
        >
          <h3 className="tuber-title">{tuber.title}</h3>
        </div>
        <div className="pt-2 text-center tuber-description">
          <Linkify>{tuber.description.padEnd(256)}</Linkify>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-4 text-center">
            <h4 className="stat-number">{tuber.stats.subscriberCount}</h4>
            <p>Subscribers</p>
          </div>
          <div className="col-4 text-center">
            <div>
              <h4 className="stat-number">{tuber.stats.videoCount}</h4>
              <p>Videos</p>
            </div>
          </div>
          <div className="col-4 text-center">
            <div>
              <h4 className="stat-number">{tuber.stats.viewCount}</h4>
              <p>Views</p>
            </div>
          </div>
        </div>

        <div className="pt-3 card-btn-group">
          <button
            onClick={() => onCheckProfile(tuber)}
            className="btn btn-primary my-1"
            style={{
              fontSize: "15px",
            }}
          >
            Check Profile
          </button>
          <button
            onClick={() => onVisitYoutube(tuber)}
            className="btn btn-danger my-1"
            style={{
              fontSize: "15px",
            }}
          >
            Visit Youtube
          </button>
        </div>
      </div>
     */}
    </div>
  );
};

export default IndieTuberCard;
