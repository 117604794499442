import { combineReducers } from "redux";
import userReducer from "./userReducer";
import videoReducer from "./videoReducer";
import asyncReducer from "./asyncReducer";

const reducer = combineReducers({
  user: userReducer,
  videos: videoReducer,
  async: asyncReducer
});

export default reducer;
