import React from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { format } from "date-fns";
import { toast } from "react-toastify";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "antd";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import {
  FacebookIcon,
  TwitterIcon,
  RedditIcon,
  LineIcon,
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LineShareButton,
} from "react-share";
import embed_img from "../../assets/images/embed.png";
import { setUserValue } from "../../redux/action/userAction";

class VideoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSocialShare: false,
      currentURL: window.location.href,
      showEmbedModal: false,
      showReportVideoModal: false,
      reportReason: "",
      isDownloading: false,
    };
  }

  handleModalClose = () => {
    this.setState({
      showSocialShare: false,
      showEmbedModal: false,
      showReportVideoModal: false,
    });
  };

  onShowSocialShareModal = () => {
    this.setState({ showSocialShare: true });
  };

  onShowEmbedModal = () => {
    this.setState({ showSocialShare: false, showEmbedModal: true });
  };

  onLikeVideo = async () => {
    const { currentUser } = this.props.user;
    const { video } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }
    const videoRef = await firebase
      .database()
      .ref("videos")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedCount");
    videoRef.transaction(function (currentLikedCount) {
      return (currentLikedCount || 0) + 1;
    });

    await firebase
      .database()
      .ref("videos")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedBy")
      .child(currentUser.uid)
      .set({
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        userName: currentUser.userName || "",
        userId: currentUser.uid,
        createdAt: Date.now(),
      });

    await firebase
      .database()
      .ref("activities")
      .child(currentUser.uid)
      .push({
        type: "New Like Video",
        content: {
          video: video,
        },
        createdAt: Date.now(),
      });
  };

  onUnlikeVideo = async () => {
    const { currentUser } = this.props.user;
    const { video } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    const videoRef = await firebase
      .database()
      .ref("videos")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedCount");
    videoRef.transaction(function (currentLikedCount) {
      return currentLikedCount - 1;
    });

    await firebase
      .database()
      .ref("videos")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedBy")
      .child(currentUser.uid)
      .remove();

    await firebase
      .database()
      .ref("activities")
      .child(currentUser.uid)
      .push({
        type: "New Unlike Video",
        content: {
          video: video,
        },
        createdAt: Date.now(),
      });
  };

  onDownloadVideo = () => {
    this.setState({ isDownloading: true });
    const { video } = this.props;
    axios({
      url: video.downloadURL,
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "video.mp4");
        document.body.appendChild(link);
        link.click();
        this.setState({ isDownloading: false });
      })
      .catch((err) => this.state({ isDownloading: false }));
  };

  copyEmbedCode = () => {
    this.setState({ showSocialShare: false, showEmbedModal: false });
  };

  reportVideo = () => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    this.setState({ showReportVideoModal: true });
  };

  sendReport = async () => {
    const { reportReason, isDownloading } = this.state;
    const { video, user } = this.props;
    const { currentUser } = user;

    //add the report to /report/{reportId}
    await firebase
      .database()
      .ref("video-reports")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .set({
        videoId: video.titleURL ? video.titleURL : video.playbackIds[0].id,
        videoOwner: video.user.uid,
        reason: reportReason,
        reportedBy: currentUser.uid,
        reporterName: currentUser.displayName,
      });

    if (currentUser.isModerator) {
      //update the report status in video
      await firebase
        .database()
        .ref("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({ reported: true });

      //update the report status in product video
      if (video.product && video.product.id !== "") {
        await firebase
          .database()
          .ref("products")
          .child(video.product.id)
          .child("videos")
          .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
          .update({ reported: true });
      }

      //update the report status in user's video
      await firebase
        .database()
        .ref("users")
        .child(video.user.uid)
        .child("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({ reported: true });
    }

    toast.success("Thanks for reporting! We will take a look soon.", {
      autoClose: 4000,
    });
    this.setState({ showReportVideoModal: false });
  };

  render() {
    const {
      showSocialShare,
      showEmbedModal,
      showReportVideoModal,
      currentURL,
      isDownloading,
    } = this.state;
    const { video, user } = this.props;
    const { currentUser } = user;

    const currentVideoURL =
      "https://stream.mux.com/" + video.playbackIds[0].id + ".m3u8";

    const embed_code = `<script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
    <video id="video" controls></video>
    <script>
      if(Hls.isSupported()) {
        var video = document.getElementById('video');
        var hls = new Hls();
        hls.loadSource("${currentVideoURL}");
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
          video.play();
      });
     } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = "${currentVideoURL}";
        video.addEventListener('canplay',function() {
          video.play();
       });
     }
</script>`;

    if (!video) return null;

    return (
      <>
        <Modal //report video
          show={showReportVideoModal}
          onHide={this.handleModalClose}
          top
        >
          <Modal.Header closeButton>
            <Modal.Title>Why are you reporting this video?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Inappropriate" })}
              >
                Inappropriate
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Off-Topic" })}
              >
                Off-Topic
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Spam" })}
              >
                Spam
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() =>
                  this.setState({ reportReason: "Sexual, Violence" })
                }
              >
                Sexual, Violence
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Others" })}
              >
                Others
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary px-5" onClick={this.sendReport}>
              Go Report
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSocialShare} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {/* <img
                src={embed_img}
                className="embed-icon"
                alt="Embed code"
                onClick={this.onShowEmbedModal}
              /> */}
              <FacebookShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
              >
                <FacebookIcon size={60} round={true} className="mr-4" />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
                via={"indie_log"}
                related={["indie_log"]}
                title={video.title}
              >
                <TwitterIcon size={60} round={true} className="mr-4" />
              </TwitterShareButton>
              <RedditShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
              >
                <RedditIcon size={60} round={true} className="mr-4" />
              </RedditShareButton>
              <LineShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
              >
                <LineIcon size={60} round={true} />
              </LineShareButton>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showEmbedModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Embed Video</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ wordBreak: "break-all" }}>
            <textarea style={{ width: "100%" }} rows={22}>
              {embed_code}
            </textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-danger my-1" onClick={this.copyEmbedCode}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="videoInfoHeader">
          <div className="videTitle">
            <h1>{video.title}</h1>
          </div>

          {video.likedBy && Object.values(video.likedBy).length >= 1 && (
            <p className="total_views">{format(video.createdAt, "PP")}</p>
          )}

          <div className="videoMeta mt-2">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-left">
                {video.likedBy && Object.values(video.likedBy).length >= 1 ? (
                  <div className="row col-12">
                    <AvatarGroup max={10}>
                      {video.likedBy &&
                        Object.values(video.likedBy)
                          .slice(0, 3)
                          .map((user, index) => {
                            return (
                              <Tooltip
                                title={user.name}
                                placement="bottom"
                                key={index}
                              >
                                <Avatar
                                  alt={user.name}
                                  src={user.avatar}
                                  className="comment-liked-by"
                                />
                              </Tooltip>
                            );
                          })}
                    </AvatarGroup>
                    {Object.values(video.likedBy).length > 1 ? (
                      <span className="liked-by-text">
                        liked by{" "}
                        <strong>{Object.values(video.likedBy)[0].name}</strong>{" "}
                        and others
                      </span>
                    ) : (
                      <span className="liked-by-text">
                        liked by{" "}
                        <strong>{Object.values(video.likedBy)[0].name}</strong>
                      </span>
                    )}
                  </div>
                ) : (
                  <p className="total_views">{format(video.createdAt, "PP")}</p>
                )}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-right">
                <div className="videoMetaRightIcon">
                  <ul>
                    {isDownloading ? (
                      <Tooltip title="Please don't close this tab. Your video is downloading.">
                        <li>
                          <ClipLoader
                            sizeUnit={"px"}
                            size={18}
                            color={"#909090"}
                          />
                        </li>
                      </Tooltip>
                    ) : (
                      currentUser &&
                      video.user.uid === currentUser.uid &&
                      video.downloadURL && (
                        <li>
                          <Tooltip title="Download">
                            <a
                              // onClick={this.onDownloadVideo}
                              href={video.downloadURL}
                              download="myvideo.mp4"
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fas fa-download"></i>
                            </a>
                          </Tooltip>
                        </li>
                      )
                    )}

                    <li className={video.likedCount > 0 ? "mr-2" : ""}>
                      {currentUser &&
                      video.likedBy &&
                      video.likedBy[currentUser.uid] ? (
                        <a
                          // onClick={this.onUnlikeVideo}
                          className="text-muted"
                        >
                          <i className="fas fa-thumbs-up" />
                        </a>
                      ) : (
                        <a
                          onClick={this.onLikeVideo}
                          style={{ cursor: "pointer" }}
                          className="text-muted"
                        >
                          <i className="far fa-thumbs-up" />
                        </a>
                      )}
                    </li>

                    <li>
                      <Tooltip title="Share on Facebook">
                        <FacebookShareButton
                          url={currentURL}
                          quote={video.title}
                        >
                          <i
                            className="fab fa-facebook-f"
                            style={{ color: "#909090", fontSize: "16px" }}
                          ></i>
                        </FacebookShareButton>
                      </Tooltip>
                    </li>

                    <li>
                      <Tooltip title="Share on Twitter">
                        <TwitterShareButton
                          url={currentURL}
                          via={"indie_log"}
                          title={video.title}
                        >
                          <i
                            className="fab fa-twitter"
                            style={{ color: "#909090", fontSize: "16px" }}
                          ></i>
                        </TwitterShareButton>
                      </Tooltip>
                    </li>
                    {/* 
                    <li>
                      <Tooltip title="Report this video">
                        <a
                          onClick={() => this.reportVideo()}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-flag"></i>
                        </a>
                      </Tooltip>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VideoDetails);
