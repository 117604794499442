import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/database";
import { toast } from "react-toastify";
import EditDetails from "./EditDetails";
import EditResult from "./EditResult";

export class EditVideoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      modalHeader: "",
      title: this.props.video.title,
      description: this.props.video.description,
      thumbnail: "",
      product: (this.props.video.product && this.props.video.product.id) || "",
      isProcessing: false,
      editSucceed: true,
    };
  }

  // Process to Next Step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  // Try again, go back to step 1
  tryAgain = () => {
    this.setState({ step: 1 });
  };
  modalHeader = (title) => {
    this.setState({
      modalHeader: title,
    });
  };
  // Handle input fields changes
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  selectThumbnail = (thumbnailURL) => {
    this.setState({
      thumbnail: thumbnailURL,
    });
  };

  selectProduct = (product) => {
    this.setState({
      product: product,
    });
  };

  onDoneEditing = async () => {
    const { title, description, thumbnail, product, step } = this.state;
    const { video, currentUser } = this.props;

    if (title === "") {
      toast.error("Error: title is required.", {
        autoClose: 4000,
      });
      return;
    }

    //check if it's weekly submit
    const videoRef = await firebase
      .database()
      .ref("videos")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .once("value");

    const firstDayOfWeek = videoRef.val().firstDayOfWeek;

    //update the thumbnail
    if (thumbnail !== "") {
      await firebase
        .database()
        .ref("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({
          thumbnail: thumbnail,
        });

      await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .child("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({
          thumbnail: thumbnail,
        });

      if (firstDayOfWeek) {
        await firebase
          .database()
          .ref("weekly-topics")
          .child(firstDayOfWeek)
          .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
          .update({
            thumbnail: thumbnail,
          });
      }

      if (product !== "") {
        await firebase
          .database()
          .ref("products")
          .child(product)
          .child("videos")
          .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
          .update({ thumbnail: thumbnail });
      }
    }

    //update the product
    if (product !== "") {
      //get the product info
      const productRef = await firebase
        .database()
        .ref("products")
        .child(product)
        .once("value");

      //check if video already associated with another product
      const videoRef = await firebase
        .database()
        .ref("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .once("value");
      const oldProduct =
        (videoRef.val().product && videoRef.val().product.id) || "";

      await firebase //add video to product's videos
        .database()
        .ref("products")
        .child(product)
        .child("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .set(video);
      await firebase //update the product
        .database()
        .ref("products")
        .child(product)
        .child("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({
          product: {
            name: productRef.val().name,
            logoImg: productRef.val().logoImg,
            id: productRef.val().id,
            description: productRef.val().description,
            lead: productRef.val().lead || "",
            leadMC: productRef.val().leadMC || "",
            leadCK: productRef.val().leadCK || "",
            website: productRef.val().website || "",
          },
          title: title,
          description: description,
        });

      if (oldProduct !== "" && oldProduct !== product) {
        await firebase
          .database()
          .ref("products")
          .child(oldProduct)
          .child("videos")
          .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
          .remove();
      }

      await firebase
        .database()
        .ref("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({
          product: {
            name: productRef.val().name,
            logoImg: productRef.val().logoImg,
            id: productRef.val().id,
            description: productRef.val().description,
            lead: productRef.val().lead || "",
            leadMC: productRef.val().leadMC || "",
            leadCK: productRef.val().leadCK || "",
            website: productRef.val().website || "",
          },
        });

      await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .child("videos")
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({
          product: {
            name: product,
            logoImg: productRef.val().logoImg,
            id: productRef.val().id,
            description: productRef.val().description,
            lead: productRef.val().lead || "",
            leadMC: productRef.val().leadMC || "",
            leadCK: productRef.val().leadCK || "",
          },
        });
    }

    await firebase
      .database()
      .ref("videos")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .update({
        title: title,
        description: description,
      });

    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("videos")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .update({
        title: title,
        description: description,
      });

    if (firstDayOfWeek) {
      await firebase
        .database()
        .ref("weekly-topics")
        .child(firstDayOfWeek)
        .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
        .update({
          title: title,
          description: description,
        });
    }

    this.setState({
      step: step + 1,
      editSucceed: true,
    });
  };

  render() {
    const { step, isProcessing, editSucceed } = this.state;
    const { video, user } = this.props;
    const { title, description } = this.state;
    const values = { title, description };

    switch (step) {
      case 1:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditDetails
                video={video}
                user={user}
                handleChange={this.handleChange}
                values={values}
                modalHeader={(title) => this.modalHeader(title)}
                onDoneEditing={this.onDoneEditing}
                isProcessing={isProcessing}
                selectThumbnail={(thumbnail) => this.selectThumbnail(thumbnail)}
                selectProduct={(product) => this.selectProduct(product)}
              />
            </Modal.Body>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditResult
                modalHeader={(title) => this.modalHeader(title)}
                editSucceed={editSucceed}
                tryAgain={this.tryAgain}
              />
            </Modal.Body>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditDetails
                modalHeader={(title) => this.modalHeader(title)}
                nextStep={this.nextStep}
              />
            </Modal.Body>
          </Fragment>
        );
    }
  }
}

export default EditVideoModal;
