import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import PulseLoader from "react-spinners/PulseLoader";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { connect } from "react-redux";
import { compose } from "redux";
import { Grid } from "semantic-ui-react";
import UserInfo from "./UserInfo";
import UserVideos from "./UserVideos";
import UserProducts from "./UserProducts";
import "./Dashboard.css";
import { setUserValue } from "../../redux/action/userAction";

var unsubscribe = null;
var currentUserListener;
var needUnmount = true;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      publicProfile: false,
      videos: [],
      products: [],
      showProducts: false,
      showVideos: true,
      noSuchUser: false,
    };
    this.props.setUserValue({ showAddNewProductModal: false });
  }

  componentDidMount = async () => {
    var userId = this.props.match.params.userId;

    if (userId && userId.length <= 20) {
      //get user's uid from the username
      const usernameRef = await firebase
        .database()
        .ref("usernames")
        .child(userId)
        .once("value");

      if (!usernameRef.val()) {
        this.props.history.push("/error");
        return;
      }
      const userUid = usernameRef.val().uid;
      userId = userUid;
    }

    if (userId) {
      currentUserListener = await firebase
        .database()
        .ref("users")
        .child(userId)
        .on("value", (snapshot) => {
          if (snapshot.val() === null || snapshot.val() === undefined) {
            needUnmount = false;
            this.props.history.push("/error");
            return;
          }

          this.setState({
            user: snapshot.val(),
            publicProfile: true,
            products: snapshot.val().products || [],
          });
        });

      await firebase
        .database()
        .ref("users")
        .child(userId)
        .child("videos")
        .orderByChild("createdAt")
        .once("value", (snapshot) => {
          var videosArray = [];
          snapshot.forEach((video) => {
            videosArray.push(video.val());
          });
          this.setState({ videos: videosArray.reverse() });
        });
    } else {
      unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          currentUserListener = await firebase
            .database()
            .ref("users")
            .child(user.uid)
            .on("value", (snapshot) => {
              this.setState({
                user: snapshot.val(),
                products: snapshot.val().products || [],
              });
            });

          await firebase
            .database()
            .ref("users")
            .child(user.uid)
            .child("videos")
            .orderByChild("createdAt")
            .once("value", (snapshot) => {
              var videosArray = [];
              snapshot.forEach((video) => {
                videosArray.push(video.val());
              });
              this.setState({ videos: videosArray.reverse() });
            });
        } else {
          this.props.history.push("/");
        }
      });
    }
  };

  componentWillUnmount = async () => {
    this.state.user &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.user.uid)
        .off("value", currentUserListener));
    needUnmount && unsubscribe && unsubscribe();
  };

  onShowProducts = () => {
    this.setState({
      showProducts: true,
      showVideos: false,
    });
  };

  onShowAllVideos = () => {
    this.setState({
      showProducts: false,
      showVideos: true,
    });
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const {
      user,
      publicProfile,
      videos,
      showProducts,
      showVideos,
      products,
      noSuchUser,
    } = this.state;

    if (!user) {
      return (
        <Fragment>
          <section className="bg-dashboard">
            <div className="text-center" style={{ marginTop: 200 }}>
              <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
            </div>
          </section>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{user.displayName + " - IndieLog"}</title>
          <meta name="description" content={user.bio || ""} />
        </Helmet>

        <section className="bg-dashboard">
          <UserInfo
            profileUser={user}
            videos={videos}
            showProducts={showProducts}
            showVideos={showVideos}
            onShowAllVideos={() => this.onShowAllVideos()}
            onShowProducts={() => this.onShowProducts()}
          />
          {showProducts ? (
            <UserProducts profileUser={user} products={products} />
          ) : (
            <Grid className="mx-0">
              <Grid.Column width={16}>
                <div ref={this.handleContextRef}>
                  <UserVideos
                    publicProfile={publicProfile}
                    profileUser={user}
                    videos={videos}
                  />
                </div>
              </Grid.Column>
            </Grid>
          )}
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
