import React, { Component } from "react";
import { Comment, Header } from "semantic-ui-react";
import AddCommentForm from "./AddCommentForm";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import RenderComments from "./RenderComments";
import { setUserValue } from "../../redux/action/userAction";
import NewVideoModal from "../../components/shared/NewVideoModal/NewVideoModal";
import NoNewVideoAccessModal from "../../components/shared/NoNewVideoAccessModal";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
      selectedCommentId: null,
    };
  }

  addVideoComment = async (videoId, comment, parentId, isPrivate) => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return "loginNeeded";
    }

    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    let newComment = currentUser.userName
      ? {
          isPrivate: isPrivate,
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          userName: currentUser.userName,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
        }
      : {
          isPrivate: isPrivate,
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
        };

    try {
      await firebase.database().ref("comments").child(videoId).push(newComment);

      await firebase
        .database()
        .ref("activities")
        .child(currentUser.uid)
        .push({
          type: "New Comment",
          content: {
            videoId: videoId,
            parentId: parentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser.email || "",
            comment: comment,
          },
          createdAt: Date.now(),
        });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "addVideoComment in Comments",
        error: error,
      });
    }
  };

  onShowReplyInVideoModal = (videoId, commentParentId) => () => {
    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({
        showAddNewVideoModal: true,
        videoId: videoId,
        commentParentId: commentParentId,
      });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  render() {
    const { video, comments, count, user } = this.props;
    const { currentUser } = user;

    if (!video) return 0;

    return (
      <div>
        <NewVideoModal type={"COMMENT"} />
        <NoNewVideoAccessModal type={"COMMENT"} />
        <AddCommentForm
          parentId={0}
          addVideoComment={this.addVideoComment}
          onShowReplyInVideoModal={this.onShowReplyInVideoModal(
            video.titleURL ? video.titleURL : video.playbackIds[0].id,
            0
          )}
          videoId={video.titleURL ? video.titleURL : video.playbackIds[0].id}
          allowCancel={false}
          setUserValue={setUserValue}
          currentUser={currentUser}
          parentComment={true}
          videoOwner={video.user.displayName || ""}
        />
        <Comment.Group threaded>
          {comments &&
            comments.map((comment) => {
              return (
                ((comment.isPrivate &&
                  currentUser &&
                  (comment.uid === currentUser.uid ||
                    video.user.uid === currentUser.uid)) ||
                  !comment.isPrivate) && (
                  <RenderComments
                    key={comment.id}
                    comment={comment}
                    currentUser={currentUser}
                    videoId={
                      video.titleURL ? video.titleURL : video.playbackIds[0].id
                    }
                    isPrivate={comment.isPrivate || false}
                  />
                )
              );
            })}
        </Comment.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Comments);
