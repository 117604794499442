import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/app";
import "firebase/database";

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevCreaterAccess: this.props.currentUser.createrAccess,
      userName: this.props.currentUser.userName
        ? this.props.currentUser.userName
        : "",
      displayName: this.props.currentUser.displayName
        ? this.props.currentUser.displayName
        : "",
      location: this.props.currentUser.location
        ? this.props.currentUser.location
        : "",
      bio: this.props.currentUser.bio ? this.props.currentUser.bio : "",
      twitter:
        this.props.currentUser.socialLinks &&
        this.props.currentUser.socialLinks.twitter
          ? this.props.currentUser.socialLinks.twitter.split("/").length > 1
            ? this.props.currentUser.socialLinks.twitter.split("/").pop()
            : this.props.currentUser.socialLinks.twitter
          : "",
      indiehackers:
        this.props.currentUser.socialLinks &&
        this.props.currentUser.socialLinks.indiehackers
          ? this.props.currentUser.socialLinks.indiehackers.split("/").length >
            1
            ? this.props.currentUser.socialLinks.indiehackers.split("/").pop()
            : this.props.currentUser.socialLinks.indiehackers
          : "",
      facebook:
        this.props.currentUser.socialLinks &&
        this.props.currentUser.socialLinks.facebook
          ? this.props.currentUser.socialLinks.facebook.split("/").length > 1
            ? this.props.currentUser.socialLinks.facebook.split("/").pop()
            : this.props.currentUser.socialLinks.facebook
          : "",
      instagram:
        this.props.currentUser.socialLinks &&
        this.props.currentUser.socialLinks.instagram
          ? this.props.currentUser.socialLinks.instagram.split("/").length > 1
            ? this.props.currentUser.socialLinks.instagram.split("/").pop()
            : this.props.currentUser.socialLinks.instagram
          : "",
      personalWeb:
        this.props.currentUser.socialLinks &&
        this.props.currentUser.socialLinks.personalWeb
          ? this.props.currentUser.socialLinks.personalWeb
          : "",
    };

    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeText = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onUpdateAccount = async () => {
    var createrAccess = false;

    const {
      prevCreaterAccess,
      userName,
      displayName,
      location,
      bio,
      twitter,
      indiehackers,
      facebook,
      instagram,
      personalWeb,
    } = this.state;

    const usernameLower = userName.toLowerCase();

    if (displayName !== "" && location !== "" && bio !== "") {
      createrAccess = true;
    }

    //check if username is changed. then check if username is taken or available
    if (this.props.currentUser.userName !== usernameLower) {
      //check is new userName is valid in regex
      var usernameRegex = /^([a-zA-Z0-9\_]{2,})+$/;
      if (usernameLower.match(usernameRegex) === null) {
        //didn't pass the username validator, toast error and return
        if (usernameLower.length < 2) {
          toast.error("Username must be at least 2 characters long", {
            autoClose: 4000,
          });
          return;
        }
        toast.error('Your username can only contain letters, numbers and "_"', {
          autoClose: 4000,
        });
        return;
      }

      const usernameRef = await firebase
        .database()
        .ref("usernames")
        .child(usernameLower)
        .once("value");

      if (usernameRef.exists() === false) {
        await firebase //add new username
          .database()
          .ref("usernames")
          .child(usernameLower)
          .set({ uid: this.props.currentUser.uid });

        this.props.currentUser.userName &&
          (await firebase //remove old username
            .database()
            .ref("usernames")
            .child(this.props.currentUser.userName)
            .remove());

        await firebase //update username in /users/{userId}
          .database()
          .ref("users")
          .child(this.props.currentUser.uid)
          .update({
            userName: usernameLower,
            usernameSet: true,
          });

        await firebase
          .database()
          .ref("new-members")
          .child(this.props.currentUser.uid)
          .update({
            userName: usernameLower,
          });
      } else {
        //username is taken
        toast.error("Sorry, this username is taken.", {
          autoClose: 4000,
        });
        return;
      }
    }

    await firebase
      .database()
      .ref("users")
      .child(this.props.currentUser.uid)
      .update({
        createrAccess: createrAccess,
        displayName: displayName,
        location: location,
        bio: bio,
        socialLinks: {
          twitter: twitter,
          indiehackers: indiehackers,
          facebook: facebook,
          instagram: instagram,
          personalWeb:
            personalWeb !== "" && personalWeb.toLowerCase().indexOf("http") < 0
              ? "http://" + personalWeb
              : personalWeb,
        },
      });

    await firebase
      .database()
      .ref("new-members")
      .child(this.props.currentUser.uid)
      .update({
        displayName: displayName,
        bio: bio,
      });

    toast.success("Your info is saved.", {
      autoClose: 3000,
    });

    if (prevCreaterAccess !== createrAccess) {
      if (createrAccess) {
        toast.success("Congrats, you now have creator's access!", {
          autoClose: 4000,
        });
      } else {
        toast.error("Sorry, you lost creator's access :(", {
          autoClose: 4000,
        });
      }
    }
  };

  render() {
    const {
      userName,
      displayName,
      location,
      bio,
      twitter,
      indiehackers,
      facebook,
      instagram,
      personalWeb,
    } = this.state;
    const { currentUser } = this.props;

    return (
      <div className="col-sm-9">
        <div className="border">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Account
              </a>
              <a
                className="nav-item nav-link"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Social Links
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="form" id="registrationForm">
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Username</label>
                    </div>
                    <div className="col-6">
                      <input
                        name="userName"
                        type="text"
                        className="form-control"
                        placeholder="Choose a username (only set once)"
                        onChange={this.onChangeText}
                        value={userName}
                        maxLength="20"
                        disabled={currentUser.usernameSet ? true : false}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Display Name</label>
                    </div>
                    <div className="col-6">
                      <input
                        name="displayName"
                        type="text"
                        className="form-control"
                        placeholder="Choose a display name"
                        onChange={this.onChangeText}
                        value={displayName}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Living In</label>
                    </div>
                    <div className="col-6">
                      <input
                        name="location"
                        type="text"
                        className="form-control"
                        placeholder="e.g. San Francisco, CA"
                        onChange={this.onChangeText}
                        value={location}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Bio</label>
                    </div>
                    <div className="col-6">
                      <textarea
                        name="bio"
                        className="form-control"
                        placeholder="Add your bio"
                        rows="4"
                        onChange={this.onChangeText}
                        value={bio}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="form-group text-right border-less">
                  <div className="col-12">
                    <button
                      className="btn btn-lg btn-primary"
                      onClick={this.onUpdateAccount}
                    >
                      <i className="fas fa-save "></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="form" id="registrationForm">
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Twitter</label>
                    </div>
                    <div className="input-group col-6">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">
                          https://twitter.com/
                        </span>
                      </div>
                      <input
                        name="twitter"
                        type="text"
                        className="form-control"
                        onChange={this.onChangeText}
                        value={
                          twitter.split("/").length > 1
                            ? twitter.split("/").pop()
                            : twitter
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">IndieHackers</label>
                    </div>
                    <div className="input-group col-6">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">
                          https://indiehackers.com/
                        </span>
                      </div>
                      <input
                        name="indiehackers"
                        type="text"
                        className="form-control"
                        onChange={this.onChangeText}
                        value={
                          indiehackers.split("/").length > 1
                            ? indiehackers.split("/").pop()
                            : indiehackers
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Facebook</label>
                    </div>
                    <div className="input-group col-6">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">
                          https://facebook.com/
                        </span>
                      </div>
                      <input
                        name="facebook"
                        type="text"
                        className="form-control"
                        onChange={this.onChangeText}
                        value={
                          facebook.split("/").length > 1
                            ? facebook.split("/").pop()
                            : facebook
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Instagram</label>
                    </div>
                    <div className="input-group col-6">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon3">
                          https://instagram.com/
                        </span>
                      </div>
                      <input
                        name="instagram"
                        type="text"
                        className="form-control"
                        onChange={this.onChangeText}
                        value={
                          instagram.split("/").length > 1
                            ? instagram.split("/").pop()
                            : instagram
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label className="input_label">Personal website</label>
                    </div>
                    <div className="col-6">
                      <input
                        name="personalWeb"
                        type="text"
                        className="form-control"
                        placeholder="mywebsite.com"
                        onChange={this.onChangeText}
                        value={personalWeb}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-right border-less">
                  <div className="col-12">
                    <button
                      className="btn btn-lg btn-primary"
                      onClick={this.onUpdateAccount}
                    >
                      <i className="fas fa-save"></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileSettings;
