/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { withRouter } from "react-router";
import { Button, Modal } from "react-bootstrap";
import Avatar from "react-avatar-edit";
import NotificationSettings from "./NotificationSettings";
import "./Settings.css";

var unsubscribe;
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkProfile: true,
      currentUser: null,
      showChangeAvatarModal: false,
      preview: null,
      avatarSrc: null,
      updatedAvatar: "",
    };
    this.onAvatarCrop = this.onAvatarCrop.bind(this);
    this.onAvatarClose = this.onAvatarClose.bind(this);
  }

  componentDidMount() {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .once("value");
        this.setState({ currentUser: userRef.val() });
      } else {
        this.props.history.push("/");
      }
    });
  }

  componentWillUnmount() {
    unsubscribe();
  }

  onCheckProfile = () => {
    this.setState({ checkProfile: true });
  };

  onCheckAccount = () => {
    this.setState({ checkProfile: false });
  };

  handleModalClose = () => {
    this.setState({ showChangeAvatarModal: false });
  };

  onShowChangeAvatarModal = () => {
    this.setState({ showChangeAvatarModal: true });
  };

  onAvatarClose() {
    this.setState({ preview: null });
  }

  onAvatarCrop(preview) {
    console.log("crop preview: ", preview);
    this.setState({ preview });
  }

  onImageLoad(image) {
    console.log("image: ", image.target.files[0]);
  }

  handleUploadImage = async () => {
    const { currentUser, preview } = this.state;
    var storageRef = await firebase.storage().ref(currentUser.uid);
    var fileRef = storageRef.child("avatar");
    var type = preview.split(";")[0].split(":")[1];

    var uploadedFile = await fileRef.putString(
      preview.split(",")[1],
      "base64",
      {
        contentType: type,
      }
    );
    var fileURL = await uploadedFile.ref.getDownloadURL();
    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .update({ avatar: fileURL });
    this.setState({ updatedAvatar: fileURL, showChangeAvatarModal: false });
  };

  render() {
    const { currentUser, showChangeAvatarModal } = this.state;

    if (currentUser === null) {
      return null;
    }

    return (
      <Fragment>
        <Modal
          show={showChangeAvatarModal}
          onHide={this.handleModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Update your avatar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Avatar
              width={"100%"}
              height={300}
              onCrop={this.onAvatarCrop}
              onClose={this.onAvatarClose}
              src={this.state.avatarSrc}
              label="Choose a photo"
              onImageLoad={this.onImageLoad}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary px-5" onClick={this.handleUploadImage}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <main className="bg-light settings-container">
          <div className="headerSpace container"></div>
          <div className="container mt-5 px-5">
            <h3 className="title"></h3>
            <div className="row mtop-minus">
              <div className="col-12">
                <span className="title">Settings</span>
              </div>

              {/* Left column navigator */}
              <div className="col-sm-3 user_col">
                <ul className="list-group border-less">
                  <li className="list-group-item">
                    <Link
                      onClick={this.onCheckAccount}
                      className={"list-item-selected"}
                    >
                      <i
                        className="fa fa-bell"
                        aria-hidden="true"
                        style={{ width: 16 }}
                      ></i>{" "}
                      Notifications
                    </Link>
                  </li>
                </ul>
              </div>
              {/* End left column navigator */}

              <NotificationSettings currentUser={currentUser} />
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default withRouter(Settings);
