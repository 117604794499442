import React from "react";
import { isSameWeek } from "date-fns";
import firebase from "firebase/app";
import "firebase/database";
import { Helmet } from "react-helmet";
import PulseLoader from "react-spinners/PulseLoader";
import AllProductsBanner from "./AllProductsBanner";
import ProductList from "../../components/ProductList/ProductList";
import JustJoinedProducts from "../../components/shared/JustJoinedProducts/JustJoinedProducts";
import WeeklyTopProducts from "../../components/shared/WeeklyTopProducts/WeeklyTopProducts";
import MonthlyTopProducts from "../../components/shared/MonthlyTopProducts/MonthlyTopProducts";
import "./AllProducts.css";

class AllProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsArray: [],
      contextRef: {},
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    const productsRef = await firebase
      .database()
      .ref("products")
      .orderByChild("videoCount")
      .once("value");

    var productsArray = [];
    var productsThisWeek = [];
    productsRef.forEach((product) => {
      // const ownerUid = product.val().ownerId;
      // firebase
      //   .database()
      //   .ref("users")
      //   .child(ownerUid)
      //   .once("value", (snapshot) => {
      //     const ownerObj = snapshot.val();
      //     const userObj = {
      //       uid: ownerObj.uid,
      //       userName: ownerObj.userName || "",
      //       displayName: ownerObj.displayName,
      //       avatar: ownerObj.avatar,
      //       bio: ownerObj.bio || "",
      //     };
      //     product.ref.update({ user: userObj });
      //   });

      if (
        product.val().videos &&
        Object.values(product.val().videos).length > 0
      ) {
        productsArray.push(product.val());
      }

      if (
        isSameWeek(Date.now(), product.val().createdAt, { weekStartsOn: 1 })
      ) {
        productsThisWeek.push(product.val());
      }
    });

    var weeklyProductsArray = [];
    await firebase
      .database()
      .ref("products-this-week")
      .orderByChild("count")
      .limitToLast(10)
      .once("value", (snapshot) => {
        snapshot.forEach((product) => {
          weeklyProductsArray.push(product.val());
        });
        // firebase.database().ref("products-this-month").set(snapshot.val());
      });

    var monthlyProductsArray = [];
    await firebase
      .database()
      .ref("products-this-month")
      .orderByChild("count")
      .limitToLast(10)
      .once("value", (snapshot) => {
        snapshot.forEach((product) => {
          monthlyProductsArray.push(product.val());
        });
      });

    this.setState({
      weeklyProducts: weeklyProductsArray.reverse(),
      monthlyProducts: monthlyProductsArray.reverse(),
    });

    this.setState({
      allProducts: productsArray.reverse(),
      productsThisWeek: productsThisWeek.reverse(),
      isLoading: false,
    });
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const {
      allProducts,
      productsThisWeek,
      weeklyProducts,
      monthlyProducts,
      isLoading,
    } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <main className="bg-light products-container">
            <div className="text-center" style={{ marginTop: 150 }}>
              <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
            </div>
          </main>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{"Products - IndieLog"}</title>
          <meta
            name="description"
            content={"Awesome products built by our awesome makers!"}
          />
        </Helmet>

        <main className="bg-light products-container">
          <AllProductsBanner />
          <div className="container">
            <div className="row" ref={this.handleContextRef}>
              <div className="col-lg-8">
                <ProductList products={allProducts} />
              </div>
              <div className="col-lg-4 d-none d-md-none d-lg-block">
                <JustJoinedProducts products={productsThisWeek} />
                <WeeklyTopProducts products={weeklyProducts} />
                <MonthlyTopProducts
                  products={monthlyProducts}
                  contextRef={this.state.contextRef}
                />
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AllProducts;
