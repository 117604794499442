import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { Dropdown, Image, Icon } from "semantic-ui-react";
import LoginModal from "../Modals/Login/LoginModal";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { connect } from "react-redux";
import { compose } from "redux";
import Badge from "@material-ui/core/Badge";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import VideocamIcon from "@material-ui/icons/Videocam";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import WebIcon from "@material-ui/icons/Web";
import MdAdd from "@material-ui/icons/Add";
import MdClose from "@material-ui/icons/Clear";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Tooltip from "@material-ui/core/Tooltip";
import "./Topbar.css";
import AddNewMeetupModal from "../shared/AddNewMeetup/AddNewMeetupModal";
import AddNewProductModal from "../shared/AddNewProduct/AddNewProductModal";
import NewVideoModal from "../shared/NewVideoModal/NewVideoModal";
import default_logo from "../../assets/images/indielog_logo.png";
import { setUserValue } from "../../redux/action/userAction";
import RequestMeetupHostModal from "../../components/shared/RequestMeetupHostModal";

var unsubscribe;
let currentUserListener;

const hostURL = window.location.host.split(".").reverse();
const cookieDomain =
  hostURL.length > 1 ? "." + hostURL[1] + "." + hostURL[0] : null;

const objectToArray = (object) => {
  if (object) {
    return Object.entries(object).map((e) => Object.assign(e[1], { id: e[0] }));
  }
};
class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isLoading: true,
      notifications: [],
      isFabOpen: false,
    };
    this.toggleLine = this.toggleLine.bind(this);
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  componentDidMount() {
    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({ currentUser: snapshot.val() });
            this.props.setUserValue({ currentUser: snapshot.val() });
          });

        await firebase
          .database()
          .ref("notifications")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({
              notifications: objectToArray(snapshot.val()) || [],
            });
          });
      } else {
        this.setState({ currentUser: null });
      }
      this.setState({ isLoading: false });
    });
  }

  componentWillUnmount() {
    unsubscribe();
  }

  handleModalClose = () => {
    this.props.setUserValue({
      showSignInModal: false,
      showSignOutModal: false,
      showTelegramModal: false,
      showLoginForm: false,
    });
  };

  onSignInWithToken = async (token) => {
    try {
      await firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      await firebase.database().ref("errors").push({
        where: "onSignInWithToken",
        error: error,
        errorCode: errorCode,
        errorMessage: errorMessage,
        createdAt: Date.now(),
      });
    }
  };

  onSignIn = () => {
    this.props.setUserValue({ showSignInModal: true, showLoginForm: true });
  };

  onOpenSignOutModal = () => {
    this.props.setUserValue({ showSignOutModal: true });
  };

  onSignOut = async () => {
    const userUid = this.state.currentUser.uid;
    try {
      await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener);
      await firebase.auth().signOut();
      this.props.setUserValue({ currentUser: null });
      this.handleModalClose();
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "onSignOut",
        error: error,
        userUid: userUid,
      });
    }
    this.props.history.push("/");
  };

  showSignOutForm = () => {
    return (
      <div className="row">
        <div className="col-6 text-center">
          <button
            className=" btn btn-danger my-1"
            onClick={() => {
              this.onSignOut();
            }}
          >
            Sign out
          </button>
        </div>
        <div className="col-6 text-center">
          <button
            className="btn btn-secondary my-1"
            onClick={() => this.handleModalClose()}
          >
            Go back
          </button>
        </div>
      </div>
    );
  };

  gotoDashboard = () => {
    this.props.history.push("/dashboard");
  };

  gotoProfile = () => {
    this.props.history.push("/profile");
  };

  gotoInvite = () => {
    this.props.history.push("/invite");
  };

  gotoSettings = () => {
    this.props.history.push("/settings");
  };

  hostMeetup = () => {
    const { currentUser } = this.props.user;
    if (!currentUser.canHostMeetup) {
      this.props.setUserValue({ showRequestMeetupHostModal: true });
    } else {
      this.props.setUserValue({ showAddNewMeetupModal: true });
    }
  };

  showNotifications = () => {
    if (window.location.pathname !== "/notifications") {
      this.props.history.push("/notifications");
    } else {
      this.props.history.goBack();
    }
  };

  onShowTelegramModal = () => {
    this.props.setUserValue({ showTelegramModal: true });
  };

  onShowAddNewVideoModal = () => {
    this.setState({ isFabOpen: false });

    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({ showAddNewVideoModal: true });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  onShowAddNewProductModal = () => {
    this.setState({ isFabOpen: false });

    const { currentUser } = this.props.user;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    this.props.setUserValue({ showAddNewProductModal: true });
  };

  render() {
    const { currentUser, isLoading, notifications } = this.state;

    const {
      showSignInModal,
      showSignOutModal,
      showTelegramModal,
      showLoginForm,
    } = this.props.user;

    const userMenuTrigger = (
      <span>
        <Image avatar src={currentUser && currentUser.avatar} />
      </span>
    );

    const userMenuOptions = [
      {
        key: "dashboard",
        text: "Dashboard",
        icon: "grid layout",
        onClick: this.gotoDashboard,
      },
      {
        key: "profile",
        text: "Profile",
        icon: "user outline",
        onClick: this.gotoProfile,
      },
      {
        key: "invite",
        text: "Invite friends",
        icon: "send",
        onClick: this.gotoInvite,
      },
      {
        key: "settings",
        text: "Settings",
        icon: "sun outline",
        onClick: this.gotoSettings,
      },
      {
        key: "meetup",
        text: "Host a meetup",
        icon: "coffee",
        onClick: this.hostMeetup,
      },
      {
        key: "sign-out",
        text: "Sign Out",
        icon: "arrow alternate circle right outline",
        onClick: this.onOpenSignOutModal,
      },
    ];

    return (
      <React.Fragment>
        <Modal
          show={showSignInModal}
          onHide={this.handleModalClose}
          centered
          size="lg"
        >
          <Modal.Body style={{ padding: 0 }}>
            <LoginModal
              handleModalClose={this.handleModalClose}
              showLoginForm={showLoginForm}
            />
          </Modal.Body>
        </Modal>
        <Modal show={showSignOutModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Do you want to sign out?</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.showSignOutForm()}</Modal.Body>
        </Modal>
        <Modal show={showTelegramModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Telegram group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Join our Telegram group to have real-time conversation with others.
            Together we make better products 🤗
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-danger my-1"
              href="https://t.me/indielog"
              target="_blank"
            >
              Join
            </Button>
          </Modal.Footer>
        </Modal>
        <AddNewMeetupModal />
        <AddNewProductModal />
        <NewVideoModal type={"NEW-VIDEO"} />
        <RequestMeetupHostModal />
        <header id="topnav" className="nav-sticky">
          <div className="container-fluid">
            <div className="col-8" style={{ paddingLeft: 0 }}>
              <Link className="row logo" to={"/"} style={{ paddingTop: "3px" }}>
                <img src={default_logo} alt="" className="logo-img" />
                <span>IndieLog</span>
              </Link>
            </div>

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              <ul
                className="navigation-menu"
                id="top-menu"
                style={{
                  float: "left",
                  paddingTop: "3px",
                  marginLeft: "10px",
                }}
              >
                <li style={{ paddingRight: 0, marginRight: 0 }}>
                  <a href="/topics">Topics</a>
                </li>
                <li style={{ paddingRight: 0, marginRight: 0 }}>
                  <a href="/products">Products</a>
                </li>
                <li
                  style={{ paddingRight: 0, marginRight: 0 }}
                  className="meetup-tab"
                >
                  <a href="/meetups">
                    <span className="inline-flex items-center px-3 py-1 rounded leading-5 bg-blue-100 text-blue-800 ">
                      Meetups
                    </span>
                  </a>
                </li>
                <li style={{ marginLeft: 0 }}>
                  <a href="/indietuber">IndieTuber</a>
                </li>
              </ul>
            </div>

            {isLoading ? null : (
              <div className="login-button">
                {currentUser ? (
                  <div className="row" style={{ marginTop: "2px" }}>
                    <div
                      onClick={() => this.showNotifications()}
                      style={{
                        lineHeight: "68px",
                        cursor: "pointer",
                        marginRight: "40px",
                      }}
                    >
                      {notifications.length > 0 ? (
                        <Badge color="secondary" badgeContent=" " variant="dot">
                          <i className="far fa-bell fa-2x" />
                        </Badge>
                      ) : (
                        <Badge>
                          <i className="far fa-bell fa-2x" />
                        </Badge>
                      )}
                    </div>
                    <Dropdown
                      trigger={userMenuTrigger}
                      options={userMenuOptions}
                      pointing="top right"
                      icon={null}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      onClick={this.onSignIn}
                      className="btn btn-outline-primary"
                    >
                      Log in
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </header>
        <FloatingMenu
          slideSpeed={500}
          direction="up"
          spacing={8}
          isOpen={this.state.isFabOpen}
          className="fab-menu"
        >
          <MainButton
            iconResting={<MdAdd style={{ fontSize: 20, color: "#fff" }} />}
            iconActive={<MdClose style={{ fontSize: 20, color: "#fff" }} />}
            className="fab-main-btn"
            onClick={() => this.setState({ isFabOpen: !this.state.isFabOpen })}
            size={56}
          />
          <ChildButton
            icon={
              <Tooltip
                title={<p style={{ fontSize: "14px" }}>Send feedback</p>}
                placement="left"
              >
                <EmojiObjectsIcon style={{ fontSize: 20, color: "#fff" }} />
              </Tooltip>
            }
            className="fab-child-btn"
            size={40}
            onClick={() => {
              this.setState({ isFabOpen: false });
              window.open("https://feedback.indielog.com");
            }}
          />
          <ChildButton
            icon={
              <Tooltip
                title={<p style={{ fontSize: "14px" }}>Add your product</p>}
                placement="left"
              >
                <WebIcon style={{ fontSize: 20, color: "#fff" }} />
              </Tooltip>
            }
            className="fab-child-btn"
            size={40}
            onClick={this.onShowAddNewProductModal}
          />
          {/* <ChildButton
            icon={
              <Tooltip
                title={<p style={{ fontSize: "14px" }}>Add a new todo</p>}
                placement="left"
              >
                <FormatListBulletedIcon
                  style={{ fontSize: 20, color: "#fff" }}
                />
              </Tooltip>
            }
            className="fab-child-btn"
            size={40}
            onClick={this.onShowAddNewProductModal}
          /> */}
          <ChildButton
            icon={
              <Tooltip
                title={<p style={{ fontSize: "14px" }}>Record a video</p>}
                placement="left"
              >
                <VideocamIcon style={{ fontSize: 20, color: "#fff" }} />
              </Tooltip>
            }
            className="fab-child-btn"
            size={40}
            onClick={this.onShowAddNewVideoModal}
          />
        </FloatingMenu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Topbar);
