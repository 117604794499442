import React, { Component } from "react";
import { Comment, Dropdown } from "semantic-ui-react";
import AddCommentForm from "./AddCommentForm";
import { Link } from "react-router-dom";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "antd";
import firebase from "firebase/app";
import "firebase/database";
import { formatDistance } from "date-fns";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import Linkify from "linkifyjs/react";
import { toast } from "react-toastify";
import { Modal, ListGroup, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import VideoPlayer from "./VideoPlayer";
import { setUserValue } from "../../redux/action/userAction";
import CodeBlock from "../../utils/CodeBlock";
import LinkRenderer from "../../utils/LinkRenderer";

class RenderComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyForm: false,
      selectedCommentId: null,
      showVideoModal: false,
      showVideoId: "",
      showReportVideoModal: false,
      reportReason: "",
      reportComment: null,
      isEditComment: false,
    };
  }

  handleCloseReplyForm = () => {
    this.setState({
      selectedCommentId: null,
      showReplyForm: false,
      isEditComment: false,
    });
    this.props.setUserValue({ showAddNewVideoModal: false });
  };

  handleOpenReplyForm = (id) => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id,
      });
    }
  };

  handleOpenEditReplyForm = (id) => () => {
    const { currentUser, setUserValue } = this.props;
    if (!currentUser) {
      setUserValue({ showSignInModal: true });
    } else {
      this.setState({
        showReplyForm: true,
        selectedCommentId: id,
        isEditComment: true,
      });
    }
  };

  onShowReplyInVideoModal = (videoId, commentParentId) => () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({
        showAddNewVideoModal: true,
        videoId: videoId,
        commentParentId: commentParentId,
      });
    } else {
      this.props.setUserValue({ showNoNewVideoAccessModal: true });
    }
  };

  addVideoComment = async (videoId, comment, parentId, isPrivate) => {
    const { currentUser } = this.props;
    if (!currentUser) {
      toast.error("Sorry, You have to log in first. :(", {
        autoClose: 4000,
      });
      return alert("You have to log in first.");
    }
    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    let newComment = currentUser.userName
      ? {
          isPrivate: isPrivate,
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          userName: currentUser.userName,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
          type: "TEXT",
        }
      : {
          isPrivate: isPrivate,
          parentId: parentId,
          displayName: currentUser.displayName,
          photoURL: currentUser.avatar,
          uid: currentUser.uid,
          email: currentUser.email || "",
          comment: comment,
          date: Date.now(),
          type: "TEXT",
        };

    try {
      await firebase.database().ref("comments").child(videoId).push(newComment);

      await firebase
        .database()
        .ref("activities")
        .child(currentUser.uid)
        .push({
          type: "New Comment",
          content: {
            videoId: videoId,
            parentId: parentId,
            displayName: currentUser.displayName,
            photoURL: currentUser.avatar,
            uid: currentUser.uid,
            email: currentUser || "",
            comment: comment,
          },
          createdAt: Date.now(),
        });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "addVideoComment in RenderComments",
        error: error,
      });
    }
  };

  editVideoComment = async (videoId, comment, parentId) => {
    const { currentUser } = this.props;
    if (!currentUser) {
      toast.error("Sorry, You have to log in first. :(", {
        autoClose: 4000,
      });
      return alert("You have to log in first.");
    }
    if (comment === "") {
      toast.error("Sorry, comment cannot be empty :(", {
        autoClose: 4000,
      });
      return;
    }

    try {
      await firebase
        .database()
        .ref("comments")
        .child(videoId)
        .child(parentId)
        .update({ comment: comment, updatedAt: Date.now() });
    } catch (error) {
      await firebase.database().ref("errors").push({
        where: "editVideoComment in RenderComments",
        error: error,
      });
    }
  };

  onClickVideoReply = (videoPlaybackId) => () => {
    this.setState({ showVideoModal: true, showVideoId: videoPlaybackId });
  };

  handleModalClose = () => {
    this.setState({ showVideoModal: false, showReportVideoModal: false });
  };

  reportVideo = (comment) => () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    this.setState({ showReportVideoModal: true, reportComment: comment });
  };

  sendReport = async () => {
    const { reportComment, reportReason } = this.state;
    const { videoId, currentUser } = this.props;

    //add the report to /report/{reportId}
    await firebase
      .database()
      .ref("comment-reports")
      .child(videoId)
      .child(reportComment.id)
      .set({
        comment: reportComment,
        commentVideoURL:
          "https://stream.mux.com/" + reportComment.playbackIds[0].id + ".m3u8",
        reason: reportReason,
        judge: "pending",
        reportedBy: currentUser,
      });
    //update the report status in comment's object
    await firebase
      .database()
      .ref("comments")
      .child(videoId)
      .child(reportComment.id)
      .update({ badComment: "pending" });

    toast.success("Thanks for reporting! We will take a look soon.", {
      autoClose: 4000,
    });
    this.setState({ showReportVideoModal: false });
  };

  onLike = (comment) => async () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    await firebase
      .database()
      .ref("comments")
      .child(this.props.videoId)
      .child(comment.id)
      .child("likedBy")
      .child(currentUser.uid)
      .set({
        avatar: currentUser.avatar,
        name: currentUser.displayName,
        userName: currentUser.userName || "",
        userId: currentUser.uid,
      });
  };

  onUnlike = (comment) => async () => {
    const { currentUser } = this.props;

    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    await firebase
      .database()
      .ref("comments")
      .child(this.props.videoId)
      .child(comment.id)
      .child("likedBy")
      .child(currentUser.uid)
      .remove();
  };

  goToUserProfile = (user) => () => {
    console.log("goto ");
    this.props.history.push(
      "/user/" + (user.userName ? user.userName : user.userId)
    );
  };

  render() {
    const {
      videoId,
      comment,
      currentUser,
      setUserValue,
      isPrivate,
    } = this.props;
    const {
      showVideoModal,
      showVideoId,
      showReplyForm,
      selectedCommentId,
      showReportVideoModal,
      isEditComment,
    } = this.state;
    var nestedComments;
    if (comment && comment.childNodes && comment.childNodes.length > 0) {
      nestedComments = (
        <Comment.Group threaded>
          {(comment.childNodes || []).map((comment, index) => {
            return (
              <RenderComments
                key={index}
                comment={comment}
                currentUser={currentUser}
                setUserValue={setUserValue}
                videoId={videoId}
                isPrivate={isPrivate}
                history={this.props.history}
              />
            );
          })}
        </Comment.Group>
      );
    } else {
      nestedComments = null;
    }

    const videoPoster =
      showVideoId !== "" &&
      "https://image.mux.com/" + showVideoId + "/thumbnail.png";
    const videoURL =
      showVideoId !== "" && "https://stream.mux.com/" + showVideoId + ".m3u8";

    const videoJsOptions = {
      responsive: true,
      fill: true,
      autoplay: true,
      controls: true,
      fluid: true,
      // aspectRatio: "16:9"
    };

    return (
      <>
        <Modal //report video
          show={showReportVideoModal}
          onHide={this.handleModalClose}
          top
        >
          <Modal.Header closeButton>
            <Modal.Title>Why are you reporting this video reply?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Inappropriate" })}
              >
                Inappropriate
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Off-Topic" })}
              >
                Off-Topic
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Spam" })}
              >
                Spam
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() =>
                  this.setState({ reportReason: "Sexual, Violence" })
                }
              >
                Sexual, Violence
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setState({ reportReason: "Others" })}
              >
                Others
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary px-5" onClick={this.sendReport}>
              Go Report
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal //show video
          show={showVideoModal}
          onHide={this.handleModalClose}
          size="lg"
          centered
        >
          <Modal.Body style={{ padding: 0 }}>
            <VideoPlayer
              {...videoJsOptions}
              poster={videoPoster}
              src={videoURL}
            />
          </Modal.Body>
        </Modal>
        <Comment key={comment.id}>
          <Comment.Avatar
            src={comment.photoURL || "/assets/user.png"}
            as={Link}
            to={
              comment.userName
                ? `/user/${comment.userName}`
                : `/user/${comment.uid}`
            }
          />
          {!isEditComment ? (
            <Comment.Content>
              <Comment.Author
                as={Link}
                to={
                  comment.userName
                    ? `/user/${comment.userName}`
                    : `/user/${comment.uid}`
                }
              >
                {comment.displayName}
              </Comment.Author>
              <Comment.Metadata>
                <div>{formatDistance(comment.date, Date.now())} ago</div>
                {isPrivate && <span className="private-message">private</span>}
              </Comment.Metadata>
              <Comment.Text>
                {comment.type === "VIDEO" &&
                comment.playbackIds &&
                comment.playbackIds[0] &&
                comment.playbackIds[0].id ? (
                  !comment.badComment ||
                  (comment.badComment && comment.badComment === "no") ? (
                    <img
                      src={
                        "https://image.mux.com/" +
                        comment.playbackIds[0].id +
                        "/animated.gif?width=150"
                      }
                      alt=""
                      className="video_reply_thumbnail"
                      onClick={this.onClickVideoReply(
                        comment.playbackIds[0].id
                      )}
                    />
                  ) : (
                    <span style={{ backgroundColor: "antiquewhite" }}>
                      This video reply is reported.
                    </span>
                  )
                ) : (
                  <Linkify style={{ whiteSpace: "per-line" }}>
                    <ReactMarkdown
                      source={comment.comment}
                      renderers={{ code: CodeBlock, link: LinkRenderer }}
                    />
                  </Linkify>
                )}
              </Comment.Text>
              <Comment.Actions>
                <Comment.Action onClick={this.handleOpenReplyForm(comment.id)}>
                  <Dropdown text="Reply">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        icon="paper plane outline"
                        text="Text Reply"
                        onClick={this.handleOpenReplyForm(comment.id)}
                      />
                      <Dropdown.Item
                        icon="video"
                        text="Video Reply"
                        onClick={this.onShowReplyInVideoModal(
                          videoId,
                          comment.id
                        )}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Comment.Action>
                {currentUser &&
                  comment &&
                  currentUser.uid === comment.uid &&
                  comment.type !== "VIDEO" && (
                    <Comment.Action
                      onClick={this.handleOpenEditReplyForm(comment.id)}
                    >
                      Edit
                    </Comment.Action>
                  )}
                {comment.type === "VIDEO" && (
                  <Comment.Action onClick={this.reportVideo(comment)}>
                    <i className="fas fa-exclamation-circle fa-sm"></i>
                  </Comment.Action>
                )}

                {comment.likedBy &&
                currentUser &&
                comment.likedBy[currentUser.uid] ? (
                  <Comment.Action onClick={this.onUnlike(comment)}>
                    <i
                      className="fas fa-heart fa-sm"
                      style={{ color: "#f13a59" }}
                    ></i>
                  </Comment.Action>
                ) : (
                  <Comment.Action onClick={this.onLike(comment)}>
                    <i
                      className="far fa-heart fa-sm"
                      style={{ color: "#f13a59" }}
                    ></i>
                  </Comment.Action>
                )}

                <Comment.Action>
                  <AvatarGroup max={11}>
                    {comment.likedBy &&
                      Object.values(comment.likedBy).map((user, index) => {
                        return (
                          <Tooltip
                            title={user.name}
                            placement="bottom"
                            key={index}
                          >
                            <Avatar
                              alt={user.name}
                              src={user.avatar}
                              className="comment-liked-by"
                              onClick={this.goToUserProfile(user)}
                            />
                          </Tooltip>
                        );
                      })}
                  </AvatarGroup>
                </Comment.Action>

                {showReplyForm && selectedCommentId === comment.id && (
                  <AddCommentForm
                    addVideoComment={this.addVideoComment}
                    videoId={videoId}
                    closeForm={this.handleCloseReplyForm}
                    parentId={comment.id}
                    allowCancel={true}
                    parentComment={false}
                    isEditComment={isEditComment}
                    comment={comment}
                  />
                )}
              </Comment.Actions>
            </Comment.Content>
          ) : (
            <Comment.Content>
              <Comment.Author
                as={Link}
                to={
                  comment.userName
                    ? `/user/${comment.userName}`
                    : `/user/${comment.uid}`
                }
              >
                {comment.displayName}
              </Comment.Author>
              <Comment.Metadata>
                <div>{formatDistance(comment.date, Date.now())} ago</div>
              </Comment.Metadata>
              {showReplyForm && comment && selectedCommentId === comment.id && (
                <AddCommentForm
                  addVideoComment={this.addVideoComment}
                  editVideoComment={this.editVideoComment}
                  videoId={videoId}
                  closeForm={this.handleCloseReplyForm}
                  parentId={comment.id}
                  allowCancel={true}
                  parentComment={false}
                  isEditComment={isEditComment}
                  comment={comment}
                />
              )}
            </Comment.Content>
          )}

          {nestedComments}
        </Comment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RenderComments);
