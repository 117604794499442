import React from "react";
import { Modal, Button } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { format } from "date-fns";
import {
  FacebookIcon,
  TwitterIcon,
  RedditIcon,
  LineIcon,
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LineShareButton,
} from "react-share";
import embed_img from "../../assets/images/embed.png";
import { setUserValue } from "../../redux/action/userAction";

class AmaDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSocialShare: false,
      currentURL: window.location.href,
      showEmbedModal: false,
    };
  }

  handleModalClose = () => {
    this.setState({ showSocialShare: false, showEmbedModal: false });
  };

  onShowSocialShareModal = () => {
    this.setState({ showSocialShare: true });
  };

  onShowEmbedModal = () => {
    this.setState({ showSocialShare: false, showEmbedModal: true });
  };

  onLikeAMA = async () => {
    const { currentUser } = this.props.user;
    const { video } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }
    const amaRef = await firebase
      .database()
      .ref("AMAs")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedCount");
    amaRef.transaction(function (currentLikedCount) {
      return (currentLikedCount || 0) + 1;
    });

    await firebase
      .database()
      .ref("AMAs")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedBy")
      .child(currentUser.uid)
      .set({ liked: true });
  };

  onUnlikeAMA = async () => {
    const { currentUser } = this.props.user;
    const { video } = this.props;
    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    const amaRef = await firebase
      .database()
      .ref("AMAs")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedCount");
    amaRef.transaction(function (currentLikedCount) {
      return currentLikedCount - 1;
    });

    await firebase
      .database()
      .ref("AMAs")
      .child(video.titleURL ? video.titleURL : video.playbackIds[0].id)
      .child("likedBy")
      .child(currentUser.uid)
      .remove();
  };

  copyEmbedCode = () => {
    this.setState({ showSocialShare: false, showEmbedModal: false });
  };

  render() {
    const { showSocialShare, showEmbedModal, currentURL } = this.state;
    const { video, user } = this.props;
    const { currentUser } = user;

    const currentVideoURL =
      "https://stream.mux.com/" + video.playbackIds[0].id + ".m3u8";

    const embed_code = `<script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
    <video id="video" controls></video>
    <script>
      if(Hls.isSupported()) {
        var video = document.getElementById('video');
        var hls = new Hls();
        hls.loadSource("${currentVideoURL}");
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
          video.play();
      });
     } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = "${currentVideoURL}";
        video.addEventListener('canplay',function() {
          video.play();
       });
     }
</script>`;

    if (!video) return null;

    return (
      <>
        <Modal show={showSocialShare} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <img
                src={embed_img}
                className="embed-icon"
                alt="Embed code"
                onClick={this.onShowEmbedModal}
              />
              <FacebookShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
              >
                <FacebookIcon size={60} round={true} className="mr-4" />
              </FacebookShareButton>
              <TwitterShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
                via={"indie_log"}
                related={["indie_log"]}
                title={video.title}
              >
                <TwitterIcon size={60} round={true} className="mr-4" />
              </TwitterShareButton>
              <RedditShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
              >
                <RedditIcon size={60} round={true} className="mr-4" />
              </RedditShareButton>
              <LineShareButton
                url={currentURL}
                onShareWindowClose={this.handleModalClose}
              >
                <LineIcon size={60} round={true} />
              </LineShareButton>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showEmbedModal} onHide={this.handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Embed Video</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ wordBreak: "break-all" }}>
            <textarea style={{ width: "100%" }} rows={22}>
              {embed_code}
            </textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-danger my-1" onClick={this.copyEmbedCode}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="videoInfoHeader">
          <div className="videTitle">
            <h4>{video.title}</h4>
          </div>
          <div className="videoMeta mt-2">
            <div className="row">
              <div className="col-6 text-left">
                <p className="total_views">Answer on {video.AmaDate}</p>
              </div>
              <div className="col-6 text-right">
                <div className="videoMetaRightIcon">
                  <ul>
                    <li stype={{ float: "right" }}>
                      <div
                        onClick={this.onShowSocialShareModal}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fas fa-share" /> Share
                      </div>
                    </li>
                    <li>
                      {video.likedBy &&
                      currentUser &&
                      video.likedBy[currentUser.uid] ? (
                        <div
                          onClick={this.onUnlikeAMA}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-thumbs-up" /> {video.likedCount}
                        </div>
                      ) : (
                        <div
                          onClick={this.onLikeAMA}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="far fa-thumbs-up" /> {video.likedCount}
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AmaDetails);
