import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { Header, Segment } from "semantic-ui-react";
import Confetti from "react-confetti";
import { toast } from "react-toastify";
import "./TodoApp.css";

var todoItems = [];
// todoItems.push({ index: 1, value: "Design a simple todo", done: false });

class TodoList extends React.Component {
  render() {
    var items = this.props.items.map((item, index) => {
      return (
        <TodoListItem
          key={index}
          item={item}
          index={index}
          removeItem={this.props.removeItem}
          markTodoDone={this.props.markTodoDone}
        />
      );
    });
    return <ul className="list-group"> {items} </ul>;
  }
}

class TodoListItem extends React.Component {
  constructor(props) {
    super(props);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
  }

  onClickClose() {
    var index = parseInt(this.props.index);
    this.props.removeItem(index);
  }
  onClickDone() {
    var index = parseInt(this.props.index);
    this.props.markTodoDone(index);
  }
  render() {
    var todoClass = this.props.item.done ? "done" : "undone";

    return (
      <li className="list-group-item ">
        <div className={todoClass}>
          {this.props.item.done ? (
            <i
              className="far fa-check-square mr-2"
              onClick={this.onClickDone}
              style={{ color: "#1fab89" }}
            ></i>
          ) : (
            <i className="far fa-square mr-2" onClick={this.onClickDone}></i>
          )}

          {this.props.item.value}
          <button type="button" className="close" onClick={this.onClickClose}>
            &times;
          </button>
        </div>
      </li>
    );
  }
}

class TodoForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    this.refs.itemName.focus();
  }
  onSubmit = async (event) => {
    event.preventDefault();
    const { user } = this.props;

    var todoContent = this.refs.itemName.value;
    if (todoContent) {
      const todoRef = await firebase
        .database()
        .ref("todos")
        .child(user.uid)
        .child("active")
        .push({ content: todoContent, createdAt: Date.now() });

      await firebase
        .database()
        .ref("todos")
        .child(user.uid)
        .child("active")
        .child(todoRef.key)
        .update({ id: todoRef.key });
      this.props.addItem({ content: todoContent, id: todoRef.key });
      this.refs.form.reset();
    }
  };
  render() {
    return (
      <form ref="form" onSubmit={this.onSubmit} className="form-inline">
        <input
          type="text"
          ref="itemName"
          className="form-control"
          placeholder="add a new todo..."
        />
      </form>
    );
  }
}

class TodoApp extends React.Component {
  constructor(props) {
    super(props);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.markTodoDone = this.markTodoDone.bind(this);
    this.state = { todoItems: [], activeCount: 0, dropConfetti: false };
  }

  componentDidMount = async () => {
    const activeTodos = await firebase
      .database()
      .ref("todos")
      .child(this.props.user.uid)
      .child("active")
      .once("value");

    const doneTodos = await firebase
      .database()
      .ref("todos")
      .child(this.props.user.uid)
      .child("done")
      .orderByChild("createdAt")
      .startAt(Date.now() - 86400000)
      .once("value");

    var tmpTodos = [];
    if (activeTodos.val() && Object.values(activeTodos.val()).length > 0) {
      Object.values(activeTodos.val()).forEach((item) => {
        tmpTodos.push({
          index: tmpTodos.length + 1,
          value: item.content,
          done: false,
          id: item.id,
          createdAt: Date.now(),
        });
      });

      this.setState({ activeCount: Object.values(activeTodos.val()).length });
    }

    if (doneTodos.val() && Object.values(doneTodos.val()).length > 0) {
      Object.values(doneTodos.val()).forEach((item) => {
        tmpTodos.push({
          index: tmpTodos.length + 1,
          value: item.content,
          done: true,
          id: item.id,
          createdAt: Date.now(),
        });
      });
    }

    this.setState({ todoItems: tmpTodos });
  };

  addItem(todoItem) {
    var tmpTodos = this.state.todoItems;

    tmpTodos.push({
      index: tmpTodos.length + 1,
      value: todoItem.content,
      done: false,
      id: todoItem.id,
      createdAt: Date.now(),
    });

    this.setState({ todoItems: tmpTodos });
  }
  removeItem(itemIndex) {
    var tmpTodos = this.state.todoItems;
    var todo = tmpTodos[itemIndex];
    tmpTodos.splice(itemIndex, 1);
    this.setState({ todoItems: tmpTodos });

    todo.done === true
      ? firebase
          .database()
          .ref("todos")
          .child(this.props.user.uid)
          .child("done")
          .child(todo.id)
          .remove()
      : firebase
          .database()
          .ref("todos")
          .child(this.props.user.uid)
          .child("active")
          .child(todo.id)
          .remove();
  }
  markTodoDone = async (itemIndex) => {
    var tmpTodos = this.state.todoItems;
    var todo = tmpTodos[itemIndex];

    if (todo.done === true) {
      // move todo from done to active
      const activeRef = await firebase
        .database()
        .ref("todos")
        .child(this.props.user.uid)
        .child("active")
        .push({ content: todo.value, createdAt: todo.createdAt });

      const originalId = todo.id;

      todo.done = false;
      todo.id = activeRef.key;
      tmpTodos.splice(itemIndex, 1, todo);

      await firebase
        .database()
        .ref("todos")
        .child(this.props.user.uid)
        .child("active")
        .child(activeRef.key)
        .update({ id: activeRef.key });

      await firebase
        .database()
        .ref("todos")
        .child(this.props.user.uid)
        .child("done")
        .child(originalId)
        .remove();
    } else {
      // move todo from active to done
      const doneRef = await firebase
        .database()
        .ref("todos")
        .child(this.props.user.uid)
        .child("done")
        .push({
          content: todo.value,
          doneAt: Date.now(),
          createdAt: todo.createdAt,
        });

      const originalId = todo.id;

      todo.done = true;
      todo.id = doneRef.key;
      tmpTodos.splice(itemIndex, 1, todo);

      await firebase
        .database()
        .ref("todos")
        .child(this.props.user.uid)
        .child("done")
        .child(doneRef.key)
        .update({ id: doneRef.key });

      await firebase
        .database()
        .ref("todos")
        .child(this.props.user.uid)
        .child("active")
        .child(originalId)
        .remove();

      toast("You just completed one todo 🎉", {
        autoClose: 4000,
      });

      this.setState({ dropConfetti: true });
    }

    this.setState({ todoItems: tmpTodos });
  };
  render() {
    const { todoItems, dropConfetti } = this.state;

    const headerContent =
      "Hey " +
      this.props.user.displayName.split(" ")[0] +
      ", add some todos 💪";

    if (todoItems === []) {
      return null;
    }

    return (
      <div className="mt-4 mb-4">
        {dropConfetti && (
          <Confetti style={{ position: "fixed" }} recycle={false} />
        )}
        <Header attached="top" content={headerContent} />
        <Segment
          attached
          className="todo-box"
          style={{ overflow: "auto", maxHeight: "30vh" }}
        >
          <div className="todo">
            <TodoList
              items={todoItems}
              removeItem={this.removeItem}
              markTodoDone={this.markTodoDone}
              user={this.props.user}
            />
            <TodoForm addItem={this.addItem} user={this.props.user} />
          </div>
        </Segment>
      </div>
    );
  }
}

export default TodoApp;
