import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import PulseLoader from "react-spinners/PulseLoader";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { connect } from "react-redux";
import { compose } from "redux";
import { Grid } from "semantic-ui-react";
import ProductInfo from "./ProductInfo";
import ProductVideos from "./ProductVideos";
import "./Product.css";
import { setUserValue } from "../../redux/action/userAction";
import AddNewProductModal from "../../components/shared/AddNewProduct/AddNewProductModal";
import DeleteProductModal from "../../components/shared/DeleteProductModal/DeleteProductModal";
import NewVideoModal from "../../components/shared/NewVideoModal/NewVideoModal";
import NoNewVideoAccessModal from "../../components/shared/NoNewVideoAccessModal";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      publicProfile: false,
      videos: null,
      products: [],
      showProducts: false,
    };
    this.props.setUserValue({ showAddNewProductModal: false });
  }

  componentDidMount = async () => {
    var productId = this.props.match.params.productId;
    let arrayOfVideos = [];

    if (productId) {
      const productRef = await firebase
        .database()
        .ref("products")
        .child(productId)
        .on("value", (snapshot) => {
          if (snapshot.val() === null || snapshot.val() === undefined) {
            this.props.history.push("/error");
            return;
          }

          this.setState({
            product: snapshot.val(),
          });
        });

      await firebase
        .database()
        .ref("products")
        .child(productId)
        .child("videos")
        .orderByChild("createdAt")
        .once("value", (snapshot) => {
          snapshot.forEach((video) => {
            arrayOfVideos.push(video.val());
          });
        });
      this.setState({
        videos: arrayOfVideos.reverse(),
      });
    } else {
      this.props.history.push("/error");
      return;
    }
  };

  onShowProducts = () => {
    this.setState({
      showProducts: true,
    });
  };

  onShowAllVideos = () => {
    this.setState({
      showProducts: false,
    });
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const { product, videos } = this.state;

    if (product === null) {
      return (
        <Fragment>
          <section className="bg-dashboard">
            <div className="text-center" style={{ marginTop: 200 }}>
              <PulseLoader sizeUnit={"px"} size={12} color={"#f13a59"} />
            </div>
          </section>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Helmet>
          <title>{product.name + " - IndieLog"}</title>
          <meta name="description" content={product.description} />
        </Helmet>

        <NewVideoModal type={"PRODUCT-PITCH"} />
        <NoNewVideoAccessModal type={"PRODUCT-PITCH"} />
        <AddNewProductModal product={product} />
        <DeleteProductModal product={product} />
        <section className="bg-dashboard">
          <ProductInfo product={product} />
          <Grid className="mx-0">
            <Grid.Column width={16}>
              <div ref={this.handleContextRef}>
                <ProductVideos videos={videos} />
              </div>
            </Grid.Column>
          </Grid>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Product);
