import React from "react";
import { format } from "date-fns";
import { Header, Segment, Feed, Sticky } from "semantic-ui-react";
import MonthlyTopProductsItem from "./MonthlyTopProductsItem";
import "./MonthlyTopProducts.css";

const MonthlyTopProducts = ({ products, contextRef }) => {
  const title = format(Date.now(), "MMMM") + " Top 10 🥳";
  return (
    <Sticky context={contextRef} offset={100}>
      <Header attached="top" content={title} />
      <Segment attached>
        <Feed>
          {products.length > 0 ? (
            products.map((product) => (
              <MonthlyTopProductsItem key={product.id} product={product} />
            ))
          ) : (
            <h5 className="text-muted" style={{ fontSize: "16px" }}>
              Be the first to update this month? 💪
            </h5>
          )}
        </Feed>
      </Segment>
    </Sticky>
  );
};

export default MonthlyTopProducts;
