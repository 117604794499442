import React from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/database";
import { setUserValue } from "../../redux/action/userAction";
import NoYoutubeChannelAccessModal from "../../components/shared/NoYoutubeChannelAccessModal";
import { Modal, Button } from "react-bootstrap";
import swag_img from "../../assets/images/swag.png";

class IndieTuberBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onShowAddChannelModal = async () => {
    const { currentUser } = this.props.user;

    if (!currentUser.videos) {
      this.props.setUserValue({ showNoAddYoutubeAccessModal: true });
      return;
    }

    if (Object.keys(currentUser.videos).length > 0) {
      this.props.setUserValue({ showAddYoutubeChannelModal: true });
    } else {
      const userRef = await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .once("value");
      if (Object.keys(userRef.val().videos).length > 0) {
        this.props.setUserValue({ showAddYoutubeChannelModal: true });
      } else {
        this.props.setUserValue({ showNoAddYoutubeAccessModal: true });
      }
    }
  };

  handleModalClose = () => {
    this.setState({ showSwagModal: false });
  };

  render() {
    const { currentUser } = this.props.user;

    return (
      <section className="jumbotron text-center cta">
        <NoYoutubeChannelAccessModal />

        <div className="introsection-div">
          <div className="container pt-4">
            <h1 className="home-banner-title">
              IndieTuber = IndieHacker + YouTuber
            </h1>

            <p className="lead text-muted">
              Record your journey on YouTube? Share your channel with us, and we
              want to cheer for you 🥳
            </p>

            <p>
              {currentUser ? (
                <button
                  onClick={this.onShowAddChannelModal}
                  className="btn btn-danger px-5 py-2"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  Add Your Channel
                </button>
              ) : (
                <button
                  onClick={this.onShowAuthModal}
                  className="btn btn-danger px-5 py-2"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  Get Started
                </button>
              )}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IndieTuberBanner);
