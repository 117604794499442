import React from "react";
import { Sidebar } from "./Sidebar";
import SignUp from "./SignUp";
import "./LoginModal.scss";

class LoginModal extends React.Component {
  render() {
    const { showLoginForm } = this.props;
    return (
      <div className="wrapper">
        <div className="content">
          <div className="row mx-0">
            <Sidebar />
            <SignUp showLoginForm={showLoginForm} />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginModal;
