import React from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/database";
import { setUserValue } from "../../redux/action/userAction";

class IndieTuberBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onShowAddChannelModal = async () => {
    const { currentUser } = this.props.user;

    if (!currentUser.videos) {
      this.props.setUserValue({ showNoAddYoutubeAccessModal: true });
      return;
    }

    if (Object.keys(currentUser.videos).length > 0) {
      this.props.setUserValue({ showAddYoutubeChannelModal: true });
    } else {
      const userRef = await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .once("value");
      if (Object.keys(userRef.val().videos).length > 0) {
        this.props.setUserValue({ showAddYoutubeChannelModal: true });
      } else {
        this.props.setUserValue({ showNoAddYoutubeAccessModal: true });
      }
    }
  };

  handleModalClose = () => {
    this.setState({ showSwagModal: false });
  };

  render() {
    const { currentUser } = this.props.user;

    return (
      <section className="jumbotron text-center cta">
        <div className="introsection-div">
          <div className="container py-5">
            <h1 className="home-banner-title">Challenge yourself now</h1>

            <p className="lead text-muted">
              Push yourself to make consistent progress. It's continuous
              progress makes your product different 💪
            </p>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IndieTuberBanner);
