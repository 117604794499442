import React, { Component } from "react";
import { connect } from "react-redux";
import { TwitterIcon, TwitterShareButton } from "react-share";
import Confetti from "react-confetti";
import { setUserValue } from "../../../redux/action/userAction";
import failedImg from "../../../assets/images/failed.png";

class UploadResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareOnYoutube: false,
    };
  }

  componentDidMount() {
    const { uploadSucceed, type } = this.props;
    type === "COMMENT"
      ? this.props.modalHeader(
          uploadSucceed ? "Reply Successful" : "Reply Failed"
        )
      : this.props.modalHeader(
          uploadSucceed ? "Upload Successful" : "Upload Failed"
        );
  }

  onClose = () => {
    this.props.setUserValue({
      showAddNewVideoModal: false,
      isWeeklyVideo: false,
      weeklyTopicId: "",
    });
  };

  onShare = () => {
    var prevState = this.state.shareOnYoutube;

    this.props.shareOnYoutube();

    this.setState({ shareOnYoutube: !prevState });
  };

  onTryAgain = () => {
    this.props.tryAgain();
  };

  render() {
    const { uploadSucceed, type, AmaQuestion, AmaAnswer, video } = this.props;
    const { shareOnYoutube } = this.state;

    if (type === "COMMENT") {
      var message = "Thank you! Your video reply will be visible soon 🙌";
    } else if (AmaQuestion) {
      message = "Thank you for asking! Your video will be visible soon 🙌";
    } else if (AmaAnswer) {
      message = "Yeah, you just answered a question 🙌";
    } else {
      message = "Thank you for sharing! Your video will be visible soon 🙌";
    }

    return (
      <div className="uploadForm">
        <Confetti style={{ position: "fixed" }} />
        <div className="uploadMainContent">
          {uploadSucceed ? (
            shareOnYoutube ? (
              <div className="fileUploaderWrapper text-center">
                <div className="uploadIcon">
                  <img
                    className="img-fluid"
                    src="https://media1.giphy.com/media/g9582DNuQppxC/giphy.gif?cid=ecf05e47ibtkj6mhht2m6gpzy157hwtxvlxlzqlijwrfqh8i&rid=giphy.gifC"
                    alt="success"
                  />
                </div>
                <div className="col uploadBTN recordBtn">
                  <h4>Awesome 🎉🎉🎉🎉</h4>
                  <h4>Your video will be share on YouTube as well 🙌</h4>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary my-1"
                      onClick={this.onClose}
                    >
                      Click to close
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="fileUploaderWrapper text-center">
                <div className="uploadIcon">
                  <img
                    className="img-fluid"
                    src="https://media1.giphy.com/media/g9582DNuQppxC/giphy.gif?cid=ecf05e47ibtkj6mhht2m6gpzy157hwtxvlxlzqlijwrfqh8i&rid=giphy.gifC"
                    alt="success"
                  />
                </div>
                <div className="col uploadBTN recordBtn">
                  <h4>
                    {message}
                    <div
                      className="text-muted small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      It usually takes less than 1 minute.
                    </div>
                  </h4>

                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary my-1"
                      onClick={this.onClose}
                    >
                      Click to close
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="fileUploaderWrapper text-center">
              <div className="uploadIcon">
                <img src={failedImg} className="img-fluid" alt="failure" />
              </div>
              <div className="uploadBTN recordBtn">
                <h4>Sorry, something bad just happened 🙀</h4>
                <button
                  type="button"
                  className="btn btn-danger my-1"
                  onClick={this.onTryAgain}
                >
                  Click to record again
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(null, mapDispatchToProps)(UploadResult);
