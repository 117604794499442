import React, { Component } from "react";
import recordimg from "../../../assets/images/record.svg";

export default class RecordVideo extends Component {
  componentDidMount() {
    if (this.props.type === "COMMENT") {
      this.props.modalHeader("Reply in a video");
    } else if (this.props.type === "NEW-VIDEO" && !this.props.isAMA) {
      this.props.modalHeader("Record a video");
    } else if (this.props.type === "PRODUCT-PITCH") {
      this.props.modalHeader("Record a product pitch");
    } else if (this.props.isAMA) {
      this.props.modalHeader("Record an AMA video");
    } else if (this.props.AmaAnswer) {
      this.props.modalHeader("Record an answer");
    } else if (this.props.AmaQuestion) {
      this.props.modalHeader("Record a question");
    }
  }

  continueRecord = (e) => {
    e.preventDefault();
    this.props.nextStep(false);
  };

  continueUpload = (e) => {
    e.preventDefault();
    this.props.nextStep(true);
  };

  render() {
    const { type, isAMA, AmaAnswer, AmaQuestion } = this.props;
    var title = "";
    var showTitle = true;
    if (type === "PRODUCT-PITCH") {
      title = "Elevator pitch is 30s only";
    } else if (AmaQuestion) {
      title = "Ask your question in 60 seconds";
    } else if (AmaAnswer) {
      title = "You have 5 minutes to record your answer";
    } else if (isAMA) {
      title = "In 2 minutes, tell us why do you want to start this AMA.";
    } else {
      title = "You have up to 2 minutes to record";
    }

    return (
      <div className="uploadForm">
        <div className="uploadMainContent">
          <div className="fileUploaderWrapper text-center">
            <div className="uploadIcon">
              <img
                src={recordimg}
                className="img-fluid"
                alt="recorder"
                style={{ width: "80px", marginTop: 0, paddingTop: "110px" }}
              />
            </div>
            <div className="uploadBTN recordBtn">
              <h4>{title}</h4>
              <button
                type="button"
                className="btn btn-danger my-1"
                onClick={this.continueRecord}
              >
                Click to record
              </button>
              <p className="text-muted" style={{ marginTop: "16px" }}>
                {isAMA ? "Or try" : "Or shy?"}
              </p>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={this.continueUpload}
                style={{ fontWeight: 700 }}
              >
                Upload a video
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
