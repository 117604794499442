import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import Linkify from "linkifyjs/react";
import EditVideoModal from "./EditVideo/EditVideo";
import { setUserValue } from "../../redux/action/userAction";

class AmaUserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFollow = async () => {
    const { video, user } = this.props;
    const { currentUser } = user;

    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }

    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("following")
      .child(video.user.uid)
      .set({
        user: {
          userName: video.user.userName || "",
          uid: video.user.uid,
          avatar: video.user.avatar,
          displayName: video.user.displayName
        }
      });

    await firebase
      .database()
      .ref("users")
      .child(video.user.uid)
      .child("followers")
      .child(currentUser.uid)
      .set({
        user: {
          userName: currentUser.userName || "",
          uid: currentUser.uid,
          avatar: currentUser.avatar,
          displayName: currentUser.displayName
        }
      });

    await firebase
      .database()
      .ref("activities")
      .child(currentUser.uid)
      .push({
        type: "New Follow",
        content: {
          user: video.user.uid
        },
        createdAt: Date.now()
      });
  };

  onUnfollow = async () => {
    const { video, user } = this.props;
    const { currentUser } = user;

    if (!currentUser) {
      return this.props.setUserValue({ showSignInModal: true });
    }
    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("following")
      .child(video.user.uid)
      .remove();

    await firebase
      .database()
      .ref("users")
      .child(video.user.uid)
      .child("followers")
      .child(currentUser.uid)
      .remove();

    await firebase
      .database()
      .ref("activities")
      .child(currentUser.uid)
      .push({
        type: "New Unfollow",
        content: {
          user: video.user.uid
        },
        createdAt: Date.now()
      });
  };

  onShowEditVideoModal = () => {
    this.props.setUserValue({ showEditVideoModal: true });
  };

  render() {
    const { video, user } = this.props;
    const { currentUser } = user;

    if (!video) return null;

    return (
      <div className="videoTitle row">
        <EditVideoModal video={video} />
        <div className="video_titles position-relative overflow-hidden col-9 pr-0">
          <a
            className="vdo_imgs"
            href={
              "/user/" +
              (video.user.userName ? video.user.userName : video.user.uid)
            }
          >
            <img src={video.user.avatar} alt="user avatar" />
          </a>
          <div className="channel_metas">
            <a
              href={
                "/user/" +
                (video.user.userName ? video.user.userName : video.user.uid)
              }
              style={{ color: "inherit" }}
            >
              <h4>{video.user.displayName}</h4>
            </a>
            {/* <h6 className="subscribeNum">
              {video.user.followers || 0} followers
            </h6> */}
            <h5 className="mt-2">
              <Linkify>{video.description}</Linkify>
            </h5>
          </div>
        </div>
        <div className="col-3 pl-0">
          {((currentUser && video.user.uid !== currentUser.uid) ||
            !currentUser) && (
            <div className="btnSubs">
              {currentUser &&
              currentUser.following &&
              currentUser.following[video.user.uid] ? (
                <div onClick={this.onUnfollow} className="btn btn-danger my-1">
                  Followed
                </div>
              ) : (
                <div onClick={this.onFollow} className="btn btn-danger my-1">
                  Follow
                </div>
              )}
            </div>
          )}
          {/* {((currentUser && video.user.uid === currentUser.uid) ||
            (currentUser &&
              currentUser.uid === "xdjzikiyJhWyWFJlA0QI8zJdd2s2")) && (
            <div className="btnSubs">
              <div
                onClick={this.onShowEditVideoModal}
                className="btn btn-danger my-1"
              >
                Edit Video
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state && state.user
  };
};

const mapDispatchToProps = dispatch => ({
  setUserValue: state => {
    return dispatch(setUserValue(state));
  }
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AmaUserInfo);
