import React from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/database";
import { setUserValue } from "../../redux/action/userAction";
import NoNewVideoAccessModal from "../../components/shared/NoNewVideoAccessModal";
import { Modal, Button } from "react-bootstrap";
import swag_img from "../../assets/images/swag.png";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSwagModal: false, weekMeta: null };
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.weekId !== prevProps.weekId) {
      const weekRef = await firebase
        .database()
        .ref("weekly-topics")
        .child(this.props.weekId)
        .once("value");

      const meta = weekRef.val().meta;
      this.setState({ weekMeta: meta });
    }
  };

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onShowAddNewWeeklyVideoModal = async () => {
    this.setState({ showSwagModal: false });
    const { currentUser } = this.props.user;
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({
        showAddNewVideoModal: true,
        isWeeklyVideo: true,
        weeklyTopicId: this.props.weekId,
      });
    } else {
      const userRef = await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .once("value");
      if (userRef.val().createrAccess === true) {
        this.props.setUserValue({
          showAddNewVideoModal: true,
          isWeeklyVideo: true,
          weeklyTopicId: this.props.weekId,
        });
      } else {
        this.props.setUserValue({ showNoNewVideoAccessModal: true });
      }
    }
  };

  onShowSwagModal = () => {
    this.setState({ showSwagModal: true });
  };

  handleModalClose = () => {
    this.setState({ showSwagModal: false });
  };

  render() {
    const { currentUser } = this.props.user;
    const { weekId } = this.props;
    const { weekMeta } = this.state;

    return (
      <section className="jumbotron text-center cta">
        <NoNewVideoAccessModal />
        <Modal
          show={this.state.showSwagModal}
          onHide={this.handleModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Wanna get it?{" "}
              <span role="img" aria-label="wow">
                🤩
              </span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={swag_img} alt="cool swag" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="btn btn-danger my-1"
              onClick={this.onShowAddNewWeeklyVideoModal}
            >
              Record Yours
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="introsection-div">
          <div className="container pt-4">
            <h1 className="home-banner-title pb-4">
              {weekId
                ? weekMeta && weekMeta.topic
                : "Have you tried talking to your customers?"}
            </h1>
            <p className="mt-4">
              {currentUser ? (
                <button
                  onClick={this.onShowAddNewWeeklyVideoModal}
                  className="btn btn-danger my-2"
                  style={{ fontSize: "15px" }}
                >
                  Record Yours
                </button>
              ) : (
                <button
                  onClick={this.onShowAuthModal}
                  className="btn btn-danger my-2"
                  style={{ fontSize: "15px" }}
                >
                  Get Started
                </button>
              )}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
