import React from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/database";
import { setUserValue } from "../../redux/action/userAction";
import NoNewVideoAccessModal from "../../components/shared/NoNewVideoAccessModal";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onShowAddNewVideoModal = async () => {
    const { currentUser } = this.props.user;
    if (currentUser.createrAccess === true) {
      this.props.setUserValue({ showAddNewVideoModal: true });
    } else {
      const userRef = await firebase
        .database()
        .ref("users")
        .child(currentUser.uid)
        .once("value");
      if (userRef.val().createrAccess === true) {
        this.props.setUserValue({ showAddNewVideoModal: true });
      } else {
        this.props.setUserValue({ showNoNewVideoAccessModal: true });
      }
    }
  };

  render() {
    const { currentUser } = this.props.user;
    return (
      <section className="jumbotron text-center cta">
        <NoNewVideoAccessModal />

        <div className="introsection-div">
          <div className="container pt-4">
            <h1 className="home-banner-title">
              Vlogging Our Indie Journey Together
            </h1>
            <p className="lead text-muted">
              We're a community for indie hackers. Each video is up to 2
              minutes. Let's share our journey together.
            </p>
            <p>
              {currentUser ? (
                <button
                  onClick={this.onShowAddNewVideoModal}
                  className="btn btn-danger px-5 py-2"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  Add New Video
                </button>
              ) : (
                <button
                  onClick={this.onShowAuthModal}
                  className="btn btn-danger px-5 py-2"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  Get Started
                </button>
              )}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
