import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import firebase from "firebase/app";
import "firebase/database";
import { getVideos } from "../../redux/action/videoAction";
import { setUserValue } from "../../redux/action/userAction";
import HomeBanner from "./HomeBanner";
import HomeVideos from "./HomeVideos";
import "./Home.css";

var boardme = null;
var activityListener = null;
var newMemberListener = null;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadedVideos: [],
      activities: [],
      newMembers: [],
      products: [],
      contextRef: {},
      hasMore: false,
    };
  }

  componentDidMount = async () => {
    let videos = await this.props.getVideos("");
    if (videos && videos.length === 36) {
      this.setState({ hasMore: true });
    } else {
      this.setState({ hasMore: false });
    }

    this.setState({ isLoading: false });

    activityListener = await firebase
      .database()
      .ref("recent-activities")
      .orderByChild("createdAt")
      .limitToLast(20)
      .on("value", (snapshot) => {
        this.setState({ activities: snapshot.val() });
      });

    let arrayOfProducts = [];
    newMemberListener = await firebase
      .database()
      .ref("new-members")
      .orderByChild("createdAt")
      .limitToLast(5)
      .on("value", (snapshot) => {
        let arrayOfNewUsers = [];
        snapshot.forEach((user) => {
          arrayOfNewUsers.push(user.val());
        });

        // arrayOfNewUsers.map(async (user) => {
        //   await firebase.database().ref("new-members").child(user.uid).set({
        //     displayName: user.displayName,
        //     avatar: user.avatar,
        //     email: user.email,
        //     uid: user.uid,
        //     createdAt: user.createdAt,
        //   });
        // });

        this.setState({
          newMembers: arrayOfNewUsers.reverse(),
        });
      });

    await firebase
      .database()
      .ref("products-this-week")
      .orderByChild("count")
      .once("value", (snapshot) => {
        snapshot.forEach((product) => {
          arrayOfProducts.push(product.val());
        });
      });

    this.setState({
      products: arrayOfProducts.reverse(),
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.videos !== prevProps.videos) {
      this.setState({
        loadedVideos: [
          ...this.state.loadedVideos,
          ...this.props.videos.arrayOfVideos,
        ],
      });
    }

    // boardme = window.boardme;
    // if (boardme) {
    //   boardme.renderFlow("4b73f8da-16f2-45cc-b0c2-c3076d4c666c");
    // }
  };

  componentWillUnmount = async () => {
    await firebase
      .database()
      .ref("recent-activities")
      .off("value", activityListener);

    await firebase
      .database()
      .ref("new-members")
      .off("value", newMemberListener);
  };

  getNextVideos = async () => {
    const { currentUser } = this.props.user;
    const { referenceToOldestKey } = this.props.videos;

    //ask user to login/signup to trigger load more
    if (!currentUser) {
      this.props.setUserValue({ showSignInModal: true });
      return;
    }

    let videos = await this.props.getVideos(referenceToOldestKey, 18);
    if (videos && videos.length < 18) {
      this.setState({ hasMore: false });
    }
  };

  handleContextRef = (contextRef) => this.setState({ contextRef });

  render() {
    const { loading } = this.props;
    const {
      loadedVideos,
      hasMore,
      isLoading,
      activities,
      newMembers,
      products,
    } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>{"IndieLog - Vlogging our Indie journey together"}</title>
          <meta
            name="description"
            content={
              "A video community for makers to vlog their startup journey"
            }
          />
        </Helmet>
        <section className="bg-home">
          <HomeBanner />
          <Grid className="mx-0">
            <Grid.Column width={16}>
              <div ref={this.handleContextRef}>
                <HomeVideos
                  loading={loading}
                  isLoading={isLoading}
                  getNextVideos={this.getNextVideos}
                  videos={loadedVideos}
                  hasMore={hasMore}
                  contextRef={this.state.contextRef}
                  activities={activities}
                  newMembers={newMembers}
                  products={products}
                  user={this.props.user.currentUser}
                />
              </div>
            </Grid.Column>
          </Grid>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state && state.user,
  videos: state && state.videos,
  loading: state && state.async.loading,
  activities: state && state.activities,
});

const mapDispatchToProps = {
  getVideos,
  setUserValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
