import React, { Component } from "react";
import { Player, ControlBar } from "video-react";
import { Form, ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class VideoDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      product: "",
      topic: "",
      uploadToYT: false,
      date: new Date(),
    };
  }
  componentDidMount() {
    this.props.modalHeader("Add video details");
    if (this.props.isAMA) {
      this.props.modalHeader("Add AMA details");
    } else if (this.props.AmaQuestion) {
      this.props.modalHeader("Add question details");
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isUploading !== prevProps.isUploading) {
      this.props.modalHeader("Uploading");
    }
  };

  handleSelectProduct = (event) => {
    const { selectProduct } = this.props;
    selectProduct(event.target.value);
    this.setState({ product: event.target.value });
  };

  handleSelectTopic = (event) => {
    const { selectTopic } = this.props;
    selectTopic(event.target.value);
    this.setState({ topic: event.target.value });
  };

  handleDateChange = (date) => {
    const { selectDate } = this.props;
    selectDate(date);
    this.setState({
      date: date,
    });
  };

  render() {
    const {
      values,
      handleChange,
      isUploading,
      muxUpload,
      user,
      title,
      progress,
      isAMA,
      AmaQuestion,
    } = this.props;
    const { product, topic, date } = this.state;
    const products = user.currentUser.products || [];
    const topicOptions = [
      {
        key: "Ideas and Validation",
        text: "💡 Ideas and Validation",
      },
      {
        key: "Product Development",
        text: "🧑🏿‍💻 Product Development",
      },
      {
        key: "Growth",
        text: "📈 Growth",
      },
      {
        key: "Marketing and Sales",
        text: "💸 Marketing and Sales",
      },
      {
        key: "Content Creation",
        text: "✍🏽 Content Creation",
      },
      {
        key: "Roast My Product",
        text: "🧠 Roast My Product",
      },
      {
        key: "Ask for help",
        text: "🙋‍♀️ Ask for help",
      },
      {
        key: "Indie Mind",
        text: "🤔 Indie's Mind",
      },
      {
        key: "Indie Life",
        text: "🏖️ Indie's Life",
      },
    ];
    return (
      <div className="uploadForm">
        <div className="uploadMainContent">
          <div className="tabbedStepForm">
            <div className="first_step">
              <div className="row">
                <div className="col-md-8 order-md-1 order-sm-2 order-xs-2">
                  {AmaQuestion === false && (
                    <h6 className="title_right_top mb-0">Details</h6>
                  )}
                  <form>
                    {isAMA === false && (
                      <div className="form-group mb-0">
                        <label htmlFor="exampleInputEmail1" className="mt-0">
                          {AmaQuestion
                            ? "Your question in a short sentence"
                            : "Title (required)"}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add a title"
                          onChange={handleChange("title")}
                          defaultValue={values.title}
                          maxLength="60"
                          disabled={isUploading ? true : false}
                        />
                        <Form.Text className="text-muted">
                          {60 - title.length} characters
                        </Form.Text>
                      </div>
                    )}
                    {AmaQuestion === false && (
                      <div className="form-group  mb-0">
                        <label htmlFor="exampleInputPassword1">
                          {isAMA ? "Introduce yourself 😉" : "Descriptions"}
                        </label>
                        <textarea
                          className="form-control"
                          placeholder={
                            isAMA
                              ? "Tell us a bit about yourself, though people may already know you :)"
                              : "Tell us about your video"
                          }
                          onChange={handleChange("description")}
                          defaultValue={values.description}
                          rows="4"
                          disabled={isUploading ? true : false}
                        ></textarea>
                      </div>
                    )}
                    {isAMA === false && AmaQuestion === false && (
                      <>
                        <Form.Group controlId="formGridState">
                          <Form.Label>Product (optional)</Form.Label>
                          <Form.Control
                            as="select"
                            value={product}
                            onChange={this.handleSelectProduct}
                          >
                            <option value="">Choose...</option>
                            {Object.keys(products).length > 0 &&
                              Object.keys(products).map((product) => {
                                return <option key={product}>{product}</option>;
                              })}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formGridState">
                          <Form.Label>Select Topic (optional)</Form.Label>
                          <Form.Control
                            as="select"
                            value={topic}
                            onChange={this.handleSelectTopic}
                          >
                            <option value="">Choose...</option>
                            {topicOptions.map((topic) => {
                              return (
                                <option key={topic.key} value={topic.key}>
                                  {topic.text}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                        {/* <Form.Group controlId="uploadToYT">
                          <Form.Check
                            type="switch"
                            label="Share on Indielog's Youtube Channel?"
                            checked={values.uploadToYT}
                            onChange={handleChange("uploadToYT")}
                          />
                        </Form.Group> */}
                      </>
                    )}
                    {isAMA && (
                      <div className="form-group mb-0">
                        <label htmlFor="exampleInputPassword1">AMA on </label>{" "}
                        <DatePicker
                          selected={date}
                          onChange={this.handleDateChange}
                          className="card-date-picker"
                          withPortal
                        />
                      </div>
                    )}
                  </form>
                </div>
                <div className="col-md-4 order-md-2 order-sm-1 order-xs-1">
                  <div className="right_col">
                    <h6 className="title_right_top">Preview</h6>
                    <div className="preview_col">
                      <Player fluid>
                        <source
                          src={URL.createObjectURL(this.props.videoBlob)}
                        />
                        <ControlBar disableCompletely={true} />
                      </Player>
                    </div>
                  </div>
                  <br />
                  <p className="text-muted">
                    <strong>Don't like the thumbnail? 😩 </strong>No problem!
                    Simply click the 'Edit Video' button once you've uploaded it
                    and you can choose a different one.
                  </p>
                </div>
              </div>
            </div>
            <div className="nextPrevBtn">
              {!isUploading ? (
                <button
                  onClick={
                    isAMA
                      ? this.props.onStartAMA
                      : AmaQuestion
                      ? this.props.onUploadAmaQuestion
                      : this.props.onUploadVideo
                  }
                  className="good btn btn-danger my-1"
                  disabled={isUploading || muxUpload === null ? true : false}
                >
                  {isUploading || muxUpload === null ? (
                    <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                  ) : isAMA ? (
                    "Start AMA"
                  ) : AmaQuestion ? (
                    "Upload Question"
                  ) : (
                    "Upload video"
                  )}
                </button>
              ) : (
                <ProgressBar
                  now={progress}
                  label={`${progress}%`}
                  className="my-3"
                />
              )}
              {/* <button
                onClick={
                  isAMA
                    ? this.props.onStartAMA
                    : AmaQuestion
                    ? this.props.onUploadAmaQuestion
                    : this.props.onUploadVideo
                }
                className="good btn btn-danger my-1"
                disabled={isUploading || muxUpload === null ? true : false}
              >
                {isUploading || muxUpload === null ? (
                  <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                ) : isAMA ? (
                  "Start AMA"
                ) : AmaQuestion ? (
                  "Upload Question"
                ) : (
                  "Upload video"
                )}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

export default connect(mapStateToProps)(VideoDetail);
